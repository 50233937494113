import React, { useState } from 'react';
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import rehypeRaw from 'rehype-raw';

export const converter = new Showdown.Converter({
	tables: true,
	simplifiedAutoLink: true,
	strikethrough: true,
	tasklists: true,
});

export const MarkDownPreview = ({ record, source }) => {

    return (
		<ReactMde
			value={record[source]}
			selectedTab={'preview'}
			generateMarkdownPreview={() => 
				Promise.resolve(<ReactMarkdown rehypePlugins={[rehypeRaw]} children={record[source]}  />)
			}
			toolbarCommands= {[]}
		/>
	)
};

export const MarkDownInput = ({ record, source, handleMarkdownField, saveThisImage, cureentMarkdownInputValue }) => {

	const [value, setValue] = useState(record && record[source]);
    const [selectedTab, setSelectedTab] = useState("write");

	const handleValue = (newValue) => {
		setValue(newValue);
		handleMarkdownField(source, newValue, cureentMarkdownInputValue);
	}


    return (
		<ReactMde
			value={value}
			onChange={(value) => handleValue(value)}
			selectedTab={selectedTab}
			onTabChange={setSelectedTab}
			generateMarkdownPreview={(markdown) =>
				Promise.resolve(<ReactMarkdown rehypePlugins={[rehypeRaw]} children={markdown} />)
			}
			childProps={{
			writeButton: {
				tabIndex: -1
			}
			}}
			paste={{
				saveImage: saveThisImage,
				//multiple: false,
				//command: '',
				//accept: ''
			}}
		/>
	)
};

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { getDataFromLocalDb } from '../utils/Helpers';
import { TABLE_AUTH } from '../local-db';
import * as cbconn from 'corebos-ws-lib/WSClientm';
import DynamicComponent from '../component/DynamicComponent';
import { useTranslate } from 'react-admin';



const useStyles = makeStyles((theme) => ({
  grow: {
	  width: '100%',
  },
  title: {
    display: 'none',
    overflow: 'unset',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  graphContainer: {
    padding: 10, 
    width: '100%',
    //minHeight: '100%',
  },
}));

const Dashboard = () => {

	const classes = useStyles();
  const translate = useTranslate();
  const [user, setUser] = useState({});
  const [questionsdata, setQuestionsdata] = useState(null);
	const [initiallayout, setInitiallayout] = useState([]);

  useEffect(() => {
		getDataFromLocalDb(TABLE_AUTH.tableName).then((result) => {
			setUser(result?.user??{});
			const userId = result?.user?.id ?? '';
			cbconn.doInvoke('getSetting', {key: `ReactAdminGrid_Dashboard_${userId}`}, 'POST').then((settingData) => {
				if(settingData && Array.isArray(JSON.parse(settingData))){
					setInitiallayout(JSON.parse(settingData));
				}
				cbconn.doQuery('select id from cbQuestion where active_reactadmin=1').then((cbQuestions) => {
					const idarray = cbQuestions?.map((item) =>  {return item?.id})?.toString();
					cbconn.doInvoke('cbQuestionAnswer', {qid: idarray}, 'POST').then((questionsAnswers) => {
						if(questionsAnswers && Array.isArray(questionsAnswers)){
              setQuestionsdata(questionsAnswers);
            } else {
              const questionsArr = [];
              questionsArr.push(questionsAnswers);
              setQuestionsdata(questionsArr);
            }
					});
				})
			}).catch((err) => {
				console.log('Something went wrong on getting setting');
			})
		});
	}, [])

  return (
    <div className={classes.grow}>
      <AppBar position="static" color="default" elevation={0} sx={{borderRadius: 4}}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            { translate('My Home Page') }
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.graphContainer}>
        { user && user.id && questionsdata && questionsdata.length > 0 &&
         <div style={{paddingTop: 50, width: '100%', minHeight: '100%'}}>
          <DynamicComponent settingKey={`ReactAdminGrid_Dashboard_${user.id}`} questionsdata={questionsdata} layout={initiallayout} />
          </div>
        }
      </div> 
    </div>
  );
}

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { Show, useRedirect, EditButton, Notification, useTranslate, FunctionField, useRefresh, useResourceContext, useResourceDefinition, useShowContext } from 'react-admin';
import cbUtils from '../corebosUtils/corebosUtils';
import { getDataFromLocalDb } from '../../utils/Helpers';
import { TABLE_DESCRIBE } from '../../local-db';
import {
	Box,
	Grid,
	Typography,
	Card,
	CardHeader,
	CardContent,
	List,
	ListItem,
	ListItemText,
	Button,
	Divider,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Title from '../Title';
import { CbRelatedList } from '../cbRelatedList';
import { useSelector } from 'react-redux';
import DocumentPreviewModal from '../DocumentPreview/DocumentPreviewModal';
import { MarkDownPreview } from '../MarkDown';
import EmailDialog from '../EmailSendView';
import { DetailViewPFPushAlong } from '../PFPushAlong';
import { BUSINESS_PROCESS_FLOW, THEME_CONFIG } from '../../constant';

//@ts-ignore
import * as cbconn from 'corebos-ws-lib/WSClientm';

const DISPLAY_CARD_HEIGHT: number = 320;

export const CbShowTabGuesser = () => {
	const { options } = useResourceDefinition();
	let resource = useResourceContext();
	const { record } = useShowContext();
	const [describe, setDescribe] = useState<any>({});
	const [blocks, setBlocks] = useState<any[]>([]);
	const [relatedModules, setRelatedModules] = useState<any[]>([]);
	const { id } = useParams();
	const translate = useTranslate();
	const [labelField, setLabelField] = useState<string>(options?.label ?? '');
	const [label, setLabel] = useState<string>(options?.label ?? '');
	const theme = useSelector((state: any) => state.theme);
	const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
	const [emailDialogProps, setEmailDialogProps] = useState<any>({ open: false, parentResource: resource, toggleDialog: () => { } });
	const [clickedItem, setClickedItem] = useState<any>({});
	const refresh = useRefresh();
	const [questionsdata, setQuestionsdata] = useState<any>(null);
	const qid: string = '52x13110';
	const redirect = useRedirect();

	const getProcessFlow = (field: any) => {
		const pf = BUSINESS_PROCESS_FLOW.find((element) => element.field === field);
		return pf;
	};

	useEffect(() => {
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
			setDescribe(result);
			setLabel(result[resource]?.label);
			let bfields: any[] = [];
			let mfields: any[] = result[resource]?.fields;
			for (let f = 0; f < mfields.length; f++) {
				if (!mfields[f].block || mfields[f].block.blockname === 'Stato' || mfields[f].block.blockname === 'Note') {
					continue;
				}
				let bidx = bfields.findIndex((element: any) => element.id === mfields[f].block.blockid);
				if (bidx === -1) {
					bfields.push({
						id: mfields[f].block.blockid,
						sequence: mfields[f].block.blocksequence,
						label: mfields[f].block.blocklabel,
						name: mfields[f].block.blockname,
						fields: []
					});
					bidx = bfields.findIndex((element) => element.id === mfields[f].block.blockid);
				}
				bfields[bidx].fields.push(mfields[f]);
			}

			setBlocks(bfields);
			if (!options?.label) {
				const labelFields = result[resource]?.labelFields;
				if (labelFields) {
					let aLabelField = labelFields.split(',');
					aLabelField = aLabelField && aLabelField[0] ? aLabelField[0] : '';
					setLabelField(aLabelField);
				}
			}
			if (!options?.hiderelmod) {
				const relMods = result[resource]?.relatedModules;
				if (relMods) {
					const relatedMods = [];
					const rModules: any[] = Object.values(relMods);
					for (let index = 0; index < rModules.length; index++) {
						const rModule = rModules[index];
						const describeRelatedMod = result[rModule.related_module];
						if (describeRelatedMod) {
							describeRelatedMod.relatedfield = rModule.relatedfield;
							describeRelatedMod.moduleRelationType = rModule.relatedfield ? '1:N' : 'N:N';
							relatedMods.push(describeRelatedMod);
						}
					}
					setRelatedModules(relatedMods);
				}
			}
		});

		const recordId = id?.split('x')[1] ?? '';
		cbconn.doInvoke('cbQuestionAnswer', { qid: qid, params: JSON.stringify({ $RECORD$: recordId }) }, 'GET').then((questionsAnswers: any) => {
			setQuestionsdata(questionsAnswers);
		}).catch(() => {
			console.log('Something went wrong on getting cbquestions');
		})

	}, [id, options?.hiderelmod, options?.label, resource, translate])


	const handleDocPreviewModalOpen = (record: any = null, _moduleName: string, field: any = null) => {
		if (record) {
			record['title'] = field?.label ?? '';
			record['_downloadurl'] = record[field?.name] ?? '';
			if (!record.filetype) {
				const urlStr = record[field?.name] ? record[field.name].split('.') : [];
				record['filetype'] = urlStr[urlStr.length - 1] ?? '';
			}
		}
		setClickedItem(record);
		setDocPreviewOpen(true);
	};
	const handleEmailClick = (aRecord: any, open: boolean, fieldName: string) => {
		const defaultValue: any = {};
		if (aRecord) {
			defaultValue['to'] = aRecord[fieldName];
		}
		setEmailDialogProps((prevEmailProps: any) => { return { ...prevEmailProps, defaultValue: defaultValue, open: open, toggleDialog: handleEmailClick } });
	}

	const onPflUpdate = () => {
		refresh();
	}

	const PostShowActions = () => (
		<div style={{ alignItems: 'center', justifyContent: 'flex-end', display: 'flex', top: -54, position: 'absolute', zIndex: 99, width: '100%', flexDirection: 'row' }}>
			<EditButton onClick={() => { redirect(`/${resource}/${id}`) }} />
		</div>
	);

	const handleBlockDisplay = (blockId: string) => {
		const clickedCard: any = document.getElementById('displayCard'+blockId);
		const showLessMoreBtn: any = document.getElementById('showLessMoreBtn'+blockId);
		if(clickedCard.offsetHeight === DISPLAY_CARD_HEIGHT){
			clickedCard.style.height = "auto";
		} else {
			clickedCard.style.height = `${DISPLAY_CARD_HEIGHT}px`;
		}
		if(showLessMoreBtn.innerText === translate('Show More')){
			showLessMoreBtn.innerText = 'Show Less';
		} else {
			showLessMoreBtn.innerText = translate('Show More');
		}
	}


	return (
		<Box mt={3} ml={-2}>
			<DocumentPreviewModal record={clickedItem} title={clickedItem?.title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
			<EmailDialog {...emailDialogProps} />
			<Grid container spacing={2} >
				<Grid item xs={12} md={relatedModules && relatedModules.length > 0 ? 9 : 12} style={{ minHeight: '100vh', background: theme === 'dark' ? 'inherit' : '#fff' }}>
					<Box className='raPageContent' style={{ position: 'relative' }} px={2} mb={3}>
						<Show
							title={<Title resource={resource} label={label} showTitle={options?.showTitle} record={record} linkField={labelField} />}
							actions={<PostShowActions />}
							component="div"
						>
							<Grid container spacing={2}>
								{
									blocks.map((block, index) => {
										return (
											<Grid key={index} item xs={12} md={6}>
												<Card style={{ marginBottom: '15px'}} elevation={0} id={'displayCard'+block.id} sx={{background: THEME_CONFIG.sideBarBackground, height: `${DISPLAY_CARD_HEIGHT}px`, border: '1px solid #ddd'}}>
													<CardHeader title={<Typography variant="subtitle1" fontWeight="bold"> {block.name} </Typography>}  />
													<Divider />
													<CardContent sx={{mt: 2}}>
														<List sx={{ width: '100%', bgcolor: 'none', mt: -3 }} >
														{
															block.fields.map((field: any, idx: number) => {
																let handleFieldClick: any = null;
																if (field.uitype === '28' || field.uitype === '69') {
																	handleFieldClick = handleDocPreviewModalOpen;
																}
																if (field.uitype === '13') {
																	handleFieldClick = handleEmailClick;
																}
																const processFlow: any = getProcessFlow(field.name);
																if (processFlow && processFlow.pfId){
																	return (
																		<Box key={idx}>
																			<FunctionField label={''} render={(aRecord: any) => {
																				return (
																					<Box>
																						<Typography variant="subtitle2" sx={{fontWeight: 550}}> {field.label} </Typography>
																						<DetailViewPFPushAlong
																							pfid={processFlow?.pfId}
																							ctxid={id}
																							onPflUpdate={onPflUpdate}
																							graphId={`pfpaoutputgraph_${field.name}_${processFlow?.pfId}`}
																							record={aRecord}
																						/>
																					</Box>
																				)
																			}
																			} />
																		</Box>
																	)
																}
																
																if (field.uitype === '19'){
																	return (
																		<Box key={idx}>
																			<FunctionField label={''} render={(aRecord: any) => {
																				return (
																					<Box className='cbRaMarkdownPreview'>
																						<Typography variant="subtitle2" sx={{fontWeight: 550}}> {field.label} </Typography>
																						<MarkDownPreview record={aRecord} source={field.name} />
																					</Box>
																				)
																			}
																			} />
																		</Box>
																	)
																}
																
																const inputDisplay: any = cbUtils.field2DisplayElement(field, resource, describe, '', '', handleFieldClick);

																return (
																	<ListItem secondaryAction={inputDisplay ? inputDisplay : <></>} sx={{mt: -2, px: 0}}>
																		<ListItemText id={field.name+idx} primary={<Typography variant="subtitle2" sx={{fontWeight: 550}}> {field.label} :</Typography>} />
																	</ListItem>
																)
															})
														}
														</List>
													</CardContent>
												</Card>
												<Box sx={{background: THEME_CONFIG.sideBarBackground, mt: -2, py: 1, textAlign: 'center'}}> 
													<Button variant="text" color="primary" size="small" onClick={() => handleBlockDisplay(block.id)} id={'showLessMoreBtn'+block.id}> {translate('Show More')} </Button> 
												</Box>
											</Grid>
										)
									})
								}
							</Grid>
						</Show>
					</Box>
				</Grid>
				{(relatedModules.length > 0 || (resource === 'ServiceContracts' && questionsdata)) &&
					<Grid item xs={12} md={3} style={{ borderLeft: '1px solid #eee', height: '100vh', overflowY: 'auto', padding: '0' }}>
						<Box mt={4} >
							{relatedModules.length > 0 &&
								<>
									{relatedModules.map((relatedModule, index) => {
										return (
											<Box key={index}>
												<CbRelatedList id={id} describe={describe} basePath={relatedModule?.name} relatedModule={relatedModule} relatedParentModule={resource} moduleRelationType={relatedModule.moduleRelationType} />
											</Box>
										);
									})}
								</>
							}
							{resource === 'ServiceContracts' && questionsdata &&
								<Box>
									<CbRelatedList operation='cbQuestionAnswer' questionsdata={questionsdata} id={id} describe={describe} basePath='Products' relatedModule='Products' relatedParentModule={resource} moduleRelationType='' />
								</Box>
							}
						</Box>
					</Grid>
				}
			</Grid>
			<Notification />
		</Box>
	)
};

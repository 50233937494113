import React, { useState, useEffect } from 'react';
import { List, useListContext, Loading, useTranslate } from 'react-admin';
import CbRecordDetail from './cbRecordDetail';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { makeStyles, withStyles } from '@mui/styles';
import {Link} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../types';
import { authProvider } from '../authProvider';
import CbCreateRelatedRecordDrawer from './CbCreateRelatedRecordDrawer';
import DocumentPreviewModal from './DocumentPreview/DocumentPreviewModal';
import { getListColumns } from '../utils/lib';
import { TABLE_AUTH } from '../local-db';
import { getDataFromLocalDb } from '../utils/Helpers';

const useStyles = makeStyles(() => ({
    w100: {
        width: '100%',
    },
}));

const Accordion = withStyles({
    root: {
      boxShadow: 'none',
      background: 'inherit',
      borderBottom: '1px solid #eee',
      padding: '1px 0',
      border: 'none',
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: '0',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
        marginBottom: -8,
        padding: '0 15px',
        minHeight: 15,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      '& .list-page': {
        width: '100%',
      },
    },
  }))(MuiAccordionDetails);


const pagesize = 3;

export const CbRelatedList = ({id, describe, questionsdata, relatedModule, relatedParentModule, moduleRelationType, operation='' } : {id: any, describe: any, basePath: string, relatedModule: any, relatedParentModule: string, moduleRelationType: string, operation?: string, questionsdata?: any }) => {
    const resource = operation === 'cbQuestionAnswer' ? relatedModule : relatedModule?.name ?? '';
    const label = operation === 'cbQuestionAnswer' ? relatedModule : relatedModule?.label ?? '';
    let realResourceName = resource;
    if(realResourceName === 'Project'){
        realResourceName = 'Projects';
    }
    const translate = useTranslate();
    const classes = useStyles();
    const theme = useSelector((state: AppState) => state.theme);
    const linkfields = describe[resource]?.filterFields?.linkfields ?? [];
    const [expanded, setExpanded] = useState<any>('');
    const [ modPermissions, setModPermissions ] = useState<any>(null);
    const [open, toggleDrawer] = useState(false);

	const defaultSearchquery: any = {};
	if(operation !== 'cbQuestionAnswer'){
        defaultSearchquery[relatedModule?.relatedfield] = id;
        defaultSearchquery['relatedModule'] = relatedParentModule;
        defaultSearchquery['moduleRelationType'] = moduleRelationType;
        defaultSearchquery['joinCondition'] = 'AND';
    }
    const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
    const [ clickedItem, setClickedItem ] = useState<any>({});
    const [user, setUser] = useState({});

    const questionAnswerFields: any[] = [];
    let questionTitle: string = '';
    if(questionsdata && operation === 'cbQuestionAnswer'){
        questionTitle = `${questionsdata.title} (${questionsdata.answer?.length})`;
        const properties: any = JSON.parse(questionsdata.properties);

        const relatedModFields = describe[relatedModule]?.fields ?? [];   
        properties.forEach((qfield: any) => {
            const fieldObj: any = relatedModFields.find((relatedField: any) => relatedField.name === qfield.name);
            if(fieldObj){
                questionAnswerFields.push(fieldObj);
            }
        });
    }

    const [title, setTitle] = useState<string>(questionsdata && operation === 'cbQuestionAnswer' ? questionTitle : `${resource} (${0})`);
    const [ fields, setFields ] = useState<any[]>([]);
    

    useEffect(() => {
        if(operation !== 'cbQuestionAnswer'){
            getDataFromLocalDb(TABLE_AUTH.tableName).then((result) => {
                setUser(result?.user??{});
            });
            authProvider.getModulePermission(resource).then((result: any) => {
                setModPermissions(result);
            });

            getListColumns(relatedParentModule).then((result) => { 
                let relatedListColumnNames: any[] = [];
                let modFields = describe[resource]?.filterFields?.fields ?? [];
                let realResourceName = resource;
                if(realResourceName === 'Project'){
                    realResourceName = 'Projects';
                }
                if(describe[resource] && describe[resource].ListFieldsName){
                    for (const key in describe[resource].ListFieldsName) {
                        if (Object.hasOwnProperty.call(describe[resource].ListFieldsName, key)) {
                            relatedListColumnNames.push(describe[resource].ListFieldsName[key]);
                        }
                    }
                    // eslint-disable-next-line array-callback-return
                    let relatedFields = modFields.filter((field: any) => {
                        if(relatedListColumnNames.includes(field.name)) return field;
                    })
                    setFields(relatedFields);
                } else {
                    const relModFilterFields = describe[realResourceName]?.filterFields?.fields ?? [];
                    const relModFields = describe[realResourceName]?.fields ?? [];
                    const relModFilters = [];
                    // eslint-disable-next-line array-callback-return
                    let relfields = relModFields.filter((field: any) => {
                        if(relModFilterFields.includes(field.name)) {
                            relModFilters.push(field);
                            return field;
                        };
                    })
                    if(relfields.length > 0){
                        setFields(relfields);
                    } else {
                        setFields([]);
                    }
                }
            }).catch((error) => {
                console.log(translate('translations.somethingWentWrong'));
                const relModFilterFields = describe[realResourceName]?.filterFields?.fields ?? [];
                    const relModFields = describe[realResourceName]?.fields ?? [];
                    const relModFilters = [];
                    // eslint-disable-next-line array-callback-return
                    let relfields = relModFields.filter((field: any) => {
                        if(relModFilterFields.includes(field.name)) {
                            relModFilters.push(field);
                            return field;
                        };
                    })
                    if(relfields.length > 0){
                        setFields(relfields);
                    } else {
                        setFields([]);
                    }
            })
        }
    }, [describe, operation, realResourceName, relatedParentModule, resource, translate])

    const handleChange = (panel: any) => (event: any, newExpanded: any) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleDrawer = () => {
        toggleDrawer(!open);
    }

    const handleDocPreviewModalOpen = (record: any = null, _moduleName: string, field:any =null) => {
        if(record){
			record['title'] = field?.label ?? '';
			record['_downloadurl'] = record[field?.name] ?? '';
			if(!record.filetype){
				const urlStr: string[] = record[field?.name] ? record[field.name].split('.') : [];
				record['filetype'] = urlStr[urlStr.length-1] ?? '';
			}
		}
		setClickedItem(record);
        setDocPreviewOpen(true);
    };


    return (
        <Box mt={2} >
            {/**
             * TransitionProps={{ unmountOnExit: true }} 
             * Records will be fetched when the accordion is opened, which causes difficulties on 
             * knowing the total number of records. But in case of performance, this feature is good. 
             */}
            <DocumentPreviewModal record={clickedItem} title={clickedItem?.title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
            <Accordion square TransitionProps={{ unmountOnExit: false }} expanded={expanded === resource} onChange={handleChange(resource)}>
                <Box display={'flex'}>
                    <Box mt={-2} style={{width: '100%'}} >
                        <AccordionSummary>
                            { expanded ? <ExpandMoreIcon color="primary" /> : <ChevronRight color="primary" /> } 
                            <Typography fontWeight="bold">
                                {title}
                            </Typography>
                        </AccordionSummary>
                    </Box>
                    {operation !== 'cbQuestionAnswer' &&
                        <Box mx={2} mt={-1} >
                            {modPermissions && modPermissions.create && 
                                <Button
                                variant="text"
                                color={theme === 'dark' ? 'inherit' : 'primary'}
                                onClick={() => handleDrawer()}
                            >
                                {`+ ${translate('translations.addAction')}`}
                            </Button>
                            }
                        </Box>
                    }
                </Box>
                
                <AccordionDetails>
                    { operation === 'cbQuestionAnswer'
                        ? <Box className={classes.w100}>
                            {
                                questionsdata?.answer?.map((record: any, index: number) => {
                                    return (
                                        <Box>
                                            <CbRecordDetail key={index} handleDocPreviewModalOpen={handleDocPreviewModalOpen} record={record} fields={questionAnswerFields} describe={describe} linkfields={linkfields} resource={resource} />
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                        :   <List
                                resource={resource}
                                filterDefaultValues={defaultSearchquery}
                                title={<></>}
                                perPage={pagesize}
                                actions={false}
                                pagination={false}
                                component="div"
                                >
                                    <ListContent id={id} handleDocPreviewModalOpen={handleDocPreviewModalOpen} moduleRelationType={moduleRelationType} relatedfield={relatedModule?.relatedfield} fields={fields} describe={describe} linkfields={linkfields} resource={resource} label={label} relatedParentModule={relatedParentModule} setTitle={setTitle} />
                            </List>
                    }
                </AccordionDetails>
            </Accordion>
            {   open && 
			    <CbCreateRelatedRecordDrawer user={user} handleDocPreviewModalOpen={handleDocPreviewModalOpen} relatedFields={fields} linkfields={linkfields} relatedModDetails={relatedModule} open={open} toggleRelatedModuleDrawer={handleDrawer} describe={describe} relatedFieldValue={id} modPermissions={modPermissions} />
		    }
        </Box>
    )
}

const ListContent = (props:any) => {
    const translate = useTranslate();
    const { data, total, isLoading } = useListContext<any>();
    
    props.setTitle(`${props.label} (${total ?? 0})`);

    return (
        <Box>
            {isLoading && 
                <Loading loadingPrimary={""} loadingSecondary={""} />
            }
            {data?.map((record: any, index: number) => 
                <CbRecordDetail key={index} handleDocPreviewModalOpen={props.handleDocPreviewModalOpen} record={record} fields={props.fields} describe={props.describe} linkfields={props.linkfields} resource={props.resource} />
            )}
            {total > pagesize &&
                <Typography variant="inherit" component={Link} to={`/${props.resource}?relmod=${props.relatedParentModule}&relfield=${props.relatedfield}&relfieldval=${props.id}&reltype=${props.moduleRelationType}`} color="primary" style={{textDecoration: 'none'}}>
                    {translate('translations.viewAll')}
                </Typography>
            }
        </Box>
    )


}
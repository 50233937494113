import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslate, Title } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { changeTheme, changeMenuPostion } from '../app-reducers/actions';
import { AppState, ThemeName, MenuPosition } from '../types';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles({
    label: { width: '4em', display: 'inline-block' },
    button: { margin: '1em' },
});

const Configuration = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();

    const theme = useSelector((state: AppState) => state.theme);
    const menuPosition = useSelector((state: AppState) => state.menuPosition);
    const isMedium = useMediaQuery((theme: any) => theme.breakpoints.up('md'));


    const handleThemeChange = (themeName: ThemeName) => {
        dispatch(changeTheme(themeName));
        localStorage.setItem('theme', themeName);
    }

    const handleMenuPositionChange = (position: MenuPosition) => {
        dispatch(changeMenuPostion(position));
        localStorage.setItem('menuposition', position);
    }

    return (
        <Box mt={2}>
            <Title title={translate('translations.configuration')} />
            <Grid container spacing={2} >
                <Grid item xs={12} md={4}> 
                    <Card elevation={2}>
                        <CardHeader
                            title={translate('Theme Color')}
                        />
                        <CardContent>
                            <div className={classes.label}>
                                <Typography>{translate('translations.theme.name')}</Typography>
                            </div>
                            <Button
                                variant="contained"
                                size='small'
                                className={classes.button}
                                color={theme === 'light' ? 'primary' : 'secondary'}
                                onClick={() => handleThemeChange('light')}
                            >
                                {translate('translations.theme.light')}
                            </Button>
                            <Button
                                variant="contained"
                                size='small'
                                className={classes.button}
                                color={theme === 'dark' ? 'primary' : 'secondary'}
                                onClick={() => handleThemeChange('dark')}
                            >
                                {translate('translations.theme.dark')}
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                {isMedium && 
                    <Grid item xs={12} md={4}> 
                        <Card elevation={2}>
                            <CardHeader
                                title={translate('Menu Position')}
                            />
                            <CardContent>
                                <div className={classes.label}>
                                    <Typography>{translate('Position')}</Typography>
                                </div>
                                <Button
                                    variant="contained"
                                    size='small'
                                    className={classes.button}
                                    color={menuPosition === 'top' ? 'primary' : 'secondary'}
                                    onClick={() => handleMenuPositionChange('top')}
                                >
                                    {translate('Top')}
                                </Button>
                                <Button
                                    variant="contained"
                                    size='small'
                                    className={classes.button}
                                    color={menuPosition === 'left' ? 'primary' : 'secondary'}
                                    onClick={() => handleMenuPositionChange('left')}
                                >
                                    {translate('Left')}
                                </Button>
                                <Button
                                    variant="contained"
                                    size='small'
                                    className={classes.button}
                                    color={menuPosition === 'right' ? 'primary' : 'secondary'}
                                    onClick={() => handleMenuPositionChange('right')}
                                >
                                    {translate('Right')}
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                }
            </Grid>
        </Box>
    );
};

export default Configuration;
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import MenuIcon from '@mui/icons-material/Menu';
import { IconSettings, Icon } from '@salesforce/design-system-react';
import clsx from "clsx";
import { Loading, MenuItemLink, useTranslate } from "react-admin";
import SubMenu from "./SubMenu";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Theme, Box } from "@mui/material";


const sidebarProp = {
  width: '200px',
  collapsedWidth: '55px',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: '0 0 auto',
    marginTop: 0,
    marginBottom: 0,
    transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    '& .RaMenuItemLink-active': {
      color: theme.palette.primary.main,
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      marginLeft: '-1px',
      fontWeight: 'bold',
    },
  },
  menuItemLink: {
    fontStyle: 'italic',
    fontWeight: 550,
    fontSize: '17px',
  },
  open: {
    width: sidebarProp.width,
    position: 'relative',
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    background: 'transparent',
    zIndex: 'inherit',
  },
  closed: {
    width: sidebarProp.collapsedWidth,
    position: 'relative',
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    background: 'transparent',
    zIndex: 'inherit',
  },
  menuBox: {
    width: sidebarProp.width,
    marginTop: '8px',
    marginBottom: '8px',
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  appTitle: {
    fontWeight: 'bold',
  },
}));

const AppMenu = (props: any) => {
  const { menuItemsList, dense, sidebarIsOpen, isLoadingMenu } = props;
  const classes = useStyles();
  const [anchorElIndex, setAnchorElIndex] = useState("");
  const [anchorParentElIndex, setAnchorParentElIndex] = useState("");
  const [open, setOpen] = useState(false);
  const [open2ndLevel, setOpen2ndLevel] = useState(false);
  const translate = useTranslate();


  const handleToggle = (parentMenuIndex: any, menuIndex: any) => {
    if (!parentMenuIndex) {
      setOpen(!open);
    } else {
      setOpen2ndLevel(!open2ndLevel);
    }
    setAnchorParentElIndex(parentMenuIndex);
    setAnchorElIndex(menuIndex);
  };

  const MenuItemIcon = ({ icon }: { icon: any }) => {
    if (!icon) return <MenuIcon />;
    return (
      <IconSettings iconPath="/icons" style={{ minWidth: '40px' }}>
        <div className={`${icon?.containerClass}`}>
          <Icon
            category={icon?.library}
            name={icon?.icon}
            className={`${icon.class}`}
            size="small"
            style={{ width: '1.2rem', height: '1.2rem' }}
          />
        </div>
      </IconSettings>
    )
  }

  const CBMenuItemLink = ({ menuItem }: { menuItem: any }) => {
    if (menuItem.type === 'url') {
      return (
        menuItem.menuItemVisibility && (<ul className="MuiList-root MuiList-padding" role="menu" tabIndex={-1}>
          <li>
            <a tabIndex={0} role={'menuitem'} aria-disabled={false} href={menuItem.name} target="_blank" rel="noreferrer" className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root RaMenuItemLink-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button">
              {menuItem.icon &&
                <MenuItemIcon icon={menuItem.icon} />
              }
              <span style={{ marginLeft: '4px' }}>{menuItem.label}</span>
            </a>
          </li>
        </ul>)
      )
    }

    return (
      menuItem.menuItemVisibility && (<MenuItemLink
        key={menuItem.name}
        to={{
          pathname: `/${menuItem.name}`,
        }}
        primaryText={menuItem.label}
        leftIcon={<MenuItemIcon icon={menuItem.icon} />}
        dense={dense}
        className={classes.menuItemLink}
      />)
    )
  }

  return (
    <div className={clsx(classes.root)} >
      <div
        className={clsx({
          [classes.open]: sidebarIsOpen,
          [classes.closed]: !sidebarIsOpen,
        })}
      >
        <Box className={classes.menuBox}>
          {isLoadingMenu
            ? <Loading loadingPrimary="" loadingSecondary="" />
            : <>
              <Box key={'dashboard'}>
            <MenuItemLink
              key={'homeLink'}
              to={{
                pathname: '/',
              }}
              primaryText={translate('Dashboard')}
              leftIcon={<DashboardIcon style={{ color: 'inherit' }} />}
              dense={dense}
              className={classes.menuItemLink}
            />
          </Box>

          {
            <>
              {menuItemsList.map((menu: any, menuIndex: number) => {
                return (
                  <Box>
                    {menu.menuItems.length <= 0 ? (
                      <CBMenuItemLink key={menuIndex} menuItem={menu} />
                    ) : (
                      menu?.menuItemVisibility &&
                      (<SubMenu
                        key={menu.name + menuIndex + "submenu"}
                        handleToggle={() => handleToggle("", menu.menuId)}
                        isOpen={
                          open &&
                          (menu.menuId === anchorParentElIndex ||
                            menu.menuId === anchorElIndex)
                        }
                        name={menu.label}
                        icon={<MenuItemIcon icon={menu.icon} />}
                        dense={dense}
                        sidebarIsOpen={sidebarIsOpen}
                      >
                        {menu.menuItems.map((menuItem2: any, index2: number) => {
                          return (
                            <>
                              {menuItem2 &&
                                menuItem2.menuItems &&
                                menuItem2.menuItems.length <= 0 ? (
                                <CBMenuItemLink key={index2} menuItem={menuItem2} />
                              ) : (
                                <Box ml={2.2}>
                                  <SubMenu
                                    key={menuItem2.name + index2 + "submenu"}
                                    handleToggle={() =>
                                      handleToggle(
                                        menuItem2.parentMenu,
                                        menuItem2.menuId
                                      )
                                    }
                                    isOpen={
                                      open2ndLevel &&
                                        menuItem2.menuId === anchorElIndex
                                        ? true
                                        : false
                                    }
                                    name={menuItem2.label}
                                    icon={<MenuItemIcon icon={menuItem2.icon} />}
                                    dense={dense}
                                    sidebarIsOpen={sidebarIsOpen}
                                  >
                                    {menuItem2.menuItems.map(
                                      (menuItem3: any, index3: number) => {
                                        return (
                                          <CBMenuItemLink key={index3} menuItem={menuItem3} />
                                        );
                                      }
                                    )}
                                  </SubMenu>
                                </Box>
                              )}
                            </>
                          );
                        })}
                      </SubMenu>)
                    )}
                  </Box>
                );
              })}
            </>
          }
            </>
          }
        </Box>
      </div>
    </div>
  );
};

export default AppMenu;

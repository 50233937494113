import React, { useState, useEffect } from 'react';
import { useTranslate, useRedirect, useResourceDefinition, useResourceContext, Loading  } from 'react-admin';
import { getDataFromLocalDb } from '../../utils/Helpers';
import { TABLE_DESCRIBE } from '../../local-db';
import { 
	Box,
	TextField,
	Typography,
	Grid,
	Card,
} from '@mui/material';
import queryString from 'query-string';
import { doQuery, getRelatedModules } from '../../utils/lib';
import { ListView } from '../ListView';
import Autocomplete from '@mui/material/Autocomplete';
import RelatedModCard from '../../component/RelatedModCard'
import { useLocation } from 'react-router-dom';


export const CbListGuesser = () => {
	const { options } = useResourceDefinition();
	const modPermission = options && options.permission && options?.permission[0] ? options.permission[0].permissions : null;
	let resource = useResourceContext();
	const [describe, setDescribe] = useState({});
	const [fields, setFields] = useState([]);
	const [label, setLabel] = useState(options?.label);
	const [labelField, 	setLabelField] = useState('');
	const [linkFields, setLinkfields] = useState([]);
	const [filterFields, setFilterFields] = useState([]);
	const location = useLocation();
	let defaultSearchquery = null;
	const { relmod, relfield, relfieldval, reltype, parentid } = queryString.parse(location?.search);
	const [specialDefaultFilter, setSpecialDefaultFilter] = useState(null);
	const [isLoadingRelated, 	setIsLoadingRelated] = useState(false);
	const [hiddenFilterField, 	setHiddenFilterFields] = useState([]);


	if(relmod && relfieldval && reltype){
		defaultSearchquery = {};
		defaultSearchquery[relfield] = relfieldval;
		defaultSearchquery['relatedModule'] = parentid ? resource : relmod;
		defaultSearchquery['moduleRelationType'] = reltype;
		defaultSearchquery['joinCondition'] = 'AND';
	} else if(parentid) {
		defaultSearchquery = {};
		defaultSearchquery['id'] = parentid;
	} else {
		defaultSearchquery = null;
	}


	const translate = useTranslate();
	const redirect = useRedirect();
	const [ records, setRecords ] = useState([]);
	const [relatedModules, setRelatedModules] = useState([]);
	const [relatedSearchshow, setRelatedSearchshow] = useState(false);
	const [selectedSearchValue, setSelectedSearchValue] = useState(null);


	useEffect(() => {
		doQuery(`SELECT * FROM  ${resource} LIMIT 100`).then((result) => {
			setRecords(result);
		});
	}, [resource])
	
	
	useEffect(() => {
		const getRelatedMod = (parentMod, describeModulesData) => {
			let iwEntRelatedModList = describeModulesData[parentMod]?.relatedModules;
			iwEntRelatedModList = iwEntRelatedModList ? Object.values(iwEntRelatedModList) : [];
			const rModule = iwEntRelatedModList.filter((rMod) => rMod.related_module === resource)[0] ?? null;
			return rModule;
		}
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
			return result;
		}).then(async describeData => {
			setDescribe(describeData);
			const defaultFilter = {};
			const hiddenFilterFields = [];
			const iwEntiToCurrentMod = getRelatedMod('iwEnti', describeData);
			const iwDipendentiToCurrentMod = getRelatedMod('iwDipendenti', describeData);
			setIsLoadingRelated(true);
			if(iwEntiToCurrentMod){
				const parentRecords = await doQuery(`SELECT id FROM  iwEnti LIMIT 1`);
				defaultFilter[iwEntiToCurrentMod.relatedfield] = parentRecords && parentRecords[0] ? parentRecords[0].id : '';
				hiddenFilterFields.push(iwEntiToCurrentMod.relatedfield);
			}
			if(iwDipendentiToCurrentMod){
				const parentRecords = await doQuery(`SELECT id FROM  iwDipendenti LIMIT 1`);
				defaultFilter[iwDipendentiToCurrentMod.relatedfield] = parentRecords && parentRecords[0] ? parentRecords[0].id : '';
				hiddenFilterFields.push(iwDipendentiToCurrentMod.relatedfield)
			}
			setSpecialDefaultFilter(defaultFilter);
			setHiddenFilterFields(hiddenFilterFields);
			setIsLoadingRelated(false);

			setLabel(describeData[resource]?.label);
			setLinkfields(describeData[resource]?.filterFields?.linkfields ?? []);
			let labelfields = describeData[resource].labelFields.split(',');
			setLabelField(labelfields[0]);

			let ffields = describeData[resource]?.filterFields?.fields ?? [];
			const objFilterFields = [];
			let modFields = [];
			const allFields = describeData[resource]?.fields ?? [];
			
			for (let f = 0; f<ffields.length; f++) {
				const field = allFields.find((element) => {
					return element.name === ffields[f];
				});
				if(field){
					modFields.push(field);
					objFilterFields.push(field);
				}
			}
			
			setFilterFields(objFilterFields);

			getRelatedModules(resource).then((rMods) => { 
				const relatedMods = [];
				let describeRelatedMod = {};
				if(rMods && rMods.blocks){
					const rModules = Object.values(rMods.blocks);
					rModules.forEach((rMod) => {
						if(describeData[rMod.loadfrom]){
							describeRelatedMod = describeData[rMod.loadfrom]
							describeRelatedMod.relatedfield = rMod.relatedfield;
							describeRelatedMod.moduleRelationType = rMod.relatedfield ? '1:N' : 'N:N';
						} else {
							describeRelatedMod = {};
							describeRelatedMod.name = rMod.loadfrom;
							describeRelatedMod.label = rMod.label;
							describeRelatedMod.relatedfield = rMod.relatedfield;
							describeRelatedMod.moduleRelationType = rMod.relatedfield ? '1:N' : 'N:N';
						}
						relatedMods.push(describeRelatedMod);
					});
				}

				setRelatedModules(relatedMods);
			}).catch(e=>console.log(e));

			if(relmod && relfieldval && reltype){
				setLinkfields(describeData[relmod]?.filterFields?.linkfields ?? []);
				let labelfields = describeData[relmod].labelFields.split(',');
				setLabelField(labelfields[0]);	

				const defaultRelFilterFields = describeData[relmod]?.filterFields?.fields ?? [];
				const defaultRelFields = describeData[relmod]?.fields ?? [];

				const modFieldsObj = [];
				if(!relfield){ // M:M Relation
					defaultRelFields.forEach(field => {
						if(defaultRelFilterFields.includes(field.name)){
							modFieldsObj.push(field);
						}
					});	
					setFields(modFieldsObj);
				} else {
					let relatedListColumnNames = [];	

					if(parentid){
						relatedListColumnNames = 	describeData[resource]?.relatedModules?.[relmod]?.filterFields?.fields ?? [];
						defaultRelFields.forEach(field => {
							if(defaultRelFilterFields.includes(field.name)){
								modFieldsObj.push(field);
							}
						});	
						
					} else {
						relatedListColumnNames = 	describeData[relmod]?.relatedModules?.[resource]?.filterFields?.fields ?? [];
						allFields.forEach(field => {
							if(relatedListColumnNames.includes(field.name)){
								modFieldsObj.push(field);
							}
						});		
					}

					setFields(modFieldsObj);
				}
				
			} else {
				setFields(modFields);
			}
		}).catch(() => {
			console.log(translate('translations.somethingWentWrong'));
		})
	}, [parentid, relfield, relfieldval, relmod, reltype, resource, translate])


	const handleSearchValueChange = (newRecord) => {
		setSelectedSearchValue(newRecord);
		redirect(`/${resource}?parentid=${newRecord.id}`);
	}

	const handleRelatedModule = (rModule, pid) => {
		redirect(`/${resource}?parentid=${pid}&relmod=${rModule.name}&relfield=${rModule.relatedfield}&relfieldval=${pid}&reltype=${rModule.moduleRelationType}`);
	}

	const handleDefaultList = () => {
		setRelatedSearchshow(false);
		setSelectedSearchValue({});
		redirect(`/${resource}`);
	}


	return (
		<>
		{!modPermission || !modPermission.read
			? 	<Box justifyContent="center" alignContent="center" alignItems="center"> 
					<h2>ACCESS DENIED</h2>
				</Box>
			: 	<>
					{ relatedSearchshow && 
					<>
						<Box style={{width: '75%'}} mx={'auto'} my={2}>
							<Autocomplete
								id="searchBox"
								options={records}
								value={selectedSearchValue}
								getOptionLabel={(option) => option[describe[resource].labelFields.split(',')[0]??'']}
								fullWidth
								renderInput={(params) => <TextField {...params} label={translate('Search for') + ` ${resource}` } variant="outlined" />}
								onChange={(e, aRecord) => handleSearchValueChange(aRecord)}
							/>
						</Box>
						{ parentid && relatedModules && relatedModules.length > 0  &&
							<Box my={3} >
								<Grid container spacing={0} >
									{ relatedModules && relatedModules.length > 0 && 
										<Grid key={'viewAllIndex0'} item xs={6} md={2} style={{marginBottom: '20px'}}>
											<Card 
												onClick={() => handleDefaultList()} 
												elevation={4} 
												className={'relatedModCard'}
												>
												<Typography variant="subtitle2"> {translate('View All')} </Typography>
											</Card>
										</Grid>
									}
									{
										relatedModules.map((relatedMod, index) => {
											return (
												<Grid key={index} item xs={6} md={2} style={{marginBottom: '20px'}}>
													<RelatedModCard parentMod={resource} parentId={parentid || relfieldval} relatedMod={relatedMod} handleRelatedModule={handleRelatedModule}  />
												</Grid>
											)
										})	
									}
								</Grid>
							</Box>
						}
					</>
					}
					{isLoadingRelated
						?	<Loading />
						:	<ListView 
								resource={ parentid ? relmod : resource } 
								basePath={relmod ? `/${relmod}` : `${resource}`} 
								fields={fields} 
								filterFields={filterFields} 
								linkFields={ linkFields }
								labelField={ labelField }
								describe={describe}
								title={label}
								filterDefaultValues={ defaultSearchquery ? defaultSearchquery : specialDefaultFilter ? specialDefaultFilter : {} }
								relmod={relmod}
								relfield={ relfield }
								relfieldval={relfieldval}
								hiddenFilterField={hiddenFilterField}
								otherProps={{setRelatedSearchshow: setRelatedSearchshow, relatedSearchshow: relatedSearchshow }}
							/>
					}
				</>
		}
		</>
	)
};

import { useState, MouseEvent } from 'react';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import {
    useLogin, useNotify, useRedirect, Notification, useTranslate
} from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles/createTheme';
import { COMPANY_LOGO_URL } from '../constant';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';



export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& .MuiFormLabel-root': {
            color: '#fff !important',
        },
        '& .MuiTextField-root': {
            margin: '10px auto',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#DCDCDC',
            color: '#fff !important',
        },
        '& .MuiOutlinedInput-root': {
            color: '#fff !important',
            '& fieldset': {
                borderColor: '#DCDCDC',
            },
            '&:hover fieldset': {
                borderColor: '#DCDCDC',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#DCDCDC',
            },
        },
        '& .MuiTypography-body1': {
            fontSize: '12px'
        },
        '& .MuiBox-root': {
            width: '100%',
        }
    },
    authTitle: {
        fontSize: '20px',
        color: 'white'
    },
    authContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        backgroundImage: 'url(appLoginBg.png)' ,
        backgroundSize: 'cover',
    },
    authCard: {
        [theme.breakpoints.up('sm')]: {
            width: '30%',
        },
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        padding: 0,
        margin: 0,
        background: 'rgb(199 201 224 / 69%) !important',
        border: 'none',
    },
}));

const Login = () => {

    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const translate = useTranslate();
    const [showPassword, setShowPassword] = useState<boolean>(false);


    const redirect = useRedirect();
    const login = useLogin();
    const notify = useNotify();

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        login({ username, password }).then((res) => {
            redirect('/');
        }).catch(() => {
            notify(translate('Incorrect username or password'), { type: 'error' });
            setIsLoading(false);
        });
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    return (
        <Box className={classes.root}>
            <Box className={classes.authContainer} >
                <Card className={classes.authCard} variant="elevation" elevation={0}>
                    <CardContent sx={{p: 4}} >
                        <Box p={4} mt={3} sx={{border: '1px solid #ddd',}}>
                            { COMPANY_LOGO_URL && 
                                <Box style={{width: '100%', margin: 'auto'}}>
                                    <img src={COMPANY_LOGO_URL} alt={'Company Logo'} style={{width: '100%', height: '100%'}} />
                                </Box>
                            }
                            <Box mt={2}>
                                <form onSubmit={onSubmit}>
                                    <Box>
                                        <Typography variant="subtitle2" sx={{fontWeight: 550}}> Username </Typography>
                                        <TextField
                                            label=""
                                            name={'username'}
                                            fullWidth
                                            id="usernameInput"
                                            variant="outlined"
                                            size="small"
                                            required
                                            onChange={e => setUsername(e.target.value)}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" sx={{fontWeight: 550}}> Password </Typography>
                                        <TextField
                                            label=""
                                            name={'password'}
                                            fullWidth
                                            id="passwordInput"
                                            variant="outlined"
                                            size="small"
                                            required
                                            onChange={e => setPassword(e.target.value)}
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                    </Box>
                                    <Box my={2}>
                                        <Button
                                            fullWidth
                                            type={'submit'}
                                            disabled={isLoading}
                                            startIcon={isLoading ? <CircularProgress color="primary" size={24} /> : <></>}
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                        >
                                            Login
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Notification />
        </Box>
    );
};

export default Login;
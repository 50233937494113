import React, { useState, useEffect, useCallback } from 'react';
import { Create, TabbedForm, FormTab, Toolbar, SaveButton, TabbedFormTabs, useCreate, useTranslate, useNotify, useRedirect, FormDataConsumer, useResourceContext, useResourceDefinition, ListButton } from 'react-admin';
import cbUtils from '../corebosUtils/corebosUtils';
import { filterDependencyMaps, getDataFromLocalDb, uploadFile, handleSpecialChars, reformatPostingData } from '../../utils/Helpers';
import { doInvoke, remoteValidate } from '../../utils/lib';
import { TABLE_DESCRIBE, TABLE_AUTH } from '../../local-db';
import { parse } from 'query-string';
import {
	Box,
	Grid,
	CircularProgress,
	Typography,
} from '@mui/material';
import { DocumentUpload } from '../DocumentUpload';
import { useLocation } from "react-router";
import queryString from 'query-string';

import { MarkDownInput } from '../MarkDown';
import { DependentField } from '../DependentField';
import { ParentDependencyField } from '../ParentDependencyField';
import EmailDialog from '../EmailSendView';
import { EmailDialogProps } from '../../models/EmailDialogProps';
import CloseIcon from '@mui/icons-material/Close';


export const CbCreateTabGuesser = () => {
	const { options } = useResourceDefinition();
	const resource = useResourceContext();
	const [describe, setDescribe] = useState<any>({});
	const [blocks, setBlocks] = useState<any[]>([]);
	const [user, setUser] = useState<any>({});
	const location = useLocation();
	const urlQueryString = queryString.parse(location.search);
	const modPermission = options?.permission[0]?.permissions ?? {};
	const [isLoading, setIsLoading] = useState(false);
	const [uploadedFileList, setUploadedFileList] = useState<any[]>([]);
	const [create] = useCreate();
	const translate = useTranslate();
	const notify = useNotify();
	const [dependentFields, setDependentFields] = useState<any[]>([]);
	const [dependentFieldsNames, setDependentFieldsNames] = useState<any[]>([]);
	const redirect = useRedirect();

	const { relatedModule, relatedField, relatedFieldValue } = parse(location.search);
	const defaultValue: { [k: string]: any } = {};
	let redirectUrl = '';
	if (relatedModule && relatedField && relatedFieldValue) {
		defaultValue[relatedField as string] = relatedFieldValue;
		redirectUrl = `/${relatedModule}/${relatedFieldValue}/show?showrelmod=1&relmod=${resource}`;
	}
	const [emailDialogProps] = useState<EmailDialogProps>({ open: false, parentResource: resource, toggleDialog: () => { } });


	for (const key in urlQueryString) {
		if (typeof key === 'string') {
			defaultValue[key] = urlQueryString[key];
		}
	}
	const [markdownInputValue, setMarkdownInputValue] = useState({});
	const fetchMaps = useCallback(async () => {
		const module = resource + '_FieldDependencyPortal';

		let currentMap = await doInvoke('ProcessMap', { mapid: module }).catch((err: any) => { console.log(err); });

		if (currentMap && typeof currentMap !== "undefined") {
			const { allMaps, fieldNames }: { allMaps: any[], fieldNames: any[] } = filterDependencyMaps(currentMap);
			setDependentFields(allMaps);
			setDependentFieldsNames(fieldNames);
		}


	}, [resource]);


	useEffect(() => {
		getDataFromLocalDb(TABLE_AUTH.tableName).then((result) => {
			setUser(result?.user ?? {});
		});
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then(async (result: any) => {
			setDescribe(result);
			let bfields: any[] = [];
			let mfields: any = result[resource]?.fields ?? [];
			for (let f = 0; f < mfields.length; f++) {
				if (!mfields[f].block) {
					continue;
				}
				let bidx = bfields.findIndex((element: any) => element.id === mfields[f].block.blockid);
				if (bidx === -1) {
					bfields.push({
						id: mfields[f].block.blockid,
						sequence: mfields[f].block.blocksequence,
						label: mfields[f].block.blocklabel,
						name: mfields[f].block.blockname,
						fields: []
					});
					bidx = bfields.findIndex((element: any) => element.id === mfields[f].block.blockid);
				}
				bfields[bidx].fields.push(mfields[f]);
			}

			setBlocks(bfields);
			await fetchMaps();
		});
	}, [fetchMaps, resource])


	const saveThisImage = async function* (data: any, file: any) {
		const title = '';
		const result = await handleUpload(file, title);
		if (result instanceof Error) {
			yield '';
			return true;
		}
		yield result?._downloadurl;
		return true;
	};

	const handleUpload = async (file: any, title: string) => {
		setIsLoading(true);
		const result = await uploadFile(file, title, '', '', '');
		if (result instanceof Error) {
			setIsLoading(false);
			return null;
		}
		setUploadedFileList((uploadedFileList: any[]) => { return [...uploadedFileList, result] });
		setIsLoading(false);
		return result;
	}

	const CustomToolbar = (props: any) => {
		return (
			<Toolbar {...props}>
				<ListButton label={translate('ra.action.cancel')} title={translate('ra.action.cancel')} size="small" color="primary" variant="outlined" sx={{ textTransform: 'none', mr: 1, py: 1, px: 2 }} icon={<CloseIcon />} {...props} />
				{modPermission && modPermission.create &&
					<SaveButton
						label={translate('ra.action.save')}
						icon={isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary'} size={24} /> : <></>}
						disabled={props.pristine || isLoading}
						size="small"
						sx={{ textTransform: 'none', mr: 1, py: 1, px: 2 }}
						title={translate('ra.action.save')}
						{...props}
					/>
				}
			</Toolbar>
		);
	}

	const saveRecord = useCallback(
		async (values: any, fileList: any[], currentMarkdownInputValue: any) => {
			setIsLoading(true);
			for (const key in values) {
				const value = values[key];
				if (value && value.rawFile) {
					const result = await handleUpload(value.rawFile, '');
					values[key] = result?._downloadurl ?? '';
				}
			}
			let dataTosend: any = Object.assign(values, currentMarkdownInputValue);
			dataTosend = reformatPostingData(dataTosend);
			const validationRes: any = await remoteValidate('', resource, dataTosend);
			if (validationRes.isValid) {
				create(resource, { data: dataTosend }, {
					onSuccess: (result: any) => {
						const fileIds: any[] = [];
						fileList.forEach((uploadedDocument: any) => {
							fileIds.push(uploadedDocument.id);
						});
						doInvoke('SetRelation', { relate_this_id: result?.id, with_these_ids: JSON.stringify(fileIds) }, 'POST').then(() => {
							if (redirectUrl) {
								redirect(redirectUrl);
							} else {
								setUploadedFileList([]);
								setMarkdownInputValue('');
								redirect(`/${resource}/${result?.id}/show`)
							}
						}).catch(() => {
							notify(`Upload: ${translate('Something went wrong')}`, { type: 'error' });
						}).finally(() => {
							setIsLoading(false);
						})
					},
					onError: () => {
						setIsLoading(false);
						notify(translate('Something went wrong'), { type: 'error' });
					},
				});
			} else {
				setIsLoading(false);
				notify(validationRes?.errors[0] ?? '', { type: 'error' });
			}
		},
		[create, notify, redirect, redirectUrl, resource, translate],
	);

	const handleMarkdownField = (fieldName: string, value: string, cureentMarkdownInputValue: any) => {
		cureentMarkdownInputValue[fieldName] = handleSpecialChars(value);;
		setMarkdownInputValue(cureentMarkdownInputValue);
	}



	return (
		<>
			<EmailDialog {...emailDialogProps} />
			<Box px={0.5} >
				<Grid container spacing={1}>
					<Grid item xs={12} md={12}>
						<Box className='raPageContent'>
							{resource === 'Documents'
								? <DocumentUpload afterUpload={null} uploadResource={resource} assigned_user_id={user?.id} relationsIds={''} relProjectId={''} />
								: <Create>
									<TabbedForm defaultValues={defaultValue} onSubmit={(values: any) => saveRecord(values, uploadedFileList, markdownInputValue)} toolbar={<CustomToolbar />} syncWithLocation={false} tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />} >
										{
											blocks.map((block, bidx) => {
												return (
													<FormTab key={"fbrefblk" + bidx} label={block.name}>
														<Box style={{ width: "100%" }} py={3}>
															<Grid container spacing={2}>
																{block.fields.map((field: any, idx: number) => {
																	if (field.uitype === "19") {
																		return (
																			<Grid key={field.uitype + idx} item xs={12} md={12}>
																				<Box my={2}>
																					<Typography variant="subtitle2">
																						{" "}
																						{field.label}{" "}
																					</Typography>
																					<MarkDownInput
																						record={defaultValue}
																						source={field.name}
																						handleMarkdownField={handleMarkdownField}
																						saveThisImage={saveThisImage}
																						cureentMarkdownInputValue={markdownInputValue}
																					/>
																				</Box>
																			</Grid>
																		);
																	}
																	const formattedDepenedncyMaps =
																		dependentFieldsNames.filter(
																			(e: any) => e?.dependentFieldName === field?.name
																		);
																	const formattedDepenedncyMapsForParent =
																		dependentFieldsNames.filter(
																			(e) => e.parentFieldName === field?.name
																		);
																	const cbField = cbUtils.field2InputElement(
																		field,
																		resource,
																		{ fullWidth: true },
																		describe,
																		'',
																		'',
																		{ handleClick: field.uitype === "13" ? null : null }
																	);
																	return cbField ? (
																		<Grid key={idx} item xs={12} md={6}>
																			<FormDataConsumer>
																				{({ formData }) =>
																					<>
																						{formattedDepenedncyMaps.length > 0 ? ( //* field is dependent field in dependency maps
																							<DependentField
																								formattedDepenedncyMap={formattedDepenedncyMaps}
																								describe={describe}
																								resource={resource}
																								field={field}
																								source={field.name}
																								formValues={formData}
																								dependencyMaps={dependentFields}
																							/>
																						) : formattedDepenedncyMapsForParent.length > 0 ? ( // *field is parent field in dependency maps
																							<ParentDependencyField
																								formattedDepenedncyMap={
																									formattedDepenedncyMapsForParent
																								}
																								input={cbField}
																								describe={describe}
																								resource={resource}
																								field={field}
																								source={field.name}
																								formValues={formData}
																								dependencyMaps={dependentFields}
																							/>
																						) : (
																							cbField
																						)}
																					</>
																				}
																			</FormDataConsumer>
																		</Grid>
																	) : (
																		<></>
																	);
																})}
															</Grid>
														</Box>
													</FormTab>
												);
											})
										}
									</TabbedForm>
								</Create>
							}
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	)
};

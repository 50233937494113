import * as React from 'react';
import CustomFieldLink from './CustomFieldLink';
import cbUtils from './corebosUtils/corebosUtils';
import { Box, Card, CardContent, Typography } from '@mui/material';


const CbRecordDetail = ({record, fields, describe, linkfields = [], resource, handleDocPreviewModalOpen=null } : {record: any, fields: any[], describe: any, linkfields: any[], resource: string, handleDocPreviewModalOpen: any}) => {

    return (
       <Card style={{ marginBottom: '15px', border: '1px solid #eee' }}>
			<CardContent>
				{
					fields.map((field, index) => {
						let handleFieldClick: any = null;
						if(handleDocPreviewModalOpen && (field.uitype === '28' || field.uitype === '69')){
							handleFieldClick = handleDocPreviewModalOpen;
						}
						return (
							<Box key={index} style={{display: 'flex'}}>
								<Typography variant="subtitle2" fontWeight="bold"> {field.label} </Typography>
								<span style={{margin: 'auto 5px auto 2px'}}>{':'}</span>
								{linkfields.includes(field.name) ? <CustomFieldLink resource={resource} record={record} linkField={field.name} key={index} /> : cbUtils.field2DisplayRecord(field, record, resource, describe, '', '', handleFieldClick)}
							</Box>
						 )
					})
				}
			</CardContent>
	   </Card>
    )
}

export default CbRecordDetail;
import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Droppable } from 'react-beautiful-dnd';
import { RaRecord, useRedirect } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';

import { TaskCard } from './TaskCard';
import { statusNames } from './status';

const useStyles = makeStyles((theme: any) => ({
    root: {
        flex: 1,
        paddingTop: 8,
        paddingBottom: 16,
        backgroundColor: '#eaeaee',
        '&:first-child': {
            paddingLeft: 5,
            borderTopLeftRadius: 5,
        },
        '&:last-child': {
            paddingRight: 5,
            borderTopRightRadius: 5,
        },
    },
    droppable: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        padding: 5,
        '&.isDraggingOver': {
            backgroundColor: '#dadadf',
        },
    },
    createButton: {
        marginLeft: theme.spacing(2),
        cursor: 'pointer',
        position: 'relative',
        top: '8px'
    },
}));

export const TaskColumn = ({
    status,
    data,
    resource,
    handleQuickCreateModal,
    modPermission,
}: {
    status: string;
    data: RaRecord[];
    resource: string
    handleQuickCreateModal: any,
    modPermission: any,
}) => {
    const classes = useStyles();
    const redirect = useRedirect();
    return (
        <div className={classes.root}>
            <Typography align="center" variant="subtitle1">
                {/* @ts-ignore */}
                {statusNames[status]}  <AddIcon onClick={() => redirect(`/ProjectTask/create/?projecttaskstatus=${statusNames[status]}`)}  className={classes.createButton} />
            </Typography>
            <Droppable droppableId={status}>
                {(droppableProvided, snapshot) => (
                    <div
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                        className={
                            classes.droppable +
                            (snapshot.isDraggingOver ? ' isDraggingOver' : '')
                        }
                    >
                        {data?.map((task, index) => (
                            <TaskCard key={index} modPermission={modPermission} index={index} task={task} resource={resource} handleQuickCreateModal={handleQuickCreateModal} />
                        ))}
                        {droppableProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

import React from 'react';
import CustomFieldLink from './CustomFieldLink';


const Title = ({ resource, label, showTitle = true, record, linkField } : { resource: string, label: any, showTitle: boolean, record: any, linkField: string }) => {

	if(!showTitle) return <></>;
	if(label) return label;
	if(!linkField) return <></>;
	return (
		<>{' '}<CustomFieldLink resource={resource} record={record}  linkField={linkField} /></>
	)
   
};

export default Title;
import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { 
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Tabs,
    Tab,
    Box,
    CircularProgress,
    useMediaQuery,
    Theme,
} from '@mui/material';
import { 
    makeStyles, 
    withStyles, 
} from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { AppState } from '../types';
import PropTypes from 'prop-types';
import { useTranslate, Create, SimpleForm, SaveButton, Button, useNotify, useRedirect, List, Datagrid, useUnselectAll, Filter, SearchInput, useRefresh, FunctionField } from 'react-admin';
import cbUtils from './corebosUtils/corebosUtils';
import { doInvoke } from '../utils/lib';
import ResponsiveList from './ResponsiveList';
import CustomFieldLink from './CustomFieldLink';
import { DocumentUpload } from '../component/DocumentUpload';


const StyledTabs: any = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, .125)',
      '& > span': {
        maxWidth: '100%',
        width: '100%',
        backgroundColor: '#fff',
      },
    },
})((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
const StyledTab: any = withStyles((theme: Theme) => ({
    root: {
      border: '1px',
      background: '#fff',
      borderColor: '#eee',
      borderStyle: 'solid',
      textTransform: 'none',
      '&:first-child': {
        borderRight: 'none',
      },
      fontWeight: theme.typography.fontWeightRegular,
      '&:focus': {
        opacity: 1,
      },
    },
}))((props) => <Tab disableRipple {...props} />);
  
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        zIndex: `${theme.zIndex.drawer + 2} !important`,
        '& .MuiDrawer-paper': {
            width: '40%',
        }
    },
    tabsRoot: {
        flexGrow: 1,
        width: '90%',
        margin: 'auto',
    },
    title: {
        flexGrow: 1,
    },
    tabsContainer: {
        width: '100%',
        marginTop: '30px',
        backgroundColor: '#fff',
    },
}));


const  TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;


    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
              {children ? children : <CircularProgress color="inherit" size={18} thickness={5} /> }
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const CbCreateRelatedRecordDrawer = ({relatedModDetails, relatedFieldValue, open, toggleRelatedModuleDrawer, describe, relatedFields, linkfields, modPermissions, handleDocPreviewModalOpen, user, ...props } : {relatedModDetails: any, relatedFieldValue: any, open: boolean, toggleRelatedModuleDrawer: any, describe: any, relatedFields: any[], linkfields: any[], modPermissions: any, handleDocPreviewModalOpen:any, user: any }) => {
    const resource = relatedModDetails.name;
    const classes = useStyles();
    const theme = useSelector((state: AppState) => state.theme);
    const [value, setValue] = useState(0);
    const translate = useTranslate();
    const fields = describe[resource]?.fields;
    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const defaultValues: any = {};
    defaultValues[relatedModDetails?.relatedfield] = relatedFieldValue;
    const unselectAll = useUnselectAll(resource);
    const refresh = useRefresh();
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));


    const relateRecords = ({selectedIds}:{selectedIds: any[]}) => {
		const dataToSend = {
			relate_this_id: relatedFieldValue,
			with_these_ids: JSON.stringify(selectedIds)
		};
		doInvoke('SetRelation', dataToSend, 'POST').then(() => {
			notify(translate('translations.dataUpdated'), {type: 'success'});
            refresh();
		}).catch((err: any) => {
			notify(translate('translations.somethingWentWrong'), {type: 'error'});
		}).finally(() => {
			unselectAll();
		})
	}

    const BulkActionButtons = (props: any) => (
		<Button
            label={translate('translations.relateAction')}
            color="primary"
            variant={'contained'}
            onClick={() => relateRecords(props)}
        />
	);

    const CbFilters = (props: any) => {
        return (
            <Box>
                <Filter {...props} >
                    <SearchInput source={'cblistsearch_'+props.resource} alwaysOn fullWidth />
                </Filter>
            </Box>
        )
    }
    
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const onSuccess = (res: any, redirection=true) => {
        const dataToSend = {
            relate_this_id: relatedFieldValue,
            with_these_ids: JSON.stringify([res?.id])
        };
        doInvoke('SetRelation', dataToSend, 'POST').then(() => {
            if(redirection){
                redirect(`/${resource}/${res?.id}/show`);
            } else {
                // Reset form
                notify('Saved!');
                refresh();
                defaultValues[relatedModDetails?.relatedfield] = relatedFieldValue;
            }
        }).catch((err) => {
            notify(err);
        }).finally(() => {
            setIsLoading(false);
        })
    };

    const handleAfterUpload = () => {
        refresh();
        defaultValues[relatedModDetails?.relatedfield] = relatedFieldValue;
        toggleRelatedModuleDrawer();
    }


    const CustomToolbar = (props: any) => {

		return (
			<Toolbar {...props}>
				<SaveButton
                    label={translate('ra.action.save')}
                    icon = { isLoading || props.saving ? <CircularProgress color={ isLoading || props.saving ? 'primary' : 'secondary' } size={24} /> : <></>}
                    disabled={props.pristine || isLoading || props.saving}
                    //submitOnEnter={true}
                    redirect={'show'}
                    size="small"
                    onSuccess={ (record: any) => onSuccess(record, true)}
                    {...props}
                />
                <SaveButton
                    label={translate('ra.action.save') +' & '+ translate('ra.action.add')}
                    icon = { isLoading || props.saving ? <CircularProgress color={isLoading || props.saving ? 'primary' : 'secondary' } size={24} /> : <></>}
                    disabled={props.pristine || isLoading || props.saving}
                    //submitOnEnter={true}
                    redirect={false}
                    to={{ state: { skipFormReset: false } }}
                    size="small"
                    style={{margin: 'auto 10px'}}
                    onSuccess={ (record: any) => onSuccess(record, false)}
                    {...props}
                />
                <Button 
                    label={translate('ra.action.cancel')}
                    variant='outlined'
                    onClick={toggleRelatedModuleDrawer}
                    size='small'
                    color={theme === 'dark' ? 'inherit' : 'primary'}
                />
			</Toolbar>
		);
	}
    

    return (
        <Drawer hideBackdrop={true} open={open} anchor='right' className={classes.root}>
            <AppBar position="static" color={theme === 'dark' ? 'default' : 'primary'} style={{boxShadow: 'none'}}>
                <Toolbar>
                    <Typography className={classes.title}>
                        {translate('translations.associateThisRecord')}
                    </Typography>
                    <IconButton edge="end" color="inherit" aria-label="close button" onClick={() => toggleRelatedModuleDrawer({}, false)} >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Box className={classes.tabsRoot}>
                <Box className={classes.tabsContainer} >
                    <StyledTabs value={value} onChange={handleChange} aria-label={`${value}tab`}>
                        <StyledTab label={translate('translations.associateNew')} />
                        { relatedModDetails && relatedModDetails.moduleRelationType === 'N:N' && 
                            <StyledTab label={translate('translations.associateExisting')} />   
                        }
                    </StyledTabs>
                </Box>
                <Box mt={-2.1} >
                    <TabPanel value={value} index={0}>
                    { resource === 'Documents'
                        ? <DocumentUpload afterUpload={handleAfterUpload} uploadResource={resource} assigned_user_id={user?.id} relationsIds={relatedFieldValue} relProjectId={''} />
                        : <Create
                            resource={resource}
                            title={<></>}
                            >
                            <SimpleForm defaultValues={defaultValues} toolbar={<CustomToolbar />} style={{border: 'none'}}>
                                {
                                    fields.map((field: any) => {
                                        return cbUtils.field2InputElement(field, module, {fullWidth: true}, describe);
                                    })
                                }
                            </SimpleForm>
                        </Create>
                    }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box mt={5}>
                            <List
                                resource={resource}
                                title={<></>}
                                perPage={10}
                                bulkActionButtons={<BulkActionButtons />}
                                filters={<CbFilters />}
                                actions={false}
                                component="div"
                                {...props}
                                >
                                    {isSmall ? (
                                       <ResponsiveList resource={resource} fields={relatedFields} linkFields={linkfields} linkField={linkfields[0]} describe={describe} modPermission={modPermissions} />
                                    ) : (
                                        <Datagrid>
                                        {
                                            relatedFields.map((field: any, index: number) => {
                                                if(field.uitype === '28'){
                                                    return(
                                                        <FunctionField label={translate('File')} render={(record: any) => {
                                                            return (
                                                                <>
                                                                    { record.filetype.split('/')[0] === 'image' 
                                                                        ? <img 
                                                                                key={index}
                                                                                src={record?._downloadurl} 
                                                                                alt={record?.notes_title} 
                                                                                style={{maxWidth: '40%', margin: 'auto', cursor: 'pointer' }} 
                                                                                onClick={ () => handleDocPreviewModalOpen(record)}
                                                                            />
                                                                        : 	<Button 
                                                                                key={index}
                                                                                color='primary' 
                                                                                label={record?.notes_title} 
                                                                                onClick={ () => handleDocPreviewModalOpen(record)}
                                                                            />
                                                                    }
                                                                </>
                                                                
                                                            )}
                                                        } />
                                                    )
                                                } else {
                                                    return linkfields.includes(field.name) ? <CustomFieldLink record={{}} resource={resource} linkField={field.name} key={index} {...props} /> : cbUtils.field2DisplayElement(field, resource, describe);
                                                }
                                            })
                                        }
                                    </Datagrid>
                                    )}
                            </List>
                        </Box>
                    </TabPanel>
                </Box>
            </Box>
        </Drawer>
    )
}

export default CbCreateRelatedRecordDrawer;
import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    ShowBase,
    useRecordContext,
    useRedirect,
    Button,
    useDelete,
    useNotify,
    Notification,
    EditButton,
    useRefresh,
    Loading,
    useTranslate,
} from 'react-admin';
import {
    Box,
    Typography,
    Divider,
    Grid,
    Card,
    CardContent,
    useMediaQuery,
    CardHeader,
    Paper,
} from '@mui/material';


import { UserAvatar } from './User';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCommentStyle } from './comment/useCommentStyle';
import { CommentList } from './comment/CommentList';
import CommentForm from './comment/CommentForm';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QuickCreateModal from '../component/QuickCreateModal';
import { getDataFromLocalDb } from '../utils/Helpers';
import { TABLE_DESCRIBE } from '../local-db';
import cbUtils from '../component/corebosUtils/corebosUtils';
import { MarkDownPreview } from '../component/MarkDown';
import { doQuery } from './lib';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';


export const TaskShow = (props: any) => {
    //const classes = useCommentStyle();
    const [ reloadTriggerID, setReloadTriggerID ]  = useState<any>(null);
    const refresh = useRefresh();

    useEffect(() => {
        refresh();
    }, [reloadTriggerID, refresh])

    return (
        <ShowBase resource={props?.resource} id={props?.id}>
            <TaskShowContent {...props} reloadTriggerID={reloadTriggerID} setReloadTriggerID={setReloadTriggerID} resource={props?.resource} />
        </ShowBase>
    );
};

const TaskShowContent = (props:any) => {
    const classes = useCommentStyle();
    const record = useRecordContext();
    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const [deleteOne] = useDelete();
    const resourceComment = 'ModComments';
    const modPermission = props?.options?.permission[0]?.permissions??{};
    const translate = useTranslate();
    const [quickCreateOpen, setQuickCreateOpen] = useState<boolean>(false);
    const [ filterFields, setFilterFields ] = useState<any[]>([]);
    const [ describe, setDescribe ] = useState<any>({});
    const [ labelField, setLabelField ] = useState<any>('');
    const [taskActivities, setTaskActivities] = useState<any[]>([]);
    const isMedium = useMediaQuery((theme: any) => theme.breakpoints.up('md'));


    useEffect(() => {
        doQuery(`SELECT * FROM JustLogIt WHERE rel_id=${props?.id} ORDER BY createdtime DESC LIMIT 10`).then((result: any) => {
            setTaskActivities(result);
        }).catch((err) => {
            console.log('error', err);
            console.log('Something went wrong');
        })
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
            setDescribe(result);
			const modFields = result[props.resource]?.fields ?? [];
			const fFields = result[props.resource]?.filterFields?.fields ?? [];
            let lfield = result[props.resource].labelFields.split(',');
            lfield = lfield[0] ?? '';
            setLabelField(lfield);
            let filters = [];
            for (let index = 0; index < modFields.length; index++) {
                if(fFields.includes(modFields[index].name)){
                    filters.push(modFields[index]);
                }
            }
            setFilterFields(filters);
		});
	}, [props.resource, props?.id])




    const handleDelete = (record: any) => {
        setIsLoading(true);
        deleteOne(
            props?.resource,
            { id: record.id, previousData: record },
            {
                onSuccess: () => {
                    setIsLoading(false);
                    notify('Record deleted', {type: 'success'});
                    redirect(`${props.resource}`);
                    //window.location.reload();
                    
                },
                onError: (error: any) => {
                    setIsLoading(false);
                    notify(error.message, {type: 'error'});
                }
            }
        );
    }

    const handleQuickCreateModal = (open: boolean) => {
        setQuickCreateOpen(open);
    };


    if (!record) return <Loading />;


    return (
        <>
            <QuickCreateModal resource='Timecontrol' relatedMod={props?.resource} defaultValues={{relatedto: record?.id}} title={record?.projecttaskname} open={quickCreateOpen} handleQuickCreateModal={handleQuickCreateModal} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} >
                    <Box mt={2}>
                        <Card elevation={1} style={{width: 'auto', padding: '16px'}}>
                            <CardContent>
                                <Box display={ isMedium ? 'flex': 'block' } mb={3} style={{width: '100%'}}>
                                    <Box justifyContent="start" style={{width: '100%'}}>
                                        <Typography variant="h6">{record[labelField]}</Typography>
                                        <Typography variant="body2">{record.projecttask_no}</Typography>
                                    </Box>
                                    <Box justifyContent={ isMedium ? 'end': 'start' } px={0} display='flex' style={{width: '100%'}}>
                                        <Button color='primary' onClick={ () => handleQuickCreateModal(true)} label={translate('Time Control')} >
                                            <AccessTimeIcon />
                                        </Button>
                                        {modPermission && modPermission.update && 
                                            <EditButton
                                                to={`/${props.resource}/${record.id}`}
                                                label="Edit"
                                                record={JSON.parse(JSON.stringify(record))}
                                                className={classes.taskActionButton}
                                            />
                                        }
                                        {modPermission && modPermission.delete && 
                                            <Button className={classes.deleteButton} onClick={() => handleDelete(record)} disabled={isLoading} label={'Delete'} >
                                                <DeleteIcon />
                                            </Button>
                                        }
                                    </Box>
                                </Box> 
                            
                                <Grid container spacing={2}>
                                    { filterFields.map((field: any) => {
                                            let referenceCustomLink = '';
                                            if(field.name === 'projectid' ){
                                                referenceCustomLink = `/${'ProjectTask'}?projectid=${record?.projectid}`;
                                            }
                                            return (
                                                <>
                                                    { field.name !== labelField && 
                                                        <Grid item xs={12} md={2} >
                                                            <Box>
                                                                <Typography color="textSecondary" variant="body2">
                                                                    {field.label}
                                                                </Typography>
                                                                { cbUtils.field2DisplayElement(field, props.resource, describe, '', referenceCustomLink)  }
                                                            </Box>
                                                        </Grid>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </Grid>
                                <Box mt={2} className='cbRaMarkdownPreview'>
                                    <MarkDownPreview record={record} source='description' />
                                </Box>
                            </CardContent>
                        </Card>
                        <Box>
                            <Divider />
                            <Box>
                                <CommentForm handleCancel={null} replyingComment={null} comment={null} isNewRecord={true} projectTask={record} setReloadTriggerID={props.setReloadTriggerID} resource={'ModComments'} />
                            </Box>
                            <Box>
                                <CommentList resource={resourceComment} reloadTriggerID={props.reloadTriggerID} setReloadTriggerID={props.setReloadTriggerID} projectTask={record}  />
                            </Box> 
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box my={2}>
                        <Card elevation={2} >
                            <CardContent>
                                <Box
                                    width={50}
                                    mx={'auto'}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <UserAvatar size={'large'} record={record} referenceCustomLink={`/Contacts/${record.assigned_user_id}/show`} heading={'Assignee:'} title={record.assigned_user_idename?.reference} sourceField={'user_name'}  refSourceField={'assigned_user_id'} resource={props?.resource}  />
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box my={2}>
                        <Card elevation={2} >
                            <CardHeader
                                title={translate('Activities')}
                                subheader={record[labelField]}
                            />
                            <Timeline className='justLogItTimeline'>
                            {
                                taskActivities.map((activity: any, index: number) => {
                                    return (
                                        <TimelineItem key={index}>
                                            <TimelineSeparator>
                                                <TimelineDot>
                                                    <FastfoodIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Paper style={{border: 'none'}}>
                                                    <Typography variant="body1" color="textPrimary"> {activity.doneon}, {activity.hora} </Typography>
                                                    <Typography variant="body2" color="inherit"> {activity.logmessage} </Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                    )
                                })
                            }
                            </Timeline>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
            
            <Notification />
        </>
    );
};





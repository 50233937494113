import * as cbconn from 'corebos-ws-lib/WSClientm';

export const getChallenge = async (username, password) => {
    const challengeRes = await cbconn.doLogin(username, password);
    return challengeRes;
}

export const getListTypes = async () => {
    const listTypes = await cbconn.doListTypes();
    return listTypes;
}

export const getModDescribe = async (moduleName) => {
    const describe = await cbconn.doDescribe(moduleName);
    return describe;
}

export const doRetrieve = async (record) => {
    const result = await cbconn.doRetrieve(record);
    return result;
}

export const doMassRetrieve = async (ids) => {
    const result = await cbconn.doMassRetrieve(ids);
    return result;
}

export const doQuery = async (query) => {
    const result = await cbconn.doQuery(query);
    return result;
}

export const doInvoke = async (method, params, type) => {
    const result = await cbconn.doInvoke(method, params, type);
    return result;
}

export const doCreate = async (moduleName, data) => {
    const result = await cbconn.doCreate(moduleName, data);
    return result;
}
export const massCreate = async (data) => {
    const result = await cbconn.doMassUpsert(data);
    return result;
}

export const getMenu = async () => {
    const result = await cbconn.doInvoke('SearchGlobalVar', {gvname: 'BusinessMapping_Menu'}, 'GET').catch(() => {
        return null;
    });
    if(result && result.id){
        let menu = await cbconn.doInvoke('ProcessMap', {mapid: result.id}, 'GET');
        return menu ? JSON.parse(menu) : null;
    }
    return null;
}


export const getRelatedModules = async (moduleName) => {
    const result = await cbconn.doInvoke('ProcessMap', { mapid: `${moduleName}_DetailViewLayoutMapping` }, 'GET');
    return result;
}

export const getListColumns = async (moduleName) => {
    const result = await cbconn.doInvoke('ProcessMap', { mapid: `${moduleName}_ListColumns` }, 'GET');
    return result;
}

export const remoteValidate = async (id, module, values) => {
    const data = await cbconn.doValidateInformation(id, module, values)
    .catch(function (error) {
        return error;
    });

    let errors = {}
    if(data && typeof data === 'object'){
        for (let [key, value] of Object.entries(data)) {
            errors[key] = value[0]
        }
    }

    if (Object.keys(errors).length > 0) {
        return {isValid: false, errors: Object.values(errors)}
    }

    return {isValid: true, errors: []};
}
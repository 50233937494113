import React, { useState, useEffect } from 'react';
import { 
    List, 
    BulkDeleteButton,
    Datagrid,
    FunctionField,
    Loading,
    useListContext,
    useRedirect,
} from 'react-admin';
import DocumentPreviewModal from './DocumentPreview/DocumentPreviewModal';
import ResponsiveList from './ResponsiveList';
import { ListActionToolbar } from './ListActionToolbar';
import { CBListFilter } from './CBListFilter';
import CustomFieldLink from './CustomFieldLink';
import cbUtils from './corebosUtils/corebosUtils';
import RowAction from './RowAction';
import { authProvider } from '../authProvider';
import { Card, Grid, Box, Divider, CardContent, CardActions, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import ListIcon from '@mui/icons-material/List';
import GridOnIcon from '@mui/icons-material/GridOn';


const useStyles = makeStyles(() => ({
    root: {
        '& .RaDatagrid-headerCell': {
            fontWeight: 'bold',
        },
    },
    raGridContent: {
        marginTop: '15px',
    },
    content: {
        marginBottom: '15px',
    },
    cardAction: {
        padding: '10px 15px',
    },
    cardAcionBox: {
        border: '1px solid #ddd',
    },
    cardAcionRightBox: {
        float: 'right',
        textAlign: 'right'
    },
    gridCard: {
        cursor: 'pointer',
    },
    loader: {
        width: '50%',
        margin: 'auto',
    }
}));


export const ListView = ({resource, basePath, fields, filterFields, describe, linkFields=[], labelField='', title='', filterDefaultValues = {}, relmod='', relfield='', hiddenFilterField='', relfieldval='', otherProps } : {resource: string, basePath: string, fields: any[], filterFields: any[], linkFields: any[], labelField: string, describe: any, title: any, filterDefaultValues: any, relmod: string, relfield: string, hiddenFilterField?: string, relfieldval: string, otherProps: any }) => {

    const classes = useStyles();
    const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
	const [ clickedItem, setClickedItem ] = useState<any>({});
    const [ modPermissions, setModPermissions ] = useState<any>(null);
    const [ isGridView, setIsGridView ] = useState<boolean>(resource === 'Products' ? true : false);
    const [toggleButton, setToggleButton ] = useState<any>(resource === 'Products' ? <ListIcon /> : <GridOnIcon />);
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    //For RA List Grid display
    let caption: any = {};
    let imageField: any = {}; 
    let subtitle1: any = {}; 
    let subtitle2: any = {}; 

    if(resource === 'Products'){
        const productFields = describe[resource]?.fields ?? [];
        productFields.forEach((field: any) => {
            if(field.name === 'productname'){
                caption = field;
            }
            if(field.name === 'imagename'){
                imageField = field;
            }
            if(field.name === 'serial_no'){
                subtitle1 = field;
            }
            if(field.name === 'productsheet'){
                subtitle2 = field;
            }
        });
    }


    useEffect(() => {
        authProvider.getModulePermission(resource).then((result: any) => {
            setModPermissions(result);
        });
    }, [resource])
    
    const toggleView = () => {
        if(!isGridView){
            setToggleButton(<ListIcon />);
        } else {
            setToggleButton(<GridOnIcon />);
        }
        setIsGridView(!isGridView);   
    }

    const BulkActionButtons = (props: any) => (
		<BulkDeleteButton {...props} />
	);

    const MasterListAction = (props: any) => {
		let params = '';
        const { setRelatedSearchshow, relatedSearchshow } = otherProps;
		if(filterDefaultValues){
			params = `relatedModule=${relmod}&relatedField=${ filterDefaultValues && relfieldval ? relfieldval : 'relations'}&relatedFieldValue=${relfieldval}`
		}
		return (
			<Box display="flex">
				<ListActionToolbar {...props} params={params} setRelatedSearchshow={setRelatedSearchshow} relatedSearchshow={relatedSearchshow} />
			</Box>
		)
	}

    const handleDocPreviewModalOpen = (record: any = null, _moduleName: string, field: any=null) => {
        if(record){
			record['title'] = field?.label ?? '';
			record['_downloadurl'] = record[field?.name] ?? '';
			if(!record.filetype){
				const urlStr: string[] = record[field?.name] ? record[field.name].split('.') : [];
				record['filetype'] = urlStr[urlStr.length-1] ?? '';
			}
		}
		setClickedItem(record);
        setDocPreviewOpen(true);
    };


    return (
        <Box className={classes.root}>
            <DocumentPreviewModal record={clickedItem} title={clickedItem?.notes_title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
            <List
                {...otherProps}
                resource={resource}
                title={ title ? title : <></>}
                perPage={10}
                bulkActionButtons={<BulkActionButtons resource={resource} />}
                filterDefaultValues={filterDefaultValues}
                filters={<CBListFilter fields={filterFields} resource={resource} describe={describe} disabledfield={hiddenFilterField ? hiddenFilterField : relfield} disabledfieldStyle={{display: 'none'}} />}
                actions={<MasterListAction resource={resource} modPermission={modPermissions} toggleView={resource === 'Products' ? toggleView : null } toggleButton={toggleButton} />}
                component="div"
                hasList={modPermissions?.read}
                hasCreate={modPermissions?.create}
                hasEdit={modPermissions?.update}
                hasShow={modPermissions?.read}
            >
                {isGridView
                    ? <RaGridContent  caption={caption} imageField={imageField} subtitle1={subtitle1} subtitle2={subtitle2} describe={describe} resource={resource} />
                    : <>
                         {isSmall ? (
                            <ResponsiveList resource={resource} fields={filterFields} linkFields={linkFields} linkField={labelField} describe={describe} modPermission={modPermissions} />
                         ) : (
                            <Datagrid>
                                {
                                    fields.map((field: any, index: number) => {
                                        let handleFieldClick: any = null;
                                        if(field.uitype === '28' || field.uitype === '69'){
                                            handleFieldClick = handleDocPreviewModalOpen;
                                        }

                                        return linkFields.includes(field.name) 
                                            ?   <FunctionField key={index} label={field.label} render={(record: any) => {
                                                    return (
                                                        <CustomFieldLink {...otherProps} linkField={field.name} key={index} record={record} basePath={basePath} resource={resource} /> 
                                                )}
                                                }/>
                                            : cbUtils.field2DisplayElement(field, resource, describe, '', '', handleFieldClick);
                                    })
                                }
                                <RowAction {...otherProps} modPermission={modPermissions} basePath={basePath} resource={resource} />
                            </Datagrid>
                        )}
                    </>
                }
            </List>
        </Box>
    )
}

const RaGridContent = ({caption, imageField, subtitle1, subtitle2, resource, describe} : {caption: any, imageField: any, subtitle1: any, subtitle2: any, resource: string, describe: any}) => {

    const classes = useStyles();
    const { data, isLoading } = useListContext<any>();
    const redirect = useRedirect();

    return (
        <Grid container spacing={2} className={classes.raGridContent}>
            {isLoading && 
                <Box className={classes.loader} > <Loading loadingPrimary={""} loadingSecondary={""} /> </Box>
            }
            {data?.map((record: any, index: number) => {

                return (
                    <Grid key={index} item md={3} sm={4} xs={12} className={classes.content}>
                        <Card elevation={4} className={classes.gridCard} onClick={() => redirect(`/${resource}/${record?.id}/show`)} >
                            <CardContent>
                                {imageField && record &&
                                <Box mx='auto' mb={4} className='RAimageHolderBox'> 
                                     {record.imageField && record.imageField.name
                                         ? <> {cbUtils.field2DisplayRecord(imageField, record, resource, describe)} </>
                                         : <> <img src='NoImage.jpg' alt={imageField.label} /> </>
                                     }
                                 </Box>
                                }
                            </CardContent>
                            <Divider />
                            <CardActions className={classes.cardAction}>
                                <Grid container spacing={4}>
                                    <Grid item md={6} xs={6} >
                                        <Box> {cbUtils.field2DisplayRecord(subtitle1, record, resource, describe)} </Box>
                                    </Grid>
                                    <Grid item md={6} xs={6} >
                                        <Box className={clsx(classes.cardAcionRightBox)}> {cbUtils.field2DisplayRecord(subtitle2, record, resource, describe)} </Box>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
    )
}

import React, { cloneElement } from "react";
import { filterRelatedMaps } from "../utils/Helpers";
import { FIELD_DEPENDENCY_ACTIONS } from "../constant";
import { doQuery } from "../utils/lib";
import { useFormContext } from 'react-hook-form';
import { execQuery } from "../corebosServerProviderTest";
export const ParentDependencyField = ({
    field,
    resource,
    describe,
    assignedUserList,
    formValues,
    dependencyMaps,
    source,
    formattedDepenedncyMap, // *map that contains {dependant field name, parent field name, and the index of the map in @dependencyMaps}
    input
  }: {
    field: any;
    resource: string;
    describe: any;
    assignedUserList?: any;
    formValues: any;
    dependencyMaps: any[];
    source :string;
    formattedDepenedncyMap :any;
    input: any;
  }) => {
    const { setValue } = useFormContext(); 
    const changeFormValuesFromQuery = (queryResult: any, relatedRecordFields :string, destinationFields :string[], referenceModule :string ) => {
       // * this array will contain maps with @{sourceFieldName, destinationFieldName, and the new value}
       const formattedActionMaps :any[] = relatedRecordFields.split(',').map((relatedRecordFieldName :string, index :number)=>{
        if(relatedRecordFieldName.split('.')[0]===referenceModule){ //* string is of this format: 'Module.field'
          return {sourcefieldname: relatedRecordFieldName, destinationFieldName: destinationFields[index],newValue: queryResult[relatedRecordFieldName.toLowerCase().replace('.','')]};
        }
        return {sourcefieldname: relatedRecordFieldName, destinationFieldName: destinationFields[index],newValue: queryResult[relatedRecordFieldName]};
      });

      //*change the destination field values
      formattedActionMaps.forEach((formattedActionMap :any) => {
        setValue(formattedActionMap.destinationFieldName, formattedActionMap.newValue);
      });
    }

      const render = () => {
        let mapsToConsider = filterRelatedMaps(dependencyMaps, formattedDepenedncyMap.map((map :any) => map?.index)).map((valueMap: any) => {
            return { ...valueMap, conditions: valueMap?.conditions ? JSON.parse(valueMap?.conditions) :  valueMap?.conditions};
          });
          let inputProps = {...input?.props};
          mapsToConsider.forEach((map :any) => {
              Object.keys(map?.actions).forEach((action: any) => {
                const actionArray = map?.actions[action]; // * get the array of actions which hold the dependent field names
               
                 actionArray.forEach((actionMap :any) =>{
                  if(!map?.conditions){
                    if(action === 'function'){
                      if(actionMap?.value === FIELD_DEPENDENCY_ACTIONS.fieldDep_GetFields){
                        const referenceModule :string= field.type.refersTo[0];
                        const actionMapParams :string[] = actionMap?.params;
                        const relatedRecordFields :string =actionMapParams[0];
                        const destinationFields :string[] =actionMapParams[1].split(',');

                        inputProps = {...inputProps, onChange : (val: string) => {
                          let query = `Select ${relatedRecordFields} from ${referenceModule} where id = '${val}'`;
                          doQuery(query).then((res: any) => {
                            let queryResult :any = res[0];

                            changeFormValuesFromQuery(queryResult, relatedRecordFields, destinationFields, referenceModule);
                          
                          });
                        }};

                      }else if(actionMap?.value === FIELD_DEPENDENCY_ACTIONS.fieldDep_GetFieldSearch){
                       const {params} = actionMap;
                       const referenceModule = params[0];
                       const relatedRecordFields = params[1];
                       const fieldname = params[2];
                       const value = params[3];
                       const operation = params[4];
                       const destinationFields = params[5].split(',');
                       const filter = [{
                         field : fieldname, value, operation
                       }]
                       
                        inputProps = {...inputProps, onChange : (val: any) => {
                          let inputVal = field?.uitype === '10' ? val : val?.target.value
                          if(inputVal){
                            filter[0].value = inputVal;

                          execQuery(referenceModule, {filter : filter},null, relatedRecordFields).then((res: any) => {
                            let queryResult :any = res.data[0];

                            changeFormValuesFromQuery(queryResult, relatedRecordFields, destinationFields, referenceModule);

                          })
                          }
                        }}
                       
                      }
                    }

                    
                  }else{
                      const conditions : any[] = map?.conditions;
                      conditions.forEach((condition: any) => {
                          const {value} = condition;
                          if(action === FIELD_DEPENDENCY_ACTIONS.CHANGE){// *change input action
                              inputProps = {...inputProps, onChange : (e: any) => {
                                  if(typeof (window as any).notChangeInputs === 'undefined') return; // *return if the array does not exist
                                  if(e?.target?.value === value){ // * if the change action must be triggered , remove the dependant field name from notChangeInputs array
                                    const fieldIndexInNotChangeInputs = (window as any).notChangeInputs?.indexOf(actionMap?.field);
                                    if(fieldIndexInNotChangeInputs >=  0) (window as any).notChangeInputs?.splice(fieldIndexInNotChangeInputs, 1);
                                  }
                              }};
                          }else if(action === FIELD_DEPENDENCY_ACTIONS.HIDE){ //* hide input action
                          }else if(action === FIELD_DEPENDENCY_ACTIONS.SET_OPTIONS){ //* set options action
                          }else if(action === FIELD_DEPENDENCY_ACTIONS.DELETE_OPTIONS){ //*delete options action
                          }
                      })
                  }
                 });
          }); //* loop through all filtered @dependencyMaps actions
        }); //* loop through all filtered @dependencyMaps
        let inputToRender :any = cloneElement(input, inputProps);
        return inputToRender;
      }
    return (
        <>{render()}</>
    )
  }
import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    useDelete,
    useNotify,
    Notification,
    TextField,
    ReferenceField,
    DateField,
} from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import ReplyIcon from '@mui/icons-material/Reply';
import CircularProgress from '@mui/material/CircularProgress';
import TrashIcon from '@mui/icons-material/Delete';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { COMMENT_REPLY_LIMIT, REPLY_TEXT_LENGTH } from '../constant';
import { useNavigate, useLocation } from "react-router-dom";


import {
    Box,
    IconButton,
} from '@mui/material';
import { useCommentStyle } from './useCommentStyle';
import { CommentContent } from './CommentContent';
import CommentForm from "./CommentForm";
import { removeTags } from '../utils';
import { doQuery } from '../lib';


export const CommentItem = ({ comment, projectTask, setReloadTriggerID, resource, handleDocPreviewModalOpen }:{ comment:any, projectTask: any, setReloadTriggerID: any, resource: string, handleDocPreviewModalOpen: any }) => {

    const [isEditingMode, setEditingMode] = useState(false);
    const [isReplyingMode, setIsReplyingMode] = useState(false);

    const notify = useNotify();
    const classes = useCommentStyle();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [clickedCommentId, setClickedCommentId] = useState('');
    const [commentReplies, setCommentReplies] = useState<any[]>([]);
    const [isLoadingReplies, setIsLoadingReplies] = useState<boolean>(false);
    const [replyingComment, setReplyingComment] = useState<any>({});
    const [editingComment, setEditingComment] = useState<any>({});
    const [deleteOne] = useDelete();
    const navigate = useNavigate();
    let location = useLocation();
    const url = new URLSearchParams(location.search);


    useEffect(() => {
        setIsLoadingReplies(true);
        doQuery(`SELECT * FROM ${resource} WHERE parent_comments = ${comment.id} ORDER BY createdtime DESC LIMIT ${0}, ${COMMENT_REPLY_LIMIT}`).then((result) => {
            setCommentReplies(result);
            setIsLoadingReplies(false);
        }).catch(() => {
            setIsLoadingReplies(false);
        })
    }, [comment.id, resource])

    useEffect(() => {
        if(clickedCommentId){
            const clickedCommentEl:any = document.getElementById(clickedCommentId);
            clickedCommentEl.scrollIntoView({ behavior: 'smooth', block: 'start'});
        }else{
            const aUrl = new URLSearchParams(location.search);
            const commentId: any = aUrl.get('commentId');
            if(commentId){
                navigate(`${location.pathname}?commentId=${commentId}`)
                setTimeout(() => {
                    const clickedCommentEl:any = document.getElementById(commentId);
                    if(clickedCommentEl){
                        clickedCommentEl.scrollIntoView({ behavior: 'smooth', block: 'start'});
                    }
                }, 3000);
            }
        }
    }, [clickedCommentId, navigate, location.pathname, location.search])

    const deleteComment = (_comment: any) => {
        setIsLoading(true);
        deleteOne(resource, { id: _comment.id, previousData: _comment }, {
            onSuccess: (result: any) => {
                setReloadTriggerID(new Date());
                setIsLoading(false);
            },
            onError: (error: any) => {
                setIsLoading(false);
                notify(error, {type: 'error'});
            },
        });
    }

    const handleEnterEditMode = (aComment: any) => {
        setEditingComment(aComment);
        setEditingMode(true);
    };

    const handleEnterReplyingMode = (commentReplied: any) => {
        setReplyingComment(commentReplied);
        setIsReplyingMode(true);
    };

    const handleCancelReply = () => {
        setIsReplyingMode(false);
        setReplyingComment('');
    };

    const handleCancelEdit = () => {
        setEditingComment(null);
        setEditingMode(false);
    };

    const CommentFooter = ({parentCommentID, aComment}:{parentCommentID:string, aComment: any}) => {
        return (
            <Box display="flex">
                <Box mr={1}>
                    <DateField
                        source="createdtime"
                        record={comment}
                        variant="body2"
                        showTime
                        locales="en"
                        options={{
                            dateStyle: 'full',
                            timeStyle: 'short',
                        }}
                    />
                </Box>
                {parentCommentID &&
                     <Box mr={1} mt={-0.5}>
                        <IconButton size="small" title="Reply" onClick={() => handleEnterReplyingMode(aComment)} >
                            { isLoadingReplies ? <CircularProgress color="primary" size={20} /> : <ReplyIcon />}
                        </IconButton>
                    </Box>
                }
                <Box mr={1} mt={-0.5}><IconButton size="small" title="Edit" onClick={() => handleEnterEditMode(aComment)} ><EditIcon /></IconButton></Box>
                <Box mr={1} mt={-0.5}><IconButton size="small" title="Delete" onClick={() => deleteComment(aComment)} > { isLoading ?  <CircularProgress color="primary" size={20} /> : <TrashIcon /> } </IconButton></Box>
            </Box>
        )
    }

    return (
        <Box py={1} mt={-2}>
            
            {isEditingMode ? (  
                <CommentForm  isNewRecord={false} comment={editingComment} handleCancel={handleCancelEdit} replyingComment={replyingComment} projectTask={projectTask} setReloadTriggerID={setReloadTriggerID} resource={resource} />
            ) : (
                <Box mt={1}>
                     {!comment.parent_comments &&
                        <Box id={comment.id}>
                            <Box mt={0.5} className={ url.get('commentId') === comment.id ? classes.sharedComment : ''}>
                                <CommentContent handleDocPreviewModalOpen={handleDocPreviewModalOpen} aComment={comment} setClickedCommentId={setClickedCommentId} resource={resource}  />
                                <Box display={'flex'} mt={-2}>
                                    <Box mr={1}>
                                        <ReferenceField
                                        source={'creator'}
                                        record={comment}
                                        reference="Contacts"
                                        resource={resource}
                                        link="show"
                                        >
                                            <TextField
                                                source={'user_name'}
                                                component="div"
                                            />
                                        </ReferenceField> 
                                    </Box>
                                    <Box> <CommentFooter parentCommentID={comment.id} aComment={comment} /> </Box>
                                </Box>
                            </Box>
                             
                             <>
                                {isReplyingMode &&
                                    <Box ml={4} mt={2}>
                                        <em><FormatQuoteIcon /><small>{ removeTags(replyingComment?.commentcontent)?.slice(0, REPLY_TEXT_LENGTH) } </small> {'...' } <FormatQuoteIcon /></em>
                                        <CommentForm isNewRecord={true} comment={null} handleCancel={handleCancelReply} replyingComment={replyingComment} projectTask={projectTask} setReloadTriggerID={setReloadTriggerID} resource={resource} />
                                    </Box>
                                }
                    
                                {
                                    commentReplies.map((reply: any) => {
                                        return (
                                            <Box ml={4} my={2}>
                                                <Box mt={0.5} >
                                                    <CommentContent handleDocPreviewModalOpen={handleDocPreviewModalOpen} aComment={reply} setClickedCommentId={setClickedCommentId} resource={resource}  />
                                                    <Box display={'flex'} mt={-2}>
                                                        <Box mr={1}>
                                                            <ReferenceField
                                                            source={'creator'}
                                                            record={reply}
                                                            reference="Contacts"
                                                            resource={resource}
                                                            link="show"
                                                            >
                                                                <TextField
                                                                    source={'user_name'}
                                                                    component="div"
                                                                />
                                                            </ReferenceField> 
                                                        </Box>
                                                        <Box> <CommentFooter parentCommentID={''} aComment={reply} /> </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                            </>
                        </Box>
                    }

                </Box>
            )}
            <Notification />
        </Box>
    )

}

import { config } from './config';


export const APP_NAME = config.appName;
export const APP_DESCRIPTION = config.appDescription;
export const APP_META_TAGS = config.metaTags;
export const  COREBOS_URL = config.server?.url;
export const USER_AVATAR = config.userAvatar;
export const IMAGE_FULL_PATH = 'fullpath';
export const IMAGEINFO_FULL_PATH = 'imageinfo.fullpath';
export const BUSINESS_PROCESS_FLOW = config.processFlowModules;
export const COMPANY_LOGO_URL = config.logoUrl;

export const THEME_CONFIG = {
    fontFamily: 'Lato',
    lightTheme: {
        primary: {
            main: '#872d41'
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        grey: {
            main: '#f5f5f5'
        }
    },
    darkTheme: {
        primary: {
            main: '#ffffff',
        },
        secondary: {
            main: '#FBBA72',
        },
        grey: {
            main: '#f5f5f5'
        }
    },
    drawerWidth: 240,
    drawerCloseWidth: 55,
    sideBarBackground: '#f5f5f5',
}

export enum FIELD_DEPENDENCY_ACTIONS {
    CHANGE = 'change',
    HIDE = 'hide',
    SET_OPTIONS = 'setoptions',
    DELETE_OPTIONS = 'deloptions',
    READONLY = 'readonly',
    fieldDep_GetFields = 'fieldDep_GetField',
    fieldDep_GetFieldSearch = 'fieldDep_GetFieldSearch',
}

import { useEffect, useState } from 'react';
import { useLocaleState  } from 'react-admin';
import { getDataFromLocalDb, loginAction, prepareMenu } from '../utils/Helpers';
import { TABLE_DESCRIBE } from '../local-db';
import { getMenu } from '../utils/lib';
import AppDrawer from './AppDrawer';



const _window: any = window;
if (_window.coreBOS === undefined) {
    _window.coreBOS = {};
}

const Layout = (props: any) => {

    const [locale, setLocale] = useLocaleState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingMenu, setIsLoadingMenu] = useState<boolean>(false);
    const [menuItemsList, setMenuItemsList] = useState<any[]>([]);


    useEffect(() => {
        const lang = localStorage.getItem('locale') ?? 'en'; // Set default locale to english
        setLocale(lang);
    }, [setLocale, locale]);


    useEffect(() => {
        setIsLoading(true);
        getDataFromLocalDb(TABLE_DESCRIBE.tableName).then(async (describeData: any) => {
            setIsLoadingMenu(true);
            let menuRes: any = await getMenu();
            setIsLoadingMenu(false);
            menuRes = menuRes ?? {};
            const preparedUserMenu: any = prepareMenu(menuRes);
            setMenuItemsList(preparedUserMenu);
            if(!describeData){
                const coreBOSDescribe = [];
                for (const key in menuRes) {
                    if(menuRes.hasOwnProperty(key) && menuRes[key]['2']) {
                        if(menuRes[key]['1'] === 'module' && menuRes[key]['2'] && menuRes[key]['2'] !== 'Reports'){
                            coreBOSDescribe.push(menuRes[key]['2']) 
                        }
                    }
                } 
                return loginAction(coreBOSDescribe).then((describe: any) => {
                    _window.coreBOS.Describe = describe;
                    window.location.reload();
                })
            } else{
                _window.coreBOS.Describe = describeData;
                return Promise.resolve();
            }
        }).catch((error) => {
            setIsLoadingMenu(false);
            console.log(error);
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);


    return (
        <AppDrawer menuItemsList={menuItemsList} isLoadingMenu={isLoadingMenu}  {...props} isLoading={isLoading}>
            {props.children}
       </AppDrawer>
    );
};

export default Layout;

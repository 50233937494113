import { Reducer } from 'redux';
import { CHANGE_THEME, changeTheme, CHANGE_MENU_POSITION, changeMenuPostion, CHANGE_APP_MENU, changeAppMenu } from './actions';
import { ThemeName, MenuPosition } from '../types';

type ThemeState = ThemeName;
type MenuPositionState = MenuPosition;


type ThemeAction =
    | ReturnType<typeof changeTheme>
    | { type: 'OTHER_ACTION'; payload?: any };

type  MenuPositionAction =
    | ReturnType<typeof changeMenuPostion>
    | { type: 'OTHER_ACTION'; payload?: any };

type  AppMenuAction =
    | ReturnType<typeof changeAppMenu>
    | { type: 'OTHER_ACTION'; payload?: any };


const userTheme: any = localStorage.getItem('theme');
const appThemeReducer: Reducer<ThemeState, ThemeAction> = (
    previousState = userTheme ?? 'light',
    action
) => {
    if (action.type === CHANGE_THEME) {
        return action.payload;
    }
    return previousState;
};
export const themeReducer = appThemeReducer;


const userMenuPosition: any = localStorage.getItem('menuposition');
const userMenuPositionReducer: Reducer<MenuPositionState, MenuPositionAction> = (
    previousState = userMenuPosition ?? 'left',
    action
) => {
    if (action.type === CHANGE_MENU_POSITION) {
        return action.payload;
    }
    return previousState;
};
export const menuPositionReducer = userMenuPositionReducer;


const userMenuReducer: Reducer<any, AppMenuAction> = (
    previousState = [],
    action
) => {
    if (action.type === CHANGE_APP_MENU) {
        return action.payload;
    }
    return previousState;
};
export const appMenuReducer = userMenuReducer;
import React from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import * as cbconn from 'corebos-ws-lib/WSClientm';
import {
	Card,
	CardContent,
	Divider,
	Typography,
	Box,
	Paper,
} from '@mui/material';
import Scatterchart from './charts/CbScatterchart';
import Table from './Table';
import Piechart from './charts/CbPieChart';
import Numberchart from './charts/CbNumberchart';
import Rowchart from './charts/CbRowchart';


const ReactGridLayout = WidthProvider(RGL);

export default class DynamicComponent extends React.PureComponent {
  static defaultProps = {
    className: "layout",
	cols: 12,
    onLayoutChange: async (newLayout, _settingKey) => {
		const layoutdata = newLayout?.map((lay) => {
			return {
					i: lay?.i,x: lay?.x, y: lay?.y, w: lay?.w, h: lay?.h
			}
		})
		await cbconn.doInvoke('setSetting', {key: _settingKey, value: JSON.stringify(layoutdata)}, 'POST')
	}, 
  };
  constructor(props) {
    super(props);
    const layout = this.props?.layout;
	const questionsdata = this.props?.questionsdata;
	const settingKey = this.props?.settingKey;
    this.state = { layout, questionsdata, settingKey };
	
  }
  getChart(question, index){
	if(question.type === 'Grid'){
		const columns = JSON.parse(question.properties) ?? [];

		return (
			question.answer.map((record) => {
				return (
					<Card style={{ marginBottom: '15px', border: '1px solid #eee' }}>
							<CardContent>
								{
									columns.map((field, index) => {
										return (
											<Box key={index} style={{display: 'flex'}}>
												<Typography variant="subtitle2"> {field.header} </Typography>
												<span style={{margin: 'auto 5px auto 2px'}}>{':'}</span>
												<Typography variant="subtitle2"> {record[field.name]} </Typography>
											</Box>
										)
									})
								}
							</CardContent>
					</Card>
				)
			})
		); 
	}
	return (
		<>
		{question.type === 'RowChart' && 
			<Rowchart questions={question} chartElementId={`chart${index}`} key={`chart${index}`} />
		}
		{question.type === 'Scatter' && 
			<Scatterchart questions={question} chartElementId={`chart${index}`} key={`chart${index}`}  />
		}
		{question.type === 'Pie' && 
			<Piechart questions={question} chartElementId={`chart${index}`} key={`chart${index}`} />
		}
		{question.type === 'Number' && 
			<Numberchart questions={question} chartElementId={`chart${index}`} key={`chart${index}`}  />
		}
		{question.type === 'Table' && 
			<Table questions={question} chartElementId={`chart${index}`} key={`chart${index}`}  />
		}
		</>
	);
  }
  generateDOM() {
    return this.state?.questionsdata?.map((question, index) => {
		return (
			<Card data-grid={this.state.layout[index]} elevation={4} key={index} style={{marginBottom: 50, border: '1px solid #ddd', overflowY: 'auto'}} className='dynamic-component'>
				<Box p={3}>
					<Typography variant="subtitle1"> {question?.title} </Typography>
				</Box>
				<Divider />
				<CardContent>
					<Paper  elevation={0} style={{alignItems: 'center',  flexDirection: 'column', display: 'flex', border: 'none'}}>
						<Box style={{width: '100%',alignItems: 'center',  flexDirection: 'column', display: 'flex', border: 'none'}}> {this.getChart(question, index)} </Box>
					</Paper>
				</CardContent>
			</Card>
		);
	})
  }
  onLayoutChange(alayout) {
    this.props.onLayoutChange(alayout, this.state.settingKey);
  }
  render() {
    return (
		<ReactGridLayout
			{...this.props}
			layout={this.state.layout}
			onLayoutChange={(newLayout) => this.onLayoutChange(newLayout)}
		>
			{this.generateDOM()}
		</ReactGridLayout>
	);
  }
}

import React, { PureComponent } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


export default class CbScatterchart extends PureComponent {

  render() {
      const {questions} = this.props;
      const data = Array.isArray(questions?.answer) ? questions?.answer?.map((ans) => {
          return {
              x: Object.values(ans)[0],
              y: parseInt(Object.values(ans)[1])
          }
      }) : [];
      console.log(data);
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300, width: '100%'}}>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          width={'100%'}
          height={400}
          margin={{
            top: 0,
            right: 50,
            bottom: 150,
            left: 50,
          }}
        >
          <CartesianGrid />
          <XAxis sclaeToFit='true' dataKey="x" name="service_contract_tc" angle={-45} textAnchor="end" minTickGap={-200} />
          <YAxis type="number" dataKey="y" name="sum" />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter name="A school" data={data} fill="#8884d8" />
        </ScatterChart>
      </ResponsiveContainer>
      </div>
    );
  }
}
import Box from "@mui/material/Box/Box";
import { Button } from "react-admin";
import { useNavigate, useLocation } from "react-router-dom";
import { COMMENT_SHOW_LENGTH } from "../constant";
import { useEffect, useState } from "react";
import { MarkDownPreview } from "../../component/MarkDown";


export const CommentContent = ({aComment, setClickedCommentId, resource, handleDocPreviewModalOpen} : { aComment: any, setClickedCommentId: any, resource: string, handleDocPreviewModalOpen: any }) => {

    const navigate = useNavigate();
    let location = useLocation();
    const [ comment, setComment ] = useState<any>(aComment);
    const [ commentContent, setCommentContent ] = useState<string>(aComment.commentcontent);
    const [ showMore, setShowMore ] = useState<boolean>(false);



    useEffect(() => {
        if(showMore){
            const comnt = JSON.parse(JSON.stringify(aComment));
            comnt.commentcontent = commentContent;
            setComment(comnt);
        }else{
            if(commentContent.length > COMMENT_SHOW_LENGTH){
                const comnt = JSON.parse(JSON.stringify(aComment));
                comnt.commentcontent = `<span>${commentContent.slice(0, COMMENT_SHOW_LENGTH)}</span> <span id=${aComment.id+'dots'} >...</span>`;
                setComment(comnt);
            }
        }
    }, [showMore, commentContent, aComment]);
    
    const handleReadMore = (readMore: boolean) => {

        if(readMore){
            setShowMore(false);
            setCommentContent(aComment.commentcontent);
        }else{
            setShowMore(true);
        }

    }

    

    const handleCommentClick = (commentId: string) => {
        setClickedCommentId(commentId);
        navigate(`${location.pathname}?commentId=${commentId}`)
    }

    return (
        <>
        {comment && 
            <Box py={3} px={2} mx={-2} style={{cursor: 'pointer', borderRadius: '14px'}}>
                <Box onClick={() => handleCommentClick(comment.id)}>
                    <MarkDownPreview record={comment} source='commentcontent' />
                </Box>
                {commentContent.length > COMMENT_SHOW_LENGTH &&
                    <Button label={ showMore ? 'Read less' : 'Read more'} color='primary' size='small' style={{textTransform: 'none', margin: '-15px auto auto -10px'}} id={comment.id+'readMoreBtn'} onClick={ () => handleReadMore(showMore) }/>
                }
            </Box>
        }
        </>
    )
}
import React, { useState } from 'react';
import { useListContext } from 'react-admin';
import { Card, CardContent, CardHeader, Box, Typography, Divider } from '@mui/material';
import cbUtils from './corebosUtils/corebosUtils';
import CustomFieldLink from './CustomFieldLink';
import RowAction from './RowAction';
import DocumentPreviewModal from './DocumentPreview/DocumentPreviewModal';



const ResponsiveList = (props: {describe: any, resource: string, fields: any[], linkFields: any, linkField: string, modPermission: any}) => {

    const { data } = useListContext();
    const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
	const [ clickedItem, setClickedItem ] = useState<any>({});



    const handleDocPreviewModalOpen = (record: any = null, _moduleName: string, field:any =null) => {
        if(record){
			record['title'] = field?.label ?? '';
			record['_downloadurl'] = record[field?.name] ?? '';
			if(!record.filetype){
				const urlStr: string[] = record[field?.name] ? record[field.name].split('.') : [];
				record['filetype'] = urlStr[urlStr.length-1] ?? '';
			}
		}
		setClickedItem(record);
        setDocPreviewOpen(true);
    };

    return (
        <Box p={3}>
            <DocumentPreviewModal record={clickedItem} title={clickedItem?.title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
            {data.map(record =>
                <Card key={record.id} style={{ padding: '10px', margin: '15px auto', border: '1px solid #ddd' }}>
                    <CardHeader 
                        title={<CustomFieldLink resource={props.resource} record={record} linkField={props.linkField} />} 
                        action={<RowAction basePath={`/${props.resource}`} record={record} resource={props.resource} modPermission={props.modPermission} />}
                    />
                    <Divider />
                    <CardContent>
                        { props.fields.map((field: any, index: number) => {
                            let handleFieldClick: any = null;
                            if(field.uitype === '28' || field.uitype === '69'){
                                handleFieldClick = handleDocPreviewModalOpen;
                            }
                            return (
                                <Box my={1} key={index}>
                                    <Typography variant="subtitle2"> {field.label} </Typography>
                                    { props.linkFields.includes(field.name) 
                                        ? <CustomFieldLink linkField={field.name} resource={props.resource} record={record} /> 
                                        : cbUtils.field2DisplayRecord(field, record, props.resource, props.describe, '', '', handleFieldClick)}
                                </Box>
                            )
                        })}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default ResponsiveList;
import * as React from 'react';
import { useState } from 'react';
import { useCommentStyle } from './useCommentStyle';
import { handleSpecialChars, uploadFile } from '../../utils/Helpers';
import {
    useNotify,
    Notification,
    useCreate,
    useUpdate,
    useGetIdentity,
    useTranslate,
} from 'react-admin';
import {
    Box,
    Button as CommentButton,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { 
    doInvoke, 
} from '../lib'
import "dropzone/dist/dropzone.css";
import "react-dropzone-component/styles/filepicker.css";
//import { treatSpecialChars } from '../utils';

import ReactMde, { SaveImageHandler } from "react-mde";
import ReactMarkdown from "react-markdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import rehypeRaw from 'rehype-raw';



export const CommentForm = ({ projectTask, resource, setReloadTriggerID, handleCancel=null, replyingComment=null, comment, isNewRecord=false }:{ projectTask: any, resource: string, setReloadTriggerID: any, handleCancel: any, replyingComment: any, comment: any, isNewRecord: boolean }) => {

    const classes = useCommentStyle();
    const notify = useNotify();
    const translate = useTranslate();
    const [create] = useCreate();
    const [update] = useUpdate();
    const [isloading, setIsLoading] = useState(false);
    const [uploadedFileList, setUploadedFileList] = useState<any[]>([])
    const [value, setValue] = useState<any>(comment && comment.commentcontent);
    const [selectedTab, setSelectedTab] = useState<any>("write");
    const { identity } = useGetIdentity();



    const handleSave = (fileList: any[]) => {
         setIsLoading(true);
         if(isNewRecord){
            const values: any = {};
            values.assigned_user_id = identity && identity.id;
            values.related_to = projectTask?.id;
            values.parent_comments = replyingComment?.id??'';
            values.commentcontent = handleSpecialChars(value);;
            create(resource, values, {
                onSuccess: async (result: any) => {
                    const fileIds: any[] = [];
                    fileList.forEach((uploadedDocument: any) => {
                        fileIds.push(uploadedDocument.id);
                    });
                    doInvoke('SetRelation', { relate_this_id: result?.data.id, with_these_ids: JSON.stringify([...fileIds, projectTask?.id])}, 'POST').then(() => {
                        setUploadedFileList([]);
                        setValue('');
                        setReloadTriggerID(new Date().getTime());
                    }).catch(() => {
                        notify('Upload: Something went wrong, try again later', {type: 'error'});
                    }).finally(() => {
                        setIsLoading(false);
                    })
                },
                onError: () => {
                    setIsLoading(false);
                    notify('Something went wrong, try again later', {type: 'error'});
                },
            });
         }else{
            const dataToSend: any = comment;
            dataToSend.commentcontent = handleSpecialChars(value);;
            setIsLoading(true);
            update(resource, { id: dataToSend.id, data: dataToSend, previousData: comment },
                {
                    onSuccess: async (result: any) => {
                        const fileIds: any[] = [];
                        uploadedFileList.forEach((uploadedDocument: any) => {
                            fileIds.push(uploadedDocument.id);
                        });
                        doInvoke('SetRelation', { relate_this_id: result?.data.id, with_these_ids: JSON.stringify([...fileIds, projectTask?.id])}, 'POST').then(() => {
                            setUploadedFileList([]);
                            setValue('');
                            setReloadTriggerID(new Date().getTime());
                        }).catch((err) => {
                            notify('Upload: Something went wrong, try again later', {type: 'error'});
                        }).finally(() => {
                            handleCancel();
                            setIsLoading(false);
                        })
                    },
                    onError: () => {
                        setIsLoading(false);
                        notify('Something went wrong, try again later', {type: 'error'});
                    },
                }
            ) 
         } 
    }

    const saveThisImage: SaveImageHandler = async function*(data: ArrayBuffer, file: Blob) {
        const title = `${projectTask?.projecttask_no}`;
        setIsLoading(true);
        const result: any  = await uploadFile(file, title, '', '', '');
        if(result instanceof Error) {
            setIsLoading(false);
            yield '';
            return true;
        }
        setIsLoading(false);
        setUploadedFileList(uploadedFileList => [...uploadedFileList, result]);
        yield result?._downloadurl; 
        return true;
    };

    return (
        <Box my={4}>
            <ReactMde
                value={value}
                onChange={setValue}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown  rehypePlugins={[rehypeRaw]} children={markdown} />)
                }
                childProps={{
                writeButton: {
                    tabIndex: -1
                }
                }}
                paste={{
                    saveImage: saveThisImage,
                    //multiple: false,
                    //command: '',
                    //accept: ''
                }}
            />
            <Box style={{width: '25%', margin: '10px auto', float: 'right'}}>
                {handleCancel &&
                    <CommentButton
                        className={classes.cancel}
                        onClick={handleCancel}
                        color="primary"
                        style={{textTransform: 'none', width: '100%'}}
                    >
                        {translate('Cancel')}
                    </CommentButton>
                }
                <CommentButton
                    startIcon={ isloading ? <CircularProgress color={isloading ? 'primary' : 'secondary' } size={24} /> : <></>}
                    disabled={ !value || isloading }
                    className={classes.cancel}
                    onClick={() => handleSave(uploadedFileList)}
                    color="primary"
                    variant='contained'
                    style={{textTransform: 'none', width: '100%'}}
                >
                    {translate('ra.action.save')}
                </CommentButton>
            </Box>
            <Notification />
        </Box>
    )

}

export default CommentForm;
import React, { useEffect, useState } from 'react';
import {
    List,
    ExportButton,
    TopToolbar,
    Loading,
    Button,
    useTranslate,
    useGetIdentity,
    useRedirect,
} from 'react-admin';
import { CBListFilter } from '../component/CBListFilter';
import { TaskListContent } from './TaskListContent';
import corebosUtils from '../component/corebosUtils/corebosUtils';
import { useLocation} from "react-router";
import queryString from 'query-string';
import { makeStyles } from '@mui/styles';
import { getDataFromLocalDb } from '../utils/Helpers';
import { TABLE_DESCRIBE } from '../local-db';
import { CbListGuesser } from '../component/corebosGuessers/cbListGuesser';
import AddIcon from '@mui/icons-material/Add';
import QuickCreateModal from '../component/QuickCreateModal';
import { authProvider } from '../authProvider';

export const TaskList = (props: any) => {
    const resource:any = props?.resource;

    const [describe, setDescribe] = useState(null);
    const { identity } = useGetIdentity();
    const location = useLocation();
    const [project, setProject] = useState<any>(null);
    const [ modPermission,  setModPermission ] = useState<any>({});

    const urlQueryString: any = queryString.parse(location?.search);
    const { projectid } = urlQueryString;
	let defaultSearchquery = urlQueryString && urlQueryString.filter ? JSON.parse(urlQueryString.filter) : {};
	for (const key in urlQueryString) {
		if(typeof key === 'string' && key !== 'filter'){
			defaultSearchquery[key] = urlQueryString[key];
		}
	}
    
    const extraFields = props?.options?.extraFields;
    const [ queryParams, setQueryParam ] = useState<any>(defaultSearchquery);


    const [taskFilterFields, setTaskFilterFields] = useState([]);
    const [isLoadigProject, setIsLoadingProject] = useState(true);
    const translate = useTranslate();
    const [quickCreateOpen, setQuickCreateOpen] = useState<boolean>(false);
    const [modalData, setModalData] = useState<any>({});

    useEffect(() => {
        getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
            setDescribe(result);
            if(extraFields){
                let fields = result[resource]?.fields ?? [];
                fields = fields.map((field: any) => field.name);
                fields =  fields && fields.length > 0 ? fields.join(',') +  `, ${extraFields}` : '*';
                const newQueryParams = queryParams;
                newQueryParams[`${'assigned_user_id : (Users) id'}`] = identity && identity?.id;
                newQueryParams['extraFields'] = fields;
                setQueryParam(newQueryParams);
            }
		});
        authProvider.getModulePermission(resource).then((result: any) => {
			setModPermission(result);
		})

        if(projectid){
            corebosUtils.loadModuleFields(resource).then((modDescribe) => {
                let filterFields = modDescribe?.filterFields?.fields??[];
                filterFields = filterFields.filter((field:string) => field !== 'projectid');
                for(let index = 0; index < filterFields.length; index++) {
                    const filterField = modDescribe?.fields.filter((field:any) => field.name === filterFields[index])[0];
                    if(filterField){
                        filterFields[index] = JSON.parse(JSON.stringify(filterField));
                    }
                }
                setTaskFilterFields(filterFields);
            });

            setIsLoadingProject(true);
            corebosUtils.cbRetrieve(projectid).then((result) => {
                setProject(result);
                setIsLoadingProject(false);
            })
        }else{
            setIsLoadingProject(false);
        }
        
    }, [extraFields, identity, projectid, queryParams, resource]);


    const handleQuickCreateModal = (data: any, open: boolean) => {
        setModalData(data);
        setQuickCreateOpen(open);
    };


    return (
        <>
        <QuickCreateModal resource='Timecontrol' relatedMod={resource} defaultValues={{relatedto: modalData?.id}} title={modalData?.projecttaskname} open={quickCreateOpen} handleQuickCreateModal={handleQuickCreateModal} />
        {!isLoadigProject && !project 
            ? <CbListGuesser {...props} />
            : <>
                {taskFilterFields.length > 0 ? (
                <>
                <List
                    {...props}
                    perPage={50}
                    title={project?.projectname}
                    sort={{ field: 'id', order: 'ASC' }}
                    filters={<CBListFilter fields={taskFilterFields} disabledfield={'projectid'} describe={describe} onlyMine={{fieldName: `${'assigned_user_id : (Users) id'}`, btnLabel: translate('Assigned to me') }} {...props} />}
                    filterDefaultValues={queryParams}
                    actions={<TaskActions filterFields={taskFilterFields} describe={describe} basePath={props.basePath} resource={props.resource} projectid={project?.id} />}
                    pagination={false}
                    component="div"
                >
                    <TaskListContent isLoadigProject={isLoadigProject} modPermission={modPermission} handleQuickCreateModal={handleQuickCreateModal} resource={resource} redirectUrl={`/${resource}?filter=${JSON.stringify({ projectid: project?.id})}`} />
                </List> 
                </>
                ) : (
                    <Loading />
                )
                }
            </>
        }
        </>
    );
};

const useActionStyles = makeStyles((theme: any) => ({
    createButton: {
        marginLeft: theme.spacing(2),
    },
}));
const TaskActions = (props: any) => {
    const classes = useActionStyles();
    const translate = useTranslate();
    const redirect = useRedirect();

    return (
        <TopToolbar>
            <CBListFilter context="button" fields={props.filterFields} disabledfield={'projectid'} describe={props.describe} />
            <ExportButton />
            <Button onClick={() => redirect(`/${props.resource}/create/?projectid=${props.projectid}`) } color='primary' variant='contained' label={translate('New Task')} className={classes.createButton}>
                <AddIcon />
            </Button>
        </TopToolbar>
    );
};


import React from "react";
import { Filter, DateInput, TextInput, SelectInput, useInput, BooleanInput, ReferenceInput, AutocompleteInput, SearchInput } from 'react-admin';
import cbUtils from './corebosUtils/corebosUtils';
import { OnlyMineInput } from "./OnlyMineInput";

const FormattedBooleanInput = (props: any) => {
    const { field } = useInput(props);
    const isChecked = {checked: false};

    if (Number(field.value)) {
        isChecked.checked = true;
    }
    return (<BooleanInput {...props} options={isChecked}/>);
};

const formatSearchObject = (field: any, searchText: any) => {
    if (!searchText) {
        return;
    }
    let srch: any = {};
    srch[field] = searchText;
    return srch;
}

const isHiddenFilter = (field: any, props: any): boolean => {
    if(!props.disabledfield){
        return false;
    }
    let isHidden: boolean = false;
    if(!Array.isArray(props.disabledfield)){
        isHidden = props.disabledfield === field.name;
    } else {
        if(props.disabledfield.includes(field.name)){
            isHidden = true;
        }
    }
    return isHidden;
}

export const CBListFilter = (props: any) => {
    return (
        <Filter {...props} style={{marginBottom: '20px'}}>
            <SearchInput source={'cblistsearch_'+props.resource} alwaysOn />
            {props.onlyMine && 
                <OnlyMineInput alwaysOn fieldName={props.onlyMine.fieldName} btnLabel={props.onlyMine.btnLabel} />
            }
            { 
                props.fields.map((field: any, i: number) => {
                    if(typeof field === 'object'){
                        switch (field.uitype) {
                            case '5': //date type
                                return (props && isHiddenFilter(field, props)) ? <DateInput key={i} source={field.name} label={field.label} alwaysOn disabled parse={cbUtils.dateParser} style={props.disabledfieldStyle ?? {}} /> : <DateInput key={i} source={field.name} label={field.label} parse={cbUtils.dateParser}/>;
                            case '50': //date type
                                return (props && isHiddenFilter(field, props)) ? <DateInput key={i} source={field.name} label={field.label} alwaysOn disabled parse={cbUtils.dateParser} style={props.disabledfieldStyle ?? {}} /> : <DateInput key={i} source={field.name} label={field.label} parse={cbUtils.dateParser}/>;
                            case '10': //Module Relation
                                
                                if(props && isHiddenFilter(field, props) && props.disabledfieldStyle){
                                    return null;
                                }

                                if (field.type.refersTo.length === 0) {
                                    return <></>
                                }
                                if (!props.describe || !props.describe[field.type.refersTo[0]]) {
                                    return <></>
                                }
                                const refmod = props?.describe[field.type.refersTo[0]]??{};
                                let labelFields = refmod.labelFields;

            
                                if (labelFields && labelFields.indexOf(',') > -1) {
                                    labelFields = labelFields.split(',')[0];
                                }

                                
                                return (props && isHiddenFilter(field, props)) ? 
                                    <ReferenceInput
                                        disabled
                                        alwaysOn
                                        style={props.disabledfieldStyle ?? {}}
                                        variant="outlined"
                                        key={field.name}
                                        label={field.label}
                                        source={field.name}
                                        reference={refmod.name}
                                        filterToQuery={(searchText: string) =>
                                            formatSearchObject(labelFields, searchText)
                                        }>
                                        <AutocompleteInput
                                            variant="outlined"
                                            key={'ref' + field.name}
                                            optionText={labelFields}
                                        />
                                    </ReferenceInput> :

                                        <ReferenceInput
                                        variant="outlined"
                                        key={field.name}
                                        label={field.label}
                                        source={field.name}
                                        reference={refmod.name}
                                        filterToQuery={(searchText: string) =>
                                            formatSearchObject(labelFields, searchText)
                                        }>
                                        <AutocompleteInput
                                            variant="outlined"
                                            key={'ref' + field.name}
                                            optionText={labelFields}
                                        />
                                        </ReferenceInput> 
                                
                            /* case '50': //datetime type
                                return (props && isHiddenFilter(field, props)) ? <DateTimeInput key={i} source={field.name} label={field.label} alwaysOn disabled parse={cbUtils.dateParser} style={props.disabledfieldStyle ?? {}} /> : <DateTimeInput key={i} source={field.name} label={field.label} parse={cbUtils.dateParser} />; */
                            case '15': //Picklist
                                return (props && isHiddenFilter(field, props)) ? <TextInput key={i} source={field.name} label={field.label} alwaysOn disabled style={props.disabledfieldStyle ?? {}} /> : <SelectInput key={i} source={field.name} label={field.label} choices={field.type.picklistValues} optionText="value" optionValue="label" defaultValue={field.defaultValue}/>;
                            case '56': //Checkbox
                                return (props && isHiddenFilter(field, props)) ? <FormattedBooleanInput key={i} source={field.name} defaultValue={field.defaultValue} label={field.label} alwaysOn disabled style={props.disabledfieldStyle ?? {}} /> : <FormattedBooleanInput key={i} source={field.name} label={field.label} />;
                            default:
                                return (props && isHiddenFilter(field, props)) ? <TextInput key={i} source={field.name} label={field.label} alwaysOn disabled style={props.disabledfieldStyle ?? {}} /> : <TextInput key={i} source={field.name} label={field.label} />;
                        }
                    }else{
                        return (props && isHiddenFilter({name: field}, props)) ? <TextInput key={i} source={field} label={field} alwaysOn disabled style={props.disabledfieldStyle ?? {}}  /> : <TextInput key={i} source={field} label={field} />;
                    }
                })
            }
        </Filter>
    );
}
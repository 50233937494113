import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PFPushAlong } from './PFPushAlong';
import { BUSINESS_PROCESS_FLOW } from '../constant';
import { MarkDownInput } from './MarkDown';
import { useRecordContext, TabbedForm, FormTab, Toolbar, SaveButton, DeleteButton, useNotify, useUpdate, TabbedFormTabs, useTranslate, useRedirect, ListButton } from "react-admin";
import { SaveImageHandler } from "react-mde";
import { filterDependencyMaps, uploadFile, handleSpecialChars, reformatPostingData } from "../utils/Helpers";
import { doInvoke, remoteValidate } from '../utils/lib';
import { FormDataConsumer } from 'react-admin';
import cbUtils from './corebosUtils/corebosUtils';
import { ParentDependencyField } from './ParentDependencyField';
import {
	CircularProgress,
	Box,
	Grid,
	Typography,
} from '@mui/material';
import { DependentField } from './DependentField';
import CloseIcon from '@mui/icons-material/Close';


const EditForm = ({ blocks, describe, resource, modPermission, ...props }: { blocks: any, describe: any, resource: string, modPermission: any }) => {
	const record = useRecordContext(props);
	const [isLoading, setIsLoading] = useState(false);
	const notify = useNotify();
	const [dependentFields, setDependentFields] = useState<any[]>([]);
	const [dependentFieldsNames, setDependentFieldsNames] = useState<any[]>([]);
	const [update] = useUpdate();
	const { id } = useParams<any>();
	const [uploadedFileList, setUploadedFileList] = useState<any[]>([]);
	const [markdownInputValue, setMarkdownInputValue] = useState({});
	const translate = useTranslate();
	const redirect = useRedirect();


	const getProcessFlow = (field: any) => {
		const pf = BUSINESS_PROCESS_FLOW.find((element: any) => element.field === field);
		return pf;
	};
	const fetchMaps = useCallback(async () => {
		const module = resource + '_FieldDependencyPortal';

		let currentMap = await doInvoke('ProcessMap', { mapid: module }).catch((err: any) => { console.log(err); });

		if (currentMap && typeof currentMap !== "undefined") {
			const { allMaps, fieldNames }: { allMaps: any[], fieldNames: any[] } = filterDependencyMaps(currentMap);
			setDependentFields(allMaps);
			setDependentFieldsNames(fieldNames);
		}


	}, [resource]);


	useEffect(() => {
		fetchMaps();
	}, [fetchMaps])

	const saveRecord = useCallback(
		async (values: any, fileList: any[], currentMarkdownInputValue: any) => {
			setIsLoading(true);
			for (const key in values) {
				const value = values[key];
				if (value && value.rawFile) {
					const result = await handleUpload(value.rawFile, '');
					values[key] = result?._downloadurl ?? '';
				}
			}
			let dataTosend: any = Object.assign(values, currentMarkdownInputValue);
			dataTosend = reformatPostingData(dataTosend);
			const validationRes: any = await remoteValidate(dataTosend.id, resource, dataTosend);
			if (validationRes.isValid) {
				update(resource, { id: record.id, data: dataTosend, previousData: record }, {
					onSuccess: (result) => {
						if (fileList.length > 0) {
							const fileIds: any[] = [];
							fileList.forEach((uploadedDocument: any) => {
								fileIds.push(uploadedDocument.id);
							});
							doInvoke('SetRelation', { relate_this_id: result?.id, with_these_ids: JSON.stringify(fileIds) }, 'POST').then(() => {
								setUploadedFileList([]);
								setMarkdownInputValue('');
								redirect(`/${resource}/${result?.id}/show`);
							}).catch(() => {
								notify(`Upload: ${translate('Something went wrong')}`, { type: 'error' });
							}).finally(() => {
								setIsLoading(false);
							})
						} else {
							setUploadedFileList([]);
							setMarkdownInputValue('');
							redirect(`/${resource}/${result?.id}/show`);
						}
					},
					onError: () => {
						setIsLoading(false);
						notify(`Upload: ${translate('Something went wrong')}`, { type: 'error' });
					},
				});
			} else {
				setIsLoading(false);
				notify(validationRes?.errors[0] ?? '', { type: 'error' });
			}
		},
		[update, resource, redirect, notify, translate, record],
	);

	const saveThisImage: SaveImageHandler = async function* (data: ArrayBuffer, file: Blob) {
		const title = '';
		const result = await handleUpload(file, title);
		if (result instanceof Error) {
			yield '';
			return true;
		}
		yield result?._downloadurl;
		return true;
	};

	const handleUpload = async (file: any, title: string) => {
		setIsLoading(true);
		const result = await uploadFile(file, title, '', '', '');
		if (result instanceof Error) {
			setIsLoading(false);
			return null;
		}
		setUploadedFileList(uploadedFileList => [...uploadedFileList, result]);
		setIsLoading(false);
		return result;
	}

	const CustomToolbar = (props: any) => {
		const { record, resource } = props;
		return (
			<Toolbar {...props}>
				<ListButton label={translate('ra.action.cancel')} title={translate('ra.action.cancel')} size="small" color="primary" variant="outlined" sx={{ textTransform: 'none', py: 1, mr: 1, px: 2 }} icon={<CloseIcon />} {...props} />
				{modPermission && modPermission.update &&
					<SaveButton
						label={translate('ra.action.save')}
						icon={isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary'} size={24} /> : <></>}
						disabled={isLoading}
						alwaysEnable={!isLoading}
						size="small"
						sx={{ textTransform: 'none', mr: 1, py: 1, px: 2 }}
						{...props}
					/>
				}
				{modPermission && modPermission.delete &&
					<DeleteButton
						record={record}
						resource={resource}
						size="small"
						variant='contained'
						sx={{ textTransform: 'none', mr: 1, py: 1, px: 2 }}
					/>
				}
			</Toolbar>
		);
	}

	const handleMarkdownField = (fieldName: string, value: any, cureentMarkdownInputValue: any) => {
		cureentMarkdownInputValue[fieldName] = handleSpecialChars(value);
		setMarkdownInputValue(cureentMarkdownInputValue);
	}

	return (
		<TabbedForm onSubmit={(values: any) => saveRecord(values, uploadedFileList, markdownInputValue)} toolbar={<CustomToolbar />} tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}  >
			{
				blocks.map((block: any, bidx: number) => {
					return (
						<FormTab key={'fbrefblk' + bidx} label={block.name}>
							<Box style={{ width: '100%' }} py={3}>
								<Grid container spacing={2} >
									{
										block.fields.map((field: any, idx: number) => {
											if (field.uitype === '19') {
												return (
													<Grid key={idx} item xs={12} md={12}>
														<Box my={2}>
															<Typography variant="subtitle2"> {field.label} </Typography>
															<MarkDownInput
																record={record}
																source={field.name}
																handleMarkdownField={handleMarkdownField}
																saveThisImage={saveThisImage}
																cureentMarkdownInputValue={markdownInputValue}
															/>
														</Box>
													</Grid>
												)
											}
											const formattedDepenedncyMaps = dependentFieldsNames.filter((e: any) => e?.dependentFieldName === field?.name);
											const formattedDepenedncyMapsForParent = dependentFieldsNames.filter(e => e.parentFieldName === field?.name);
											const inputProps = { record: record };
											const cbField = cbUtils.field2InputElement(field, resource, { fullWidth: true }, describe, '', '', inputProps);
											const processFlow: any = getProcessFlow(field.name);
											return cbField ? (
												<Grid key={idx} item xs={12} md={6}>
													<FormDataConsumer>
														{({ formData }) =>
															<>
																{(processFlow) ? (
																	<>
																		<Typography variant="subtitle2"> {field.label} </Typography>
																		<PFPushAlong
																			pfid={processFlow.pfId}
																			ctxid={id}
																			isDetailView={false}
																			graphId={`pfpaoutputgraph_${field.name}_${processFlow.pfId}`}
																		/>
																	</>
																) : formattedDepenedncyMaps.length > 0 ? ( //* field is dependent field in dependency maps
																	<DependentField
																		formattedDepenedncyMap={
																			formattedDepenedncyMaps
																		}
																		describe={describe}
																		resource={resource}
																		field={field}
																		source={field.name}
																		formValues={formData}
																		dependencyMaps={dependentFields}
																	/>
																) : formattedDepenedncyMapsForParent.length > 0 ? ( // *field is parent field in dependency maps
																	<ParentDependencyField
																		formattedDepenedncyMap={
																			formattedDepenedncyMapsForParent
																		}
																		input={cbField}
																		describe={describe}
																		resource={resource}
																		field={field}
																		source={field.name}
																		formValues={formData}
																		dependencyMaps={dependentFields}
																	/>) : (
																	cbField
																)}
															</>
														}
													</FormDataConsumer>
												</Grid>
											) : (
												<></>
											);
										})
									}
								</Grid>
							</Box>
						</FormTab>
					)
				})
			}
		</TabbedForm>
	)
}

export default EditForm;
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { 
  makeStyles, 
} from '@mui/styles';
import { Cancel, Email } from "@mui/icons-material";
import { EmailDialogProps } from "../models/EmailDialogProps";
import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  Notification,
  Loading,
  useNotify,
  useTranslate,
  TextInput,
  FileInput,
  FileField,
  required,
} from "react-admin";
import { HiddenSaveButton } from "./HelperComponens/HiddenSaveButton";
import CustomAutoComplete from "./HelperComponens/CustomAutoComplete";
import { HIDDEN_SAVE_BUTTONS_CLASSNAME } from "./HelperComponens/HelpersContants";
import { clickSaveButtons } from "./HelperComponens/ComponentHelpers";
import { getDataFromLocalDb, handleSpecialChars, massCreateDocumentsChunks, uploadFile } from "../utils/Helpers";
import { TABLE_AUTH } from "../local-db";
import { useParams } from "react-router-dom";
import { doInvoke } from "../tasks/lib";
import { MarkDownInput } from "./MarkDown";

const useCreateDialogStyles: any = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
    },
    "& .MuiCard-root": {
      width: "100%",
    },
  },
  fullHeight: {
    minHeight: "90vh",
  },
  formContainer: {
    display: "block",
  },
  cancelButton: {
    color: "#fff",
    backgroundColor: "red",
    "&:hover": { color: "#fff", backgroundColor: "red" },
  },
  contentHodlingGrid: {
    width: "100%",
  },
}));

export const EmailDialog = (props: EmailDialogProps) => {
  const { open, parentResource, toggleDialog } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const translate = useTranslate();
  const params :any = useParams();
  const [markdownInputValue, setMarkdownInputValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFileList, setUploadedFileList] = useState<any[]>([]);

  const notify = useNotify();
  const mandatoryFields = ["to", "from"];

  const createDialogStyles = useCreateDialogStyles();

  const handleClose = () => {
    toggleDialog(null, false);
  };

  const emailTemplateChange =  (selectedTemplate:any) => {
    //const {template} = selectedTemplate;
    // setMarkDownRecord(template);
  }

  const fakeCreateProps: any = {
    basePath: `${parentResource}`,
    resource: parentResource,
  };

  const isValid = (val :string | undefined | null): boolean => {
    if (typeof val === 'undefined' || val === null || val === '') {
      return false;
    }
    return true;
  }

  const sendEmail = async (values :any, fileList: any[], currentMarkdownInputValue: any) => {
    setLoading(true);
    let {from,to, CC, BCC, subject, body, files} = values;
    body = currentMarkdownInputValue;
    let validationSucceded = true;
    mandatoryFields.forEach((fieldname :string) => {
      if (!isValid(values[fieldname])) {
        notify(`${fieldname} is required`, { type: 'error' });
        validationSucceded = false;
      }
    })
    if (!validationSucceded) {
      setLoading(false);
      return;
    }

    let attachments :string[] = [];
    // * check for files and base64 encode them
     if(files && files.length > 0){
      const user = await getDataFromLocalDb(TABLE_AUTH.tableName);
      const docIds :string[] = await massCreateDocumentsChunks(files, user?.logindata?.userId,4);
      attachments = [...docIds];
     }
     //debugger;
     /* eslint-disable no-useless-escape */
     const wsParams = {
       workflow: [54],
       entities: JSON.stringify([params?.id]),
       context: JSON.stringify({
        ...(isValid(to)) && {SendEmailTo:to},
        ...(isValid(from)) && { SendFromEmail: from},
        ...(isValid(CC)) && {SendEmailCC: CC},
        ...(isValid(BCC)) && {SendEmailBCC: BCC},
        ...(isValid(subject)) && {SendThisSubject: subject},
        ...(isValid(body)) && {SendThisBody:  body.replace(/([&<>\"'])/g, (match:any) => body[match])},
        ...(attachments.length) && {SendTheseAttachments: JSON.stringify(attachments)},
      }),
     }; 
     doInvoke('ExecuteWorkflowWithContext',wsParams, 'POST').then((res:any)=>{
      setLoading(false);
      notify(`Email sent successfully`, { type: 'success'});
      toggleDialog(null, false);
      setLoading(false);
     }, (reason)=>{
      setLoading(false);
      console.log(reason);
     }).catch((err:any)=>{
      console.log(err);
      setLoading(false);
     });
  }

  const handleUpload = async (file :any, title :string) => {
		setIsLoading(true);
		const result  = await uploadFile(file, title, '', '', '');
        if(result instanceof Error) {
			setIsLoading(false);
			return null;
        }
		setUploadedFileList((uploadedFileList :any[]) => { return [...uploadedFileList, result]});
		setIsLoading(false);
		return result;
	}

  const saveThisImage = async function*(data :any, file:any) {
    const title = '';
    const result  = await handleUpload(file, title);
    if(result instanceof Error) {
      yield '';
      return true;
    }
    yield result?._downloadurl; 
    return true;
  };

  const handleMarkdownField = (fieldName :string, value :string, cureentMarkdownInputValue :any) => {
		cureentMarkdownInputValue[fieldName] = handleSpecialChars(value);;
		setMarkdownInputValue(cureentMarkdownInputValue);
	}

  return (
    <>
      {
        <Dialog
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"lg"}
          open={open}
          scroll={"paper"}
        >
          {loading ? (  
            <Loading />
          ) : (
            <>
              <DialogTitle>Send Email</DialogTitle>
              <DialogContent className={`${createDialogStyles.fullHeight}`}>
                <DialogContentText
                  className={`${createDialogStyles.fullHeight}`}
                >
                  <Create {...fakeCreateProps}>
                    <SimpleForm defaultValues={props.defaultValue ?? {}} onSubmit = {(values: any) => sendEmail(values, uploadedFileList, markdownInputValue)} toolbar={<HiddenSaveButton />}>
                      <Grid container style={{ width: "100%" }} spacing={2}>
                        <Grid item md={6}>
                          <TextInput type={'email'} fullWidth source={"from"} validate = {required()} />
                          <TextInput fullWidth type={'email'} source={"to"} validate = {required()}/>
                          <TextInput fullWidth type={'email'} source={"CC"} />
                          <TextInput fullWidth type={'email'} source={"BCC"} />
                          <TextInput fullWidth source={"subject"} />
                          <CustomAutoComplete
                            refMod={"MsgTemplate"}
                            fieldName={"Template"}
                            label={"Template"}
                            optionText={"reference"}
                            customChnage={emailTemplateChange}
                          />  
                        </Grid>
                        <Grid item md={6}>
                          <TextInput fullWidth source={"reply_to"} />
                          <FileInput
                            source="files"
                            label="Attachments"
                            multiple={true}
                          >
                            <FileField source="src" title="title" />
                          </FileInput>
                        </Grid>
                        <Grid key="bodyField" item xs={12} md={12}>
                          <Box my={2}>
                            <Typography variant="subtitle2">
                              {" "}
                              {"Body "}
                            </Typography>
                            <MarkDownInput
                              record={props.defaultValue ?? {}}
                              source="body"
                              handleMarkdownField={handleMarkdownField}
                              saveThisImage={saveThisImage}
                              cureentMarkdownInputValue={markdownInputValue}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </SimpleForm>
                  </Create>
                </DialogContentText>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    className={createDialogStyles.cancelButton}
                    startIcon={<Cancel />}
                    variant={"contained"}
                  >
                    {translate("translations.cancelAction")}
                  </Button>
                  <Button
                    onClick={() =>
                      clickSaveButtons(HIDDEN_SAVE_BUTTONS_CLASSNAME)
                    }
                    startIcon = { isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary' } size={24} /> : <Email />}
                    variant={"contained"}
                    color={"primary"}
                  >
                    {translate("ra.action.save")}
                  </Button>
                </DialogActions>
              </DialogContent>
            </>
          )}
          <Notification />
        </Dialog>
      }
    </>
  );
};

export default EmailDialog;

import React, { useState } from 'react';
import { EditButton, Notification, useTranslate, Button, useNotify, useRefresh } from 'react-admin';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Draggable } from 'react-beautiful-dnd';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom';
import { common } from '@mui/material/colors';
import clsx from 'clsx';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import ArchiveIcon from '@mui/icons-material/Archive';

// @ts-ignore
import * as cbconn from 'corebos-ws-lib/WSClientm';


const useStyles = makeStyles((theme: any) => ({
    root: {
        minWidth: '320px',
        marginBottom: theme.spacing(1),
    },
    cardHeader: {
        padding: theme.spacing(1),
        display: 'flex',
    },
    cardContent: {
        padding: theme.spacing(1),
        display: 'flex',
    },
    cardText: {
        marginLeft: theme.spacing(1),
    },
    taskActionButton: {
        marginLeft: theme.spacing(1),
        textTransform: 'none',
    },
    deleteButton: {
        marginLeft: theme.spacing(2),
        textTransform: 'none',
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.main,
            '@media (hover: none)': {
            backgroundColor: 'transparent'
          }
        }
    },
    avatar: {
        /*  width: 50,
            height: 50, */
        color: theme.palette.getContrastText(common.black),
    },
    img: {
        objectFit: 'contain',
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    medium: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    customFieldLink: {
        textDecoration: 'none !important',
        marginTop: '3px',
    },
}));

export const TaskCard = ({ task, index, resource, handleQuickCreateModal, modPermission }: { task: any; index: number, resource: string, handleQuickCreateModal: any, modPermission: any }) => {
    
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const [ isLoading, setIsLoading ] = useState<any>(false);


    const updateTask = (data: any, newValues: any) => {
        const dataTosend = Object.assign(data, newValues);
        setIsLoading(true);
        cbconn.doRevise(resource, dataTosend).then(() => {
            refresh();
        }).catch(() => {
            notify(translate('Something went wrong'), {type: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }



     if (!task) return null;


    return (
        <>
        <Draggable draggableId={String(task.id)} index={index}>
            {(provided, snapshot) => (
                <div
                    className={classes.root}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <Card
                        style={{
                            opacity: snapshot.isDragging ? 0.9 : 1,
                            transform: snapshot.isDragging
                                ? 'rotate(-2deg)'
                                : '',
                        }}
                        className={classes.root}
                        elevation={snapshot.isDragging ? 3 : 1}
                    >
                       

                        <Box py={1} px={2} display={'flex'} style={{width: '100%'}}>
                            <Box mt={1} justifyContent="start" style={{width: '100%'}}>
                                <Typography variant="subtitle2" component={Link} to={`/${resource}/${task?.id}/show`} color="primary" style={{textDecoration: 'none'}}>
                                    {task.projecttaskname}
                                </Typography>
                                <Typography variant="subtitle2" style={{fontSize: '12px'}}>{task.projecttask_no}</Typography>
                            </Box>
                            <Box mr={2}>
                                <IconButton disableFocusRipple disableRipple disableTouchRipple color='primary' onClick={ () => handleQuickCreateModal(task, true)} title={translate('Time Control')}  >
                                    <AccessTimeIcon fontSize="large" />
                                </IconButton>
                            </Box>
                        </Box>
                        <CardActions>
                            <Box px={1} width='100%'>
                                <Typography variant="body2" color="textSecondary" component="span"> {task.projecttaskprogress} </Typography>
                                {modPermission && modPermission.update && 
                                    <Button
                                        label={translate('Archive')}
                                        onClick={() => updateTask(task, {projecttaskstatus: 'Archive'})}
                                        className={classes.taskActionButton}
                                        disabled={isLoading}
                                        startIcon = { isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary' } size={24} /> : <ArchiveIcon />}
                                    />
                                }
                                {modPermission && modPermission.update && 
                                <EditButton
                                    to={`/${resource}/${task.id}`}
                                    label={translate('Edit')}
                                    record={JSON.parse(JSON.stringify(task))}
                                    className={classes.taskActionButton}
                                />
                                }
                            </Box>
                            <Box style={{float: 'right'}}>
                                <Avatar 
                                    className={clsx(classes.img, classes.avatar, classes['medium'])}
                                    title={task?.ename}
                                    alt={task.ename}
                                    style={{cursor: 'pointer'}}
                                    //onClick={() => redirect(`/Contacts/${task.assigned_user_id}/show`)}
                                >
                                    { task && task.ename && task.ename ?  task.ename.slice(0,1) : 'X'  }
                                </Avatar>
                            </Box>
                        </CardActions>
                    </Card>
                </div>
            )}
        </Draggable>
        <Notification />
        </>
    );
};

import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import DocumentPreview from '.';
import { useTranslate } from "react-admin";



const DocumentPreviewModal = ({ record, title, open, setOpen } : { record: any, title:string, open: boolean, setOpen:any }) => {

    const transalate = useTranslate();

    const handleClose = () => {
		setOpen(false);
	};

    return (
        <Box>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll={'paper'}
				aria-labelledby="document-preview"
				aria-describedby="document-preview-modal"
				fullScreen
				maxWidth={'md'}
			>
				<DialogTitle id="document-preview">{title}</DialogTitle>
				<DialogContent dividers >
					<DocumentPreview title={title} fileUrl={record?._downloadurl} type={record?.filetype} />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						{transalate('Cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
    )
}

export default DocumentPreviewModal;
import it from "./lang/it";
import en from "./lang/en";
import fr from "./lang/fr";
import polyglotI18nProvider from 'ra-i18n-polyglot';

const translations = { fr, it, en };

const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    localStorage.getItem('locale') ?? 'en', // default locale
    [{ locale: 'fr', name: 'Français' }, { locale: 'it', name: 'Italian' }, { locale: 'en', name: 'English' }],
);

export default i18nProvider;

import React, {useEffect} from 'react'
import mermaid from 'mermaid';
import { useFormContext } from 'react-hook-form';
import { useNotify } from 'react-admin';
import { remoteValidate } from '../utils/lib';

//@ts-ignore
import * as cbconn from 'corebos-ws-lib/WSClientm';

export const PFPushAlong = (props: any) => { 
    const {pfid, ctxid, isDetailView, onPflUpdate=null, graphId=''} = props;
    const {setValue} = useFormContext();
    const pflGraphId = graphId ? graphId : 'pfpaoutputgraph' + pfid;

    const renderGraph = (value: any, pfidParam: any) => {
        let output: any = document.getElementById(pflGraphId);
        try {
            output.innerHTML = '';
            mermaid.parse(value);
            mermaid.render('theGraph' + pfidParam, value, function (svgCode) {
                output.innerHTML = svgCode;
            });
            const cbWindow: any = window;
            window.dispatchEvent(cbWindow.coreBOS.PFStateEvent);
        } catch (err) {
            console.error(err);
        }
    }

    const getGraph = (pfidParam: any, ctxidParam: any, _isDetailView: boolean = false) => {
        cbconn.doInvoke('PushAlongFlow', {pflowid: pfidParam, contextid: ctxidParam}, 'GET').then((data: any) => {
                    renderGraph(data.graph, pfidParam);
                    const cbWindow: any = window;
                    cbWindow[data.functionname] = (tostate:any, _forrecord: any, _askifsure: any) => {
                        if (_isDetailView) {
                            const element: any = {
                                'id': data.record
                            };
                            element[data.fieldname] = tostate;
                            cbconn.doRevise(data.module, element).then(() => getGraph(pfid, ctxid));
                            if(onPflUpdate){
                                onPflUpdate();
                            }
                        } else {
                            setValue(data.fieldname, tostate);
                        }
                    }
                },
                (reason: any) => {
                    console.warn('fail to read graph: '+reason);
                }
            ).catch((e:any)=>console.log(e));
    };

    useEffect(() => {
        /* eslint-disable react-hooks/exhaustive-deps */
        const cbWindow: any = window;
        if (cbWindow.coreBOS === undefined) {
            cbWindow.coreBOS = {};
        }
        const processflow: any = {processflow: pfid};
        cbWindow.coreBOS.PFStateEvent = new CustomEvent('PFStateUpdated', processflow);
        mermaid.initialize({startOnLoad: true, securityLevel: 'loose'});
        getGraph(pfid, ctxid, isDetailView);
    }, [pfid, ctxid, isDetailView]);

    return (
        <div id={pflGraphId}/>
    );
};

export const DetailViewPFPushAlong = (props: any) => { 

    const {pfid, ctxid, onPflUpdate=null, graphId='', record=null} = props;

    const pflGraphId = graphId ? graphId : 'pfpaoutputgraph' + pfid;
    const notify = useNotify();

    const renderGraph = (value: any, pfidParam: any) => {
        const output: any = document.getElementById(pflGraphId);
        try {
            output.innerHTML = '';
            mermaid.parse(value);
            mermaid.render('theGraph' + pfidParam, value, function (svgCode) {
                output.innerHTML = svgCode;
            });
            const cbWindow: any = window;
            window.dispatchEvent(cbWindow.coreBOS.PFStateEvent);
        } catch (err) {
            console.error(err);
        }
    }

    const getGraph = (pfidParam: any, ctxidParam: any, _isDetailView: boolean = false) => {
        cbconn.doInvoke('PushAlongFlow', {pflowid: pfidParam, contextid: ctxidParam}, 'GET').then((data: any) => {
                    renderGraph(data.graph, pfidParam);
                    const cbWindow: any = window;
                    cbWindow[data.functionname] = (tostate: any, _forrecord: any, _askifsure: any) => {
                        const element: any = {
                            'id': data.record
                        };
                        record[data.fieldname] = tostate;
                        element[data.fieldname] = tostate;
                        remoteValidate(record.id, data.module, record).then((validationRes) => {
                            if(validationRes.isValid){
                                cbconn.doRevise(data.module, element).then(() => {
                                    getGraph(pfid, ctxid);
                                    if(onPflUpdate){
                                        onPflUpdate();
                                    }
                                }).catch((err: any) => {
                                    notify(err);
                                })
                            } else {
                                notify(validationRes?.errors[0]??'', {type: 'error'});
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    }
                },
                (reason: any) => {
                    console.warn('fail to read graph: '+reason);
                }
            ).catch((e: any) =>console.log(e));
    };

    useEffect(() => {
        /* eslint-disable react-hooks/exhaustive-deps */
        const cbWindow: any = window;
        if (cbWindow.coreBOS === undefined) {
            cbWindow.coreBOS = {};
        }
        const processflow: any = {processflow: pfid};
        cbWindow.coreBOS.PFStateEvent = new CustomEvent('PFStateUpdated', processflow);
        mermaid.initialize({startOnLoad: true, securityLevel: 'loose'});
        getGraph(pfid, ctxid);
    }, [pfid, ctxid]);

    return (
        <div id={pflGraphId} />
    );
};
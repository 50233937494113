import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditButton, ShowButton, DeleteButton, Button } from 'react-admin';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';



const RowAction = ({ basePath, record, resource, modPermission }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				aria-controls="rowMenu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="rowMenu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={handleClose}><ShowButton basePath={basePath} record={record} style={{ textTransform: 'none' }} /></MenuItem>
				<MenuItem onClick={handleClose}> <EditButton basePath={basePath} record={record} style={{ textTransform: 'none', marginTop: '-10px' }} /> </MenuItem>
				{resource && resource === 'Project' &&
					<MenuItem style={{ marginLeft: '-3px', marginTop: '-10px' }}>
						<Button
							label={<span style={{ marginLeft: '1px' }}>{'Tasks'}</span>}
							color="primary"
							onClick={() => navigate(`${'ProjectTask'}?projectid=${record?.id}`)}
							style={{ textTransform: 'none' }}
							startIcon={<AssignmentTurnedInIcon />}
						></Button>
					</MenuItem>
				}
				{modPermission && modPermission.delete 
					?	<MenuItem onClick={handleClose}><DeleteButton basePath={basePath} record={record} style={{ textTransform: 'none', marginTop: '-10px' }} /></MenuItem>
					: null
				}

			</Menu>
		</>
	)
};

export default RowAction;
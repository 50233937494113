import React from 'react'
import {  SaveButton } from 'react-admin'
import { HIDDEN_SAVE_BUTTONS_CLASSNAME } from './HelpersContants';

export const HiddenSaveButton = (props:any) => {
  return (
    <>
      <SaveButton
        className={HIDDEN_SAVE_BUTTONS_CLASSNAME}
        label="Save"
        {...props}
      />
    </>
  );
}

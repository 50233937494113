import frenchMessages from 'ra-language-french';

const ra = frenchMessages.ra;

ra.page.dashboard = 'Dashboard';
ra.page.empty = 'Vide';
ra.page.invite = 'Inviter';
ra.action.export = 'Exporter';
/* ra.notification.updated = 'Data updated |||| %{smart_count} elements updated';
ra.notification.created = 'Data created';
ra.notification.deleted = 'Data deleted |||| %{smart_count} elements deleted'; */

const fr = {
    ra,
    dashboard: {
        searchInputPlaceholder: "Recherche...",
    },
    translations: {
        somethingWentWrong: "Quelque chose s'est mal passé",
        all: "Toute",
        dataUpdated: "Données mises à jour",
        menuPosition: "Emplacement des menus",
        left: "La gauche",
        right: "Droite",
        top: "Haut",
        upload: "Télécharger",
        unsupportedFileType: "Type de fichier non pris en charge",
        relateAction: "Relater",
        selectAction: "Sélectionner",
        backAction: "Retour",
        theme: {
            name: 'Thème',
            light: 'Lumière',
            dark: 'Sombre',
        },
        configuration: "Configuration",
        profile: "Profil",
        viewAll: "Voir tout",
        addAction: "Ajouter",
        associateNew: "Associé Nouveau",
        associateExisting: "Associer existant",
        cancelAction: "Annuler",
        closeAction: "proche",
        associateThisRecord: "Associé nouveau/existante à cet enregistrement",
    },
    "incorrect response: INVALID_SESSIONID: Session Identifier provided is Invalid": 'Session invalide',
    "Theme Setting": "Réglage du thème",
    "Theme Color": "Couleur du thème",
    "Menu Position": "Emplacement des menus",
    "left": "La gauche",
    "right": "Droite",
    "top": "Haut",
    "Position": "Position",
    "Time Control": "Contrôle du temps",
    "Dashboard": "Dashboard",
    "Cancel": "Cancel",
    "File": "File",
    "Search": "Chercher",
    "View All": "Voir tout",
    "Search for": "Rechercher",
    "Assigned to me": "Assigné à moi",
    "Data saved successfully": "Données enregistrées avec succès",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Edit": "Éditer",
    "New Task": "Nouvelle tâche",
    "Archive": "Archive",
    "Version": "Version",
    "A new version was created successfully": "Une nouvelle version a été créée avec succès",
    "Drop your file here to upload or click to select": "Déposez votre fichier ici pour le télécharger ou cliquez pour le sélectionner",
    "TimeControl created successfully": "TimeControl créé avec succès",
    "Record created successfully": "Enregistrement créé avec succès",
    "Activities": "Activités",
    "Activity": "Activité",
    "Related View": "Vue associée",
    "Toggle View": "Basculer la vue",
    "Invalid username or password": "identifiant ou mot de passe incorrect",
    "My Home Page": "Ma page d'accueil",
    "Show More": "Show More",
    "Show Less": "Montrer moins",
};

export default fr;

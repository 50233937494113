import React, { memo } from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { useFormContext } from 'react-hook-form';
import { formatSearchObject } from "../../utils/Helpers";


function CustomAutoComplete({
  refMod,
  fieldName,
  label,
  optionText,
  customChnage,
}: {
  refMod: string;
  fieldName: string;
  label: string;
  optionText: string;
  customChnage ? :Function;
}) {
const {setValue } = useFormContext();
  return (
    <ReferenceInput
      fullWidth
      label={label}
      source={fieldName}
      reference={refMod}
      filterToQuery={(searchText: any) => formatSearchObject(optionText, searchText)}
    >
      <AutocompleteInput optionText={optionText} onSelect ={(selected: any)=>{
       if(customChnage){
            const {subject,template} = selected;
            setValue ('subject',subject);
            setValue ('body',template);
            customChnage(selected);
       }
      }} />
    </ReferenceInput>
  );
}

export default memo(CustomAutoComplete);

import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
let renderLabel = function(entry) {
  return entry.name;
}


export default class CbPieChart extends PureComponent {


  render() {

    const {questions} = this.props;

    const questionsdata = questions?.answer?.map((question) => {
      return {
        name: Object.values(question)[1],
        value: parseInt(Object.values(question)[0]),
      }
    }) || [];


    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300, width: '100%',  marginBottom: -20}}>
       <ResponsiveContainer width="100%" height="100%">
         <PieChart width={400} height={300} onMouseEnter={this.onPieEnter}>
        <Pie
        label={renderLabel} 
          data={questionsdata}
          innerRadius={100}
          outerRadius={120}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      </ResponsiveContainer>  
      </div>
     
     
    );
  }
}
import React, { useState, useEffect } from 'react';
import { Edit, useResourceContext, useResourceDefinition, useRecordContext } from 'react-admin';
import { getDataFromLocalDb } from '../../utils/Helpers';
import { TABLE_DESCRIBE } from '../../local-db';
import { 
	Box, 
	Grid, 
} from '@mui/material';
import Title from '../Title';
import EditForm from '../EditForm';


export const CbEditTabGuesser = () => {
	
	const record = useRecordContext();
	const { options } = useResourceDefinition();
	const resource = useResourceContext();
	const [labelField, setLabelField ] = useState(options?.label??'');
	const [describe, setDescribe] = useState({});
	const [blocks, setBlocks] = useState([]);
	const modPermission = options?.permission[0]?.permissions??{};
	const [ label, setLabel ] = useState(options?.label??'');
	

	useEffect(() => {
		/* eslint-disable react-hooks/exhaustive-deps */
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then(async (result) =>  {
			setDescribe(result);
			setLabel(result[resource]?.label);
			let bfields = [];
			let mfields = result[resource]?.fields ?? [];
			for (let f = 0; f<mfields.length; f++) {
				if (!mfields[f].block) {
					continue;
				}
				let bidx = bfields.findIndex((element) => element.id === mfields[f].block.blockid);
				if (bidx===-1) {
					bfields.push({
						id: mfields[f].block.blockid,
						sequence: mfields[f].block.blocksequence,
						label: mfields[f].block.blocklabel,
						name: mfields[f].block.blockname,
						fields: []
					});
					bidx = bfields.findIndex((element) => element.id === mfields[f].block.blockid);
				}
				bfields[bidx].fields.push(mfields[f]);
			}

			setBlocks(bfields);
			if(!options?.label){
				const labelFields = result[resource]?.labelFields;
				if(labelFields){
					let aLabelField = labelFields.split(',');
					aLabelField = aLabelField && aLabelField[0] ? aLabelField[0] : '';
					setLabelField(aLabelField);
				}
			}
		});
	}, [options?.label, resource])


	
	return (
		<Box px={0.5} >
			<Grid container spacing={1}>
				<Grid item xs={12} md={12}>
					<Box className='raPageContent'>
						<Edit title={ <Title resource={resource} label={label} showTitle={options?.showTitle} record={record} linkField={labelField}  /> }  >
							<EditForm describe={describe} blocks={blocks} modPermission={modPermission} resource={resource} record={record} />
						</Edit>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
};


import React from 'react'
import { Helmet } from 'react-helmet';

export const SEO = ({title, description = '', meta = []} : {title: string, description: string, meta: any[]}) => {
 return(
  <Helmet title = {title}
        //htmlAttributes={{ lang: "en" }}
        meta={meta}
   />
  )
}
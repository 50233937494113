import React, { useState, forwardRef, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { AppBar, Drawer, IconButton, Divider, Toolbar, Box, Typography, Theme, CssBaseline } from '@mui/material';
import { APP_NAME, COMPANY_LOGO_URL, THEME_CONFIG } from "../constant";
import { Loading, Logout, MenuItemLink, useGetIdentity, useRedirect, UserMenu, useTranslate } from 'react-admin';
import { ContentContext } from '../contentContext';
import AppMenu from './Menu';



const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        marginTop: 0,
        marginBottom: 0,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    title: {
        flex: 'inherit',
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    spacer: {
        flex: 1,
    },
    appBarShift: {
        marginLeft: THEME_CONFIG.drawerWidth,
        width: `calc(100% - ${THEME_CONFIG.drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: THEME_CONFIG.drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        height: '100vh'
    },
    drawerOpen: {
        width: THEME_CONFIG.drawerWidth,
        backgroundColor: THEME_CONFIG.sideBarBackground,
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        backgroundColor: THEME_CONFIG.sideBarBackground,
        boxShadow: '0 0 18px rgba(0,0,0,0.25)',
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: THEME_CONFIG.drawerCloseWidth,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: `calc(100% - ${THEME_CONFIG.drawerWidth}px)`,
    },
}));

const CustomUserMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    return (
        <UserMenu {...props}>
            <MenuItemLink
                ref={ref}
                to="/"
                primaryText={translate('ra.page.dashboard')}
                leftIcon={<DashboardOutlinedIcon />}
                onClick={props.onClick}
            />
            <MenuItemLink
                ref={ref}
                to={`/Contacts/${props?.contact?.id || props?.user?.id}/show`}
                primaryText={translate('translations.profile')}
                leftIcon={<PersonOutlineOutlinedIcon />}
                onClick={props.onClick}
            />
            <Divider />
            <Logout />
        </UserMenu>
    )
});

const AppDrawer = (props: any) => {
    const { isLoading, isLoadingMenu, menuItemsList } = props;
    const classes = useStyles();
    const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(true);
    const redirect = useRedirect();
    const { identity } = useGetIdentity();
    const [contentheight, setContentheight] = useState<number>(0);
    const contentHeightRef: any = useRef(null);
    const toolbarref: any = useRef(null);


    useEffect(() => {
        if (contentHeightRef.current) {
            setContentheight(window.innerHeight - (220))
        }
    }, []);


    const handleDrawerOpen = () => {
        setOpen(!open);
        setSidebarIsOpen(!open);
    };


    return (
        <ContentContext.Provider value={contentheight}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" color='default' elevation={0} className={clsx(classes.appBar, { [classes.appBarShift]: open,})}  ref={contentHeightRef}>
                    <Toolbar sx={{py: 0, mt: -1}}>
                        <IconButton
                            color='inherit'
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: false,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                        <span className={classes.spacer} />
                        <Box alignSelf={'center'}>
                            <CustomUserMenu {...props} user={identity?.user} contact={identity?.contact} />
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >

                    <>
                        <Box mx={2} mt={3} mb={3}>
                            {COMPANY_LOGO_URL
                                ? <img src={COMPANY_LOGO_URL} alt={'Company Logo'} style={{ width: '100%', cursor: 'pointer' }} onClick={() => redirect('/')} />
                                : <Typography variant="h6" color="inherit">{APP_NAME}</Typography>
                            }
                        </Box>
                        <AppMenu  logout={props.logout} hasDashboard={!!props.dashboard} {...props} menuItemsList={menuItemsList} isLoadingMenu={isLoadingMenu} sidebarIsOpen={sidebarIsOpen}  />
                    </>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.toolbar} ref={toolbarref} />
                    <>
                        <Box>
                            <Typography
                                variant="h6"
                                color="inherit"
                                className={classes.title}
                                id="react-admin-title"
                            />
                        </Box>
                        <> { isLoading ? <Loading /> : <> {props?.children} </> } </>
                        
                    </>
                </main>
            </div>
        </ContentContext.Provider>
    );
}

export default AppDrawer;
import React, { cloneElement } from 'react';
import { ExportButton, sanitizeListRestProps, TopToolbar, Button, useRedirect, useTranslate } from 'react-admin';
import AddIcon from '@mui/icons-material//Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


export const ListActionToolbar = (props: any) => {
    const { resource, total, currentSort, maxResults, filters, displayedFilters, filterValues, basePath, modPermission, showFilter, toggleView, isGridView, toggleButton, setRelatedSearchshow, relatedSearchshow, ...rest } = props;
    const redirect = useRedirect();
    const translate = useTranslate();


    return (
        <TopToolbar my={2} display="flex" justifyContent="end" {...props} {...sanitizeListRestProps(rest)}>
            { toggleView && 
                 <Button
                    variant="text"
                    color="primary"
                    onClick={() => toggleView(isGridView)}
                    label={translate('Toggle View')}
                    title={translate('Toggle View')}
                >
                    {toggleButton}
                </Button>
            }
            { setRelatedSearchshow &&
                <Button
                    variant="text"
                    color="primary"
                    onClick={() => setRelatedSearchshow(!relatedSearchshow)}
                    label={translate('Related View')}
                    title={translate('Related View')}
                >
                    { relatedSearchshow ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </Button>
            }
            {filters && cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
            <ExportButton 
                //label="" 
                //variant="text" 
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
                size="small"
            />
            {modPermission && modPermission.create && 
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => redirect(`/${props.resource}/create?${props?.params}`)}
                    label={translate('ra.action.create')}
                    title={translate('ra.action.create')}
                >
                <AddIcon />
              </Button>
            }
        </TopToolbar>
    )
}
/* export const status = [
    '--None--',
    'Open',
    'In Progress',
    'deferred',
    'cancelled',
    'completed',
];

export const statusNames = {
    '--None--': 'None',
    Open: 'Open',
    'In Progress': 'In Progress',
    deferred: 'Deferred',
    cancelled: 'Cancelled',
    completed: 'Completed',
};
 */

export const status = [
    'Backlog',
    'To Do',
    'Doing',
    'To Be Tested Demo',
    'To Be Tested Production',
    'Done'
];

export const statusNames = {
    'Backlog': 'Backlog',
    'To Do': 'To Do',
    'Doing': 'Doing',
    'To Be Tested Demo': 'To Be Tested Demo',
    'To Be Tested Production': 'To Be Tested Production',
    'Done': 'Done',
};


export const statusChoices = status.map(type => ({
    id: type,
    /* @ts-ignore */
    name: statusNames[type],
}));

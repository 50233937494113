import * as cbconn from 'corebos-ws-lib/WSClientm';
import DropzoneComponent from 'react-dropzone-component'
import * as DropZone from 'dropzone';

import {
    ImageField,
    FileField,
} from 'react-admin';

const convertPdf2Base64 = (document) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(document);
    return new Promise((resolve, reject) => {
      fileReader.onload = () => resolve(fileReader.result)
      fileReader.onerror = (error) => reject(error);
    })
}

export const Dropzone = DropZone;
export const DropzoneComp = DropzoneComponent;

export const getChallenge = async (username, password) => {
    const challengeRes = await cbconn.doLogin(username, password);
    return challengeRes;
}

export const getListTypes = async () => {
    const listTypes = await cbconn.doListTypes();
    return listTypes;
}

export const getModDescribe = async (moduleName) => {
    const describe = await cbconn.doDescribe(moduleName);
    return describe;
}

export const doRetrieve = async (record) => {
    const result = await cbconn.doRetrieve(record);
    return result;
}

export const doMassRetrieve = async (ids) => {
    const result = await cbconn.doMassRetrieve(ids);
    return result;
}

export const doQuery = async (query) => {
    const result = await cbconn.doQuery(query);
    return result;
}

export const doInvoke = async (method, params, type) => {
    const result = await cbconn.doInvoke(method, params, type);
    return result;
}

export const doCreate = async (moduleName, data) => {
    const result = await cbconn.doCreate(moduleName, data);
    return result;
}

export const convertPdfToBase64 = (document) => {
    return convertPdf2Base64(document);
}

export const handleFileDisplay = (file) => {

    if(file.filetype.split('/')[0] === 'image'){
        return (
            <ImageField
                key={file.id}
                label={''}
                source="_downloadurl"
                record={file}
            />
        );
    }else{
        return (
            <FileField source="_downloadurl" record={file}  label={''} title={file.notes_title} download fullWidth target="_blank" />
        );
    }

}

export const prepareFileToUpload = async (fileToUpload, title, relationsIds, relProjectId, assigned_user_id) => {

    const fileExtension = fileToUpload.name.split('.').pop();
    const base64document = (fileExtension === 'pdf') ? await convertPdf2Base64(fileToUpload) : fileToUpload.dataURL
        
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const fileNameFormat = `${title}_${yyyy}-${mm}-${dd}_${fileToUpload.name}`;

    const fileParams = {
        name: fileNameFormat,
        size: fileToUpload.size,
        type: fileToUpload.type,
        content: base64document,
    };


    const docData = {
        notes_title: `${fileParams.name}`,
        filename: fileParams,
        filetype: fileToUpload.type,
        filesize: fileToUpload.size,
        filelocationtype: 'I',
        filestatus: 1,
        relations: relationsIds,
        reltoproject: relProjectId,
        assigned_user_id: assigned_user_id,
    };

    return docData;

}


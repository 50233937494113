import React, { PureComponent } from 'react';


export default class CbNumberchart extends PureComponent {

  render() {
      const {questions} = this.props;
      const results = questions?.answer;
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200, width: '100%', padding: 50}}>
         {Array?.isArray(results) && results?.map((result) => {
             return (
                <span style={{color: '#000', fontSize: 50}}>{Object.values(result)[0]}</span>
             )
         })}
      </div>
    );
  }
  
}
import React from 'react';
import Chart from 'react-apexcharts';

class CbRowchart extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
      
        series: [{
          data: []
        }],
        options: {
          chart: {
            id: this.props.chartElementId ?? 'rowChart',
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                  fontSize: 16
              }
         }
          },
          yaxis: {
            labels: {
              style: {
                  fontSize: 16
              }
         }
          }
        },
      
      
      };
    }


    componentDidMount() {
      // Typical usage (don't forget to compare props):
        let rowdata = Array.isArray(this.props?.questions?.answer) ? this?.props?.questions?.answer?.map((question) => {
            return Object.values(question)[1]
          }) : [];
          let rowcat =  Array.isArray(this?.props?.questions?.answer) ? this?.props?.questions?.answer?.map((question) => {
          return `${Object.values(question)[0]}`;
        }) : [];
        this.setState({series: [{
          data: rowdata
        }], options: {
          chart: {
            id: this.props.chartElementId ?? 'rowChart',
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: rowcat,
            labels: {
              style: {
                  fontSize: 16
              }
         }
          },
          yaxis: {
            labels: {
              style: {
                  fontSize: 16
              }
         }
          }
        },})
    }

    render() {
      return (     
          <div id="chart" style={{width: '100%', marginBottom: 50}}>
            {this?.props?.questions?.answer ?  <Chart options={this.state.options} series={this.state.series} type="bar" /> : null}
          </div>
      );
    }
    
  }
  export default CbRowchart;
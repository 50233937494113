import { ThemeName, MenuPosition, UserMenu } from '../types';

export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_MENU_POSITION = 'CHANGE_MENU_POSITION';
export const CHANGE_APP_MENU = 'CHANGE_APP_MENU';

export const changeTheme = (theme: ThemeName) => ({
    type: CHANGE_THEME,
    payload: theme,
});

export const changeMenuPostion = (postion: MenuPosition) => ({
    type: CHANGE_MENU_POSITION,
    payload: postion,
});

export const changeAppMenu = (menu: UserMenu) => ({
    type: CHANGE_APP_MENU,
    payload: menu,
});
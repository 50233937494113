import { useInput, BooleanInput, BooleanField } from 'react-admin';
import { clearTable, getDataFromLocalDB, saveDataToLocalDB, TABLE_DESCRIBE, TABLE_PERMISSIONS, TABLE_LIST_VIEWS } from '../local-db';
import { getModDescribe, doInvoke, doCreate, massCreate } from './lib';
import DropzoneComponent from 'react-dropzone-component'
import * as DropZone from 'dropzone';
import {
    ImageField,
    UrlField,
} from 'react-admin';
import { IconSettings, Icon } from '@salesforce/design-system-react';
import { authProvider } from '../authProvider';

const convert2Base64 = (document: any) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(document);
    return new Promise((resolve, reject) => {
      fileReader.onload = () => resolve(fileReader.result)
      fileReader.onerror = (error) => reject(error);
    })
}

export const Dropzone = DropZone;
export const DropzoneComp = DropzoneComponent;


const getDataFromDB = async (tableName: string) => {
    const data = await getDataFromLocalDB(tableName);
	if(tableName === 'permissions' ){
		return data && data.length ? data : null;
	}
	return data && data[0] ? data[0] : null;
}

const parseDate = (v: any) => {
    const regexp = /(\d{4})-(\d{2})-(\d{2})/;

    if(!v || !regexp.exec(v) ) return;
    let date = new Date(v);

    let match = regexp.exec(date.toISOString());
    if (match === null) return;
    let year = match[1];
    let month = match[2];
    let day = match[3];
    return [year, month, day].join("-");
};

export const formatSearchObject = (field:any, searchText:any)  => {
    if (!searchText) {
        return;
    }
    let srch:any = {};
    srch[field] = searchText;
    return srch;
}

export const FormattedBooleanInput = (props:any) => {
    const {field} = useInput(props);
    const isChecked = {checked: false};

    if (Number(field.value)) {
        isChecked.checked = true;
    }
    return (<BooleanInput {...props} options={isChecked}/>);
};

export const FormattedBooleanField = (props:any) => {
    props.record[props.source] = (props.record[props.source] === '1');

    return (
        <div>
            <div style={{width: '100%'}}>
                <label className="MuiFormLabel-root">{props.label}</label>
            </div>
            <BooleanField {...props} />
        </div>
    );
};

export const getDataFromLocalDb = async (tableName: string) => {
	const data = await getDataFromDB(tableName);
	return data;
}

export const loginAction = async (coreBOSDescribe: string[]) => {
    const _window: any = window;
    if (_window.coreBOS === undefined) {
        _window.coreBOS = {};
    }
	await clearTable(TABLE_PERMISSIONS.tableName);
	await clearTable(TABLE_DESCRIBE.tableName);
    await clearTable(TABLE_LIST_VIEWS.tableName);

    let permissions: any[] = [];
    let Describe: any = {};
    let ListViews: any = {};

    return getModDescribe(coreBOSDescribe.join(',')).then((descinfo: any) => {
            for (let key in descinfo) {
                Describe[key] = descinfo[key];
                let modulePermissions = {
                    module: key,
                    permissions: {
                        create: descinfo[key].createable,
                        delete: descinfo[key].deleteable,
                        update: descinfo[key].updateable,
                        read: descinfo[key].retrieveable,
                    }
                };
                permissions.push(modulePermissions);
            }
            return permissions;
    }).then(async (result: any) => {
        await saveDataToLocalDB(TABLE_PERMISSIONS.tableName, result, true);
        let AssignedUserList = [];
        let ViewsByModule: any[] = [];
        for (let mod = 0; mod < coreBOSDescribe.length; mod++) {
            if (Describe[coreBOSDescribe[mod]]) {
                AssignedUserList.push(
                    doInvoke('getAssignedUserList', { module: coreBOSDescribe[mod] }, 'GET').then((users: any) => {
                        Describe[coreBOSDescribe[mod]].userlist = JSON.parse(users);
                    }).catch(e=>console.log(e))
                );
                ViewsByModule.push(
                    doInvoke("getViewsByModule", { module: coreBOSDescribe[mod] }, "GET").then((data) => {
                        ListViews[coreBOSDescribe[mod]] = data;
                    }).catch(e=>console.log(e))
                );
            }
        }

        return Promise.all(AssignedUserList).then( () => {
            return saveDataToLocalDB(TABLE_DESCRIBE.tableName, Describe, false);
        }).then(async () => {
            await Promise.all(ViewsByModule);
            return await saveDataToLocalDB(TABLE_LIST_VIEWS.tableName, ListViews, false);
        }).then(() => {
            _window.coreBOS.Describe = Describe;
            return Promise.resolve(Describe);
        })
    });
}

export const getModuleTabsWithFields = async (moduleName: string) => {
    const fields: any[] = [];
    const describe = await getDataFromDB(TABLE_DESCRIBE.tableName);
    if(describe == null) {
        return[];
    }

    if (!describe[moduleName]) {
        return [];
    }

    describe[moduleName].fields.forEach((e: any) => {
        if (e.block ) {
            fields.push({name: e.block.blockname, fields: [], tab: e.block.blocklabel});
        }
    });

    let tabs = new Map<any[], any>(fields.map((item:any) => [item['name'], item]));
    describe[moduleName].fields.forEach((e: any) => {
        if (e.block) {
            tabs.forEach((tab: any) => {
                if (tab.name === e.block.blockname) {
                    tab.fields.push(e);
                }
            });
        }
    });

    return tabs;
};
export const getFileParams = async (fileToUpload: File, title:string) => {
    const base64document = await convert2Base64(fileToUpload);
     
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const fileNameFormat = `${yyyy}-${mm}-${dd}_${fileToUpload.name}`;

    return {
        name: fileNameFormat,
        size: fileToUpload.size,
        type: fileToUpload.type,
        content: base64document,
    } as any;
}
export const chunkArray = (arr: any[], chunkSize? :number) :any[] => {
  const perChunk = chunkSize ?? 4; // items per chunk

  const result = arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  return result;
};
export const massCreateDocumentsChunks= async (files: any[],userId : string, chunkSize?: number) => {

   let attachments = await Promise.all(
      files
        .map(async (file: any) => {
            let docObj :any = {};
          const fileParams = await getFileParams(
            file.rawFile,
            file.title
          );
          const docData = {
            notes_title: `${fileParams.name}`,
            filename: fileParams,
            filetype: fileParams.type,
            filesize: fileParams.size,
            filelocationtype: 'I',
            filestatus: 1,
            assigned_user_id: userId,
        };
          docObj['elementType'] = 'Documents';
          docObj['element'] = {...docData};
          return Promise.resolve(docObj);
        })    );
      attachments = [...chunkArray(attachments, chunkSize)];
      let docIds :any[] = [];
      for (const chunkArray of attachments) {
            // * SEND MASS CREATE REQUEST FOR EVERY CHUNK
            const { success_creates } = await massCreate(chunkArray);
            const newDocIds = success_creates
              .map((successCreate: any) => successCreate.id);
            docIds.push(...newDocIds);
           
    }
    return docIds;
    
}

export const prepareMenu = (menuMap: any) => {
    if(!menuMap) return [];
    const menu: any = {};
    const menuItems: any[] = [];
    const moduleList: any[] = [];

    for (const key in menuMap) {
        if(menuMap.hasOwnProperty(key)) {
            if(!menu.hasOwnProperty(key)) {
                if(menuMap[key]["4"] === '0' || menuMap[key]["4"] === 0){ // Menu tier (Menu heading)
                    menu[key] = {
                        menuId: menuMap[key]['0'],
                        type: 'menu',
                        name: menuMap[key]['2'],
                        label: menuMap[key]['3'], 
                        parentMenu: menuMap[key]['4'],
                        menuItemSequence: menuMap[key]['5'],
                        menuItemVisibility: menuMap[key]['6']=== '1',
                        permittedIds: menuMap[key]['7'],
                        icon: menuMap[key]['9'] ?? null,
                    };
                } else {
                    if(menuMap[key]["1"] === 'module' || menuMap[key]["1"] === 'menu' || menuMap[key]["1"] === 'url'){
                        moduleList.push({
                            menuId: menuMap[key]['0'],
                            type: menuMap[key]["1"],
                            name: menuMap[key]['2'],
                            label: menuMap[key]['3'], 
                            parentMenu: menuMap[key]['4'],
                            menuItemSequence: menuMap[key]['5'],
                            menuItemVisibility: menuMap[key]['6'] === '1',
                            permittedIds: menuMap[key]['7'],
                            icon: menuMap[key]['9'] ?? null,
                        })
                    }
                }
            } 
        }
    }

    for (const key in menu) { // 1st Level
        menuItems.push(
            {
                menuId: menu[key].menuId,
                type: menu[key].type,
                name: menu[key].name,
                label: menu[key].label, 
                parentMenu: menu[key].parentMenu,
                menuItemSequence: menu[key].menuItemSequence,
                menuItemVisibility: menu[key].menuItemVisibility,
                permittedIds: menu[key].permittedIds,
                icon: menu[key].icon,
                menuItems: moduleList.filter(mod => mod.parentMenu === menu[key].menuId)
            }
        );
    }

    for (const menuItem of menuItems) { // 2nd Level
        for (const key in menuItem.menuItems) {
            menuItem.menuItems[key] = {
                menuId: menuItem.menuItems[key].menuId,
                type: menuItem.menuItems[key].type,
                name: menuItem.menuItems[key].name,
                label: menuItem.menuItems[key].label, 
                parentMenu: menuItem.menuItems[key].parentMenu,
                menuItemSequence: menuItem.menuItems[key].menuItemSequence,
                menuItemVisibility: menuItem.menuItems[key]['menuItemVisibility'],
                permittedIds: menuItem.menuItems[key].permittedIds,
                icon: menuItem.menuItems[key].icon,
                menuItems: moduleList.filter(mod => mod.parentMenu === menuItem.menuItems[key].menuId)
            }
        }
    }
    return menuItems;
}

export const prepareFileToUpload = async (fileToUpload: any, title: string, relationsIds: any, relProjectId: any, assigned_user_id: string) => {

    const base64document = await convert2Base64(fileToUpload);
     
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const fileNameFormat = `${title}_${yyyy}-${mm}-${dd}_${fileToUpload.name}`;

    const fileParams = {
        name: fileNameFormat,
        size: fileToUpload.size,
        type: fileToUpload.type,
        content: base64document,
    };


    const docData = {
        notes_title: `${fileParams.name}`,
        filename: fileParams,
        filetype: fileToUpload.type,
        filesize: fileToUpload.size,
        filelocationtype: 'I',
        filestatus: 1,
        relations: relationsIds,
        reltoproject: relProjectId,
        assigned_user_id: assigned_user_id,
    };

    return docData;

}

export const uploadFile = async (file: any, title: string, relationsIds: any, relProjectId: any, assigned_user_id: string) => {
    let assignedUserId = '';
    if(!assigned_user_id){
        const user = await authProvider.getIdentity();
        assignedUserId = user?.id;
    }
    const preparedFile = await prepareFileToUpload(file, title, relationsIds, relProjectId, assignedUserId);
    const result = await doCreate('Documents', preparedFile);
    return result;
}

export const handleFileDisplay = (file: any) => {

    if(file.filetype === 'image/png' || file.filetype === 'image/jpg' || file.filetype === 'image/jpeg'){
        return (
            <ImageField
                key={file.id}
                label={''}
                source="_downloadurl"
                record={file}
            />
        );
    }else{
        return (
            <UrlField source="_downloadurl" record={file}  label={''} target="_blank" />
        );
    }

}

export const convertFileToBase64 = async (file:any) => {
    const base64 = await convert2Base64(file);
    return base64;
}

export const ModMenuIcon = ({menu, moduleName, IconContainerStyle, IconStyle} : {menu: any[], moduleName: string, IconContainerStyle: any, IconStyle: any}) => {
    
    const foundMenuItem: any = menu.filter((menuItem: any) => menuItem.name === moduleName)[0];
    if(!foundMenuItem) {
        return <></>
    }

    return(
        <IconSettings iconPath="/icons" style={IconContainerStyle}>
            <div className={`${foundMenuItem?.icon?.containerClass}`}>
                <Icon
                    category={foundMenuItem?.icon?.library}
                    name={foundMenuItem?.icon?.icon}
                    className={`${foundMenuItem?.icon.class}`}
                    size="small"
                    style={IconStyle}
                />
            </div>
        </IconSettings>
    )
}
 // * function that returns only the maps related to the current field
 export const filterRelatedMaps = (dependencyMaps: any[], indexesToFind :any[number]) => {
    return dependencyMaps.filter((map: any, index) => indexesToFind.includes(index));
  };

export const dateParser = (v: any) => parseDate(v);
export const dateTimeParser = (v:any) => {
    if(!v) return;
    const dateTime = parseDate(v);
    if(!dateTime) return;
    const regexp = /(\d{4})-(\d{2})-(\d{2})/;
    const date = new Date(v);
    let match = regexp.exec(date.toISOString());
    if (match === null) return;

    const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
    return `${dateTime} ${time}`;
}

export const filterDependencyMaps = (currentMap :any )=>{
    const parentFieldNames = currentMap && currentMap.fields ? Object.keys(currentMap?.fields) : [];
    const allMaps :any[] = [];

      let fieldNames: any[] = [];
      if (parentFieldNames.length > 0) {
        parentFieldNames.forEach((parentFieldName: any, index: number) => {
          const parentFieldDependencyMaps = currentMap?.fields[parentFieldName]; // * get the map for every parent field
          parentFieldDependencyMaps.forEach((parentFieldDependencyMap: any) => {
            // * loop through parent field maps
            parentFieldDependencyMap.field = parentFieldName;
            allMaps.push(parentFieldDependencyMap);
            const actionNames = Object.keys(parentFieldDependencyMap?.actions); // *get the action names
            actionNames.forEach((actionName: string) => {
              //* loop through action names
              const actionArray = parentFieldDependencyMap?.actions[actionName]; // * get the array of actions which hold the dependent field names
              actionArray.forEach((action: any) => {
                fieldNames.push({
                  parentFieldName: parentFieldName,
                  dependentFieldName: action.field,
                  index: index,
                  action: actionName,
                });
              });
            });
          });
        });
        
      }
      return {
        allMaps: allMaps,
        fieldNames: fieldNames,
    }
}


export const handleSpecialChars = (text: string) => {
    let newText = text;
    if(newText && typeof newText === 'string'){
        newText = newText.replaceAll("\n", "<br />");
    }
    return newText;
}

export const reformatData = (data: any) => {
    let newData = data;
    if(newData && typeof newData === 'string'){
        newData = newData.replace(/&/g, '%26');
    }
    return newData;
};

export const treatSpecialChars = (text: string) => {
    let newText = '';
    if(text){
        console.log('text', text);
        newText = text.replace(/&nbsp;/g,' ').trim().replaceAll(String.fromCharCode(13), "\\n").replaceAll("\n", "\\n");
        //You can implement/add method for other chars
    }

    return (newText);
};

export const reformatPostingData = (data: any) => {
    if(data && typeof data === 'object'){
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if(data[key] && typeof data[key] !== 'object'){
                    data[key] = reformatData(data[key]);
                }
            }
        }
    }else{
        data =  reformatData(data);
    }

    return data;
}

export const prepareDescribeData = (userMenu: any) => {
    const coreBOSDescribe = [];
    for (const key in userMenu) {
        if(userMenu.hasOwnProperty(key) && userMenu[key]['2']) {
            if(userMenu[key]['1'] === 'module' && userMenu[key]['2'] && userMenu[key]['2'] !== 'Reports'){
            coreBOSDescribe.push(userMenu[key]['2']) 
            }
        }
    } 

    return coreBOSDescribe;
}




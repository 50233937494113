import React from "react";
import cbUtils from "./corebosUtils/corebosUtils";
import { FIELD_DEPENDENCY_ACTIONS } from "../constant";
import { cloneElement } from "react";
import { useFormContext } from 'react-hook-form';
import {filterRelatedMaps} from '../utils/Helpers';

export const DependentField = ({
  field,
  resource,
  describe,
  assignedUserList,
  formValues,
  dependencyMaps,
  source,
  formattedDepenedncyMap, // *map that contains {dependant field name, parent field name, and the index of the map in @dependencyMaps}
}: {
  field: any;
  resource: string;
  describe: any;
  assignedUserList?: any;
  formValues: any;
  dependencyMaps: any[];
  source :string;
  formattedDepenedncyMap :any;
}) => {
  
  const { setValue } = useFormContext();


  //* returns only the maps where the condition is true for the current field that is being displayed
  const getTheMapsinWhichTheConditionIsTrue = () => {
    const relatedMaps: any = filterRelatedMaps(dependencyMaps, formattedDepenedncyMap.map((map :any) => map?.index));
    return relatedMaps
      // eslint-disable-next-line array-callback-return
      .filter((map: any) => {
        const parentFieldName = map?.field;
        let conditions =  map?.conditions ? JSON.parse(map?.conditions) : map?.conditions;
        if (conditions?.length === 0) {
          return {}; //*return empty object there are no conditions
        }else if(conditions?.length === 1){
          const { value, comparator } = conditions[0];
          if (cbUtils.compare(comparator, formValues[parentFieldName], value)) {
            return map;
          }
        }else{
          // * this is the case when there are more than one conditions
          //! NEED TO LOOK INTO THIS, COULD BE PROBLEM WITH THE FILTERING AND PARSING OF THE CONDITIONS
           // eslint-disable-next-line array-callback-return
           conditions =  conditions.filter((condition: any) => { // *get only the conditions that are true
            const { value, comparator } = condition;
            if (
              cbUtils.compare(comparator, formValues[parentFieldName], value)
            ) {
              return condition;
            }
          });
         if(conditions?.length) return {...map, conditions: conditions}; //*return the map if there are conditions that are true
        }
      }).map((valueMap: any) => {
        return { ...valueMap, conditions:valueMap?.conditions ? JSON.parse(valueMap?.conditions) : valueMap?.conditions };
      });
  };

  const render = () => { // *this function needs to always return @inputToRender variable
    let input = cbUtils.field2InputElement(
      field,
      resource,
      { fullWidth: true },
      describe
    );
    let inputProps = {
        ...input!.props
    };
    let inputToRender;
    let mapsToConsider = getTheMapsinWhichTheConditionIsTrue();
    if (mapsToConsider?.length === 0) {
      inputToRender = cloneElement(input!, inputProps);
    }
    mapsToConsider.forEach((map :any) => {
        Object.keys(map?.actions).forEach((action: any) => {
          const actionArray = map?.actions[action]; // * get the array of actions which hold the dependent field names         
           actionArray.forEach((actionMap :any) =>{
            if(!actionMap?.field.includes(field?.name)){
              return ;
            }
            if(!map?.conditions){

            }else{
                const conditions : any[] = map?.conditions;
                conditions.forEach((condition: any) => {
                    if(action === FIELD_DEPENDENCY_ACTIONS.CHANGE){// *change input action
                      const fieldIndexInNotChangeInputs = (window as any).notChangeInputs?.indexOf(field?.name);
                        inputProps = {...inputProps, defaultValue : actionMap?.value, onChange : (e: any) => {
                            if(typeof (window as any).notChangeInputs === 'undefined') (window as any).notChangeInputs = [];
                            if(e?.target?.value !== actionMap?.value){ //* if the user has chosen something else than the provided value from the map
                              if((window as any).notChangeInputs?.indexOf(field?.name) <  0) (window as any).notChangeInputs.push(field?.name); //* add the field name to the notChangeInputs array
                            }
                        }};
                        if(fieldIndexInNotChangeInputs <  0 || typeof fieldIndexInNotChangeInputs === 'undefined'){ //* if the field is not in the notChangeInputs array, means there is another value selected
                          setValue(field?.name, actionMap?.value); // * so don't change the form value
                        }
                        inputToRender = cloneElement(input!, inputProps);
                    }else if(action === FIELD_DEPENDENCY_ACTIONS.HIDE){ //* hide input action
                        inputToRender = <> </>; //* set the input to nothing
                    }else if(action === FIELD_DEPENDENCY_ACTIONS.SET_OPTIONS){ //* set options action
                      const _option = actionMap?.options;
                      const inputChoices = input?.props?.choices;
                      if (typeof _option !== 'string'){
                        //* there are more than one options
                        const _optionsToAdd = _option.map((option: string) =>{
                          return {
                            ...inputChoices[0],
                            label: option,
                            value: option
                          }
                        });
                        inputProps = {...inputProps, choices: _optionsToAdd};
                      }else{
                        //*there is only one option
                        const _optionsToAdd = {...inputChoices[0], label: _option, value: _option };
                        inputProps = {...inputProps, choices : _optionsToAdd};
                      }
                      inputToRender = cloneElement(input!, inputProps);
                    }else if(action === FIELD_DEPENDENCY_ACTIONS.DELETE_OPTIONS){ //*delete options action
                      const _optionToBeDeleted = actionMap?.options;
                      const inputChoices :any[] = [...input?.props?.choices];
                      if (typeof _optionToBeDeleted !== 'string'){ //* there are more than one options
                        _optionToBeDeleted.forEach((option: any) => {
                        const optionIndex =  inputChoices.findIndex((valueMap :any)=> valueMap?.value === option) 
                        if(optionIndex >= 0){
                          inputChoices?.splice(optionIndex, 1);
                        }
                        });
                      }else{
                        //*there is only one option
                        const optionIndex =  inputChoices.findIndex((valueMap :any)=> valueMap?.value === _optionToBeDeleted) 
                        if(optionIndex >= 0){
                          inputChoices.splice(optionIndex, 1);
                        }
                      }
                      inputProps = {...inputProps, choices : inputChoices};
                      inputToRender = cloneElement(input!, inputProps);
                    }else if(action === FIELD_DEPENDENCY_ACTIONS.READONLY){ //*readonly action
                      inputProps = {...inputProps, disabled : true};
                      inputToRender = cloneElement(input!, inputProps);
                    }
                })
            }
           })
    }); //* loop through all filtered @dependencyMaps actions

  }); //* loop through all filtered @dependencyMaps

  return inputToRender;
};

  return <>{render()}</>;
}

import { makeStyles } from '@mui/styles';
import { blue } from '@mui/material/colors';

export const useCommentStyle = makeStyles((theme: any) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    dialog: {
        position: 'absolute',
        top: 50,
    },
    actionGroupBtn: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    taskActionButton: {
        marginLeft: theme.spacing(1),
        textTransform: 'none',
    },
    deleteButton: {
        marginLeft: theme.spacing(1),
        textTransform: 'none',
        color: theme.palette.error.main,
        '&:hover': {
          backgroundColor: theme.palette.error.main,
          '@media (hover: none)': {
            backgroundColor: 'transparent'
          }
        }
    },
    textarea: {
        paddingTop: 16,
        paddingLeft: 14,
        paddingRight: 60,
        paddingBottom: 14,
        lineHeight: 1.3,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1),
    },
    cancel: {
        marginRight: theme.spacing(1),
    },
    content: {
        marginBottom: theme.spacing(1),
    },
    text: {
        flex: 1,
    },
    paragraph: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.875rem',
        lineHeight: 1.3,
        marginBottom: theme.spacing(2.4),
    },
    sharedComment: {
        backgroundColor: '#edf3f0',
        padding: '0 1em',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'stretch',
        marginBottom: theme.spacing(1),
        boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
    },
    shareIt: {
        boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
    },
}));
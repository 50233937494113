import englishMessages from 'ra-language-english';

const ra = englishMessages.ra;

ra.page.dashboard = 'Dashboard';
ra.page.empty = 'Empty';
ra.page.invite = 'Invite';
ra.action.export = 'Export';
/* ra.notification.updated = 'Data updated |||| %{smart_count} elements updated';
ra.notification.created = 'Data created';
ra.notification.deleted = 'Data deleted |||| %{smart_count} elements deleted'; */

const en = {
    ra,
    dashboard: {
        searchInputPlaceholder: "Search...",
    },
    translations: {
        somethingWentWrong: "Something went wrong",
        all: "All",
        dataUpdated: "Data updated",
        menuPosition: "Menu Position",
        left: "Left",
        right: "Right",
        top: "Top",
        upload: "Upload",
        unsupportedFileType: "Unsupported file type",
        relateAction: "Relate",
        selectAction: "Select",
        backAction: "Back",
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        configuration: "Configuration",
        profile: "Profile",
        viewAll: "View all",
        addAction: "Add",
        associateNew: "Associate New",
        associateExisting: "Associate Existing",
        cancelAction: "Cancel",
        closeAction: "Close",
        associateThisRecord: "Associate new/existing to this record",
    },
    "incorrect response: INVALID_SESSIONID: Session Identifier provided is Invalid": 'Invalid Session',
    "Theme Setting": "Theme Setting",
    "Theme Color": "Theme Color",
    "Menu Position": "Menu Position",
    "Top": "Top",
    "Left": "Left",
    "Right": "Right",
    "Position": "Position",
    "Time Control": "Time Control",
    "Dashboard": "Dashboard",
    "Cancel": "Cancel",
    "File": "File",
    "Search": "Search",
    "View All": "View All",
    "Search for": "Search for",
    "Assigned to me": "Assigned to me",
    "Data saved successfully": "Data saved successfully",
    "Something went wrong": "Something went wrong",
    "Edit": "Edit",
    "New Task": "New Task",
    "Archive": "Archive",
    "Version": "Version",
    "A new version was created successfully": "A new version was created successfully",
    "Drop your file here to upload or click to select": "Drop your file here to upload or click to select",
    "TimeControl created successfully": "TimeControl created successfully",
    "Record created successfully": "Record created successfully",
    "Activities": "Activities",
    "Activity": "Activity",
    "Related View": "Related View",
    "Toggle View": "Toggle View",
    "Invalid username or password": "Invalid username or password",
    "My Home Page": "My Home Page",
    "Show More": "Show More",
    "Show Less": "Show Less",
};

export default en;

//import React, { useState, useEffect, useContext } from 'react';
import React, { useState, useEffect } from 'react';
import corebosUtils from '../component/corebosUtils/corebosUtils'
import {
    useGetList,
    Identifier,
    useListContext,
    RaRecord,
    // DataProviderContext,
} from 'react-admin';
import { Box } from '@mui/material';
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';
import isEqual from 'lodash/isEqual';

import { TaskColumn } from './TaskColumn';
import { status } from './status';

interface TasksByColumn {
    [status: string]: Identifier[];
}

const initialTasks: TasksByColumn = status.reduce(
    (obj, val) => ({ ...obj, [val]: [] }),
    {}
);

const getTasksByColumn = (
    data: RaRecord[]
): TasksByColumn => {
    const columns = data.reduce(
        (previousData, currentData) => {
            previousData[currentData?.projecttaskstatus]?.push(currentData?.id);
            return previousData;
        },
        status.reduce((obj, val) => ({ ...obj, [val]: [] }), {} as any)
    );
    status.forEach((val: any) => {
        columns[val] = columns[val].sort(
            (a: number, b: number) => a - b
        );
    });
    return columns;
};

export const TaskListContent = (props:any) => {
    const {
        data,
        page,
        perPage,
        sort,
        filterValues,
        isLoading,
    } = useListContext<any>();

    const [tasks, setTasks] = useState<TasksByColumn>(
        isLoading ? initialTasks : getTasksByColumn(data)
    );

    // const dataProvider = useContext(DataProviderContext);

    const { refetch } = useGetList(
        'ProjectTask',
        { pagination: { page, perPage }, sort, filter: filterValues }
    );

    useEffect(() => {
        if (isLoading) return;
        const newTasks = getTasksByColumn(data);
        if (isEqual(tasks, newTasks)) {
            return;
        }
        setTasks(newTasks);
    }, [data, isLoading, tasks]); 


    const onDragEnd: OnDragEndResponder = async result => {
        const { destination, source, draggableId } = result;
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            console.log(' Change ordering');
        } else {

            const sourceColumn: any = Array.from(tasks[source.droppableId]);
            const destinationColumn = Array.from(
                tasks[destination.droppableId]
            );
            const sourceTask = data[sourceColumn[source.index]];
            //const destinationTask = data[destinationColumn[destination.index]];

            sourceColumn.splice(source.index, 1);
            destinationColumn.splice(destination.index, 0, draggableId);
            setTasks({
                ...tasks,
                [source.droppableId]: sourceColumn,
                [destination.droppableId]: destinationColumn,
            });

            sourceTask.projecttaskstatus = destination?.droppableId;
            corebosUtils.cbUpdate('ProjectTask', sourceTask).then(() => {
                refetch();
            });
           
        }
    };

    if (props.isLoadigProject || isLoading) return null;

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display="flex">
                {status.map(val => (
                    <TaskColumn
                        status={val}
                        data={data}
                        key={val}
                        resource={props?.resource}
                        handleQuickCreateModal={props.handleQuickCreateModal}
                        modPermission={props.modPermission}
                    />
                ))}
            </Box>
        </DragDropContext>
    );
};

import React from "react";
import {Link} from 'react-router-dom';
import Typography from "@mui/material/Typography";



const CustomFieldLink = (propsParam: {resource: any, record: any, linkField: string  }) => {


    return (
        <Typography variant="inherit" component={Link} to={`/${propsParam.resource}/${propsParam.record?.id}/show`} color="primary">
            {propsParam.record ? propsParam.record[`${propsParam.linkField}`] : ''}
        </Typography>
    )
}

export default CustomFieldLink;